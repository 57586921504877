import { useEffect, useState } from 'react'
import { UpgradeLocation } from '../types'
import { isMyAccountOrigin } from '../utils'
import { UpgradeHooksParams, useFlowReturn } from '../upgrade-hub/types'

export default function useUpgradeHubFlow({
  origin,
  upgradeInfo: { tripInfo, profileExtendInfo },
}: UpgradeHooksParams): useFlowReturn {
  const [showContent, setShowContent] = useState(false)
  const [location, setLocation] = useState<UpgradeLocation>(
    UpgradeLocation.BLANK
  )

  useEffect(() => {
    if (isMyAccountOrigin(origin) && profileExtendInfo) {
      setShowContent(true)
      setLocation(UpgradeLocation.UPGH_SELECT_FLIGHT)
    } else if (!isMyAccountOrigin(origin) && tripInfo) {
      setShowContent(true)
      setLocation(UpgradeLocation.UPGH_ONBOARDING)
    }
  }, [origin, profileExtendInfo, tripInfo])

  return {
    upgradeInfo: {
      tripInfo,
      profileExtendInfo,
    },
    showContent,
    location,
  }
}
