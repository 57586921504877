import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { SelectRegular } from '@copa/design-system-factory.select-regular'
import { Option } from '@copa/design-system-factory.option'
import { Typography } from '@copa/design-system-factory.typography'
import { useIntl } from 'react-intl'
import {
  Controller,
  FieldValue,
  useFieldArray,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form'
import { InputRegular } from '@copa/design-system-factory.input-regular'
import { ButtonCo } from '@copa/design-system-factory.button'
import { AutocompleteRegular } from '@copa/design-system-factory.form-fields.autocomplete-regular/dist/autocomplete-regular'
import { Item } from '@copa/design-system-factory.form-fields.autocomplete-regular/dist/types'
import { Markdown } from '@copa/design-system-factory.markdown'
import {
  Airport,
  ReactEvent,
  SauaDictionary,
  UpgradeCacheKey,
} from '../../../types'
import useStyles from './styles'
import useBitBreakPoints from '../../../../../hooks/material/useBitBreakPoints'
import VALIDATIONS from '../PassengerForm/validations'
import { getInputCheckIcon } from '../../utils'
import { InputsDatePicker } from './InputsDatePicker/InputsDatePicker'
import TrashCanIcon from '../../../../../assets/images/trash-can.svg'
import flightFormState from './utils'
import CrossIcon from '../../../../../assets/images/cross.svg'
import AirplaneIcon from '../../../../../assets/images/airplane.svg'
import {
  useGetAirlinePartnersMutation,
  useGetAirportsMutation,
  useGetCabinClassesMutation,
} from '../../../../../api/sauaSlice'

export interface SauaFlight {
  origin: string
  destination: string
  airline: string
  number: string
  date: string
  cabinClass: string
  originAirportName: string
  destinationAirportName: string
  pnr: string
  isUpgraded?: boolean
}

export interface SauaFlightForm {
  origin: Item
  destination: Item
  airline: string
  number: string
  date: string
  cabinClass: string
}

export interface FlightFormProps {
  onAction: () => void
}

interface ReplaceFlightNumberInitialsProps {
  index: string
  flightNumber: string
  event: ReactEvent
  setValue: UseFormSetValue<FieldValue<any>>
  airlines: Airline[]
}

type Airline = {
  code: string
  name: string
}

function replaceFlightNumberInitials({
  index,
  flightNumber,
  event,
  setValue,
  airlines,
}: ReplaceFlightNumberInitialsProps) {
  // @ts-ignore
  const selectedInitials = event.target.value
  const isKnownAirlineInitials = airlines.some(
    airline => airline.code === flightNumber.substring(0, 2)
  )

  const newFlightNumber = isKnownAirlineInitials
    ? selectedInitials + flightNumber.substring(2)
    : selectedInitials + flightNumber

  setValue(`flights[${index}].number`, newFlightNumber, {
    shouldValidate: true,
  })
}

const premiumCabin = (cabinClassesObject: SauaDictionary[]) =>
  cabinClassesObject
    .filter(
      (cabinClass: SauaDictionary) =>
        cabinClass.code === 'C' || cabinClass.code === 'F'
    )
    .map(cabinClass => ({ ...cabinClass, name: cabinClass.name.split(' ')[0] }))

function formatAirlineName(name: string): string {
  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function FlightForm({ onAction }: FlightFormProps) {
  const { formatMessage } = useIntl()
  const { breakpoints, isXsOrSmall } = useBitBreakPoints()
  const classes = useStyles({ breakpoints })
  const emptyItem: Item = { key: '', text: '' }

  const [_, { data: airlines }] = useGetAirlinePartnersMutation({
    fixedCacheKey: UpgradeCacheKey.AIRLINES,
  })

  const [__, { data: cabinClasses }] = useGetCabinClassesMutation({
    fixedCacheKey: UpgradeCacheKey.CABIN_CLASSES,
  })

  const [getAirport] = useGetAirportsMutation({
    fixedCacheKey: UpgradeCacheKey.AIRPORTS,
  })

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'flights',
  })

  const watchedAirline = (index: number) => watch()?.flights?.[index].airline
  const watchedFlightNumber = (index: number) =>
    watch()?.flights?.[index].number
  const watchedOrigin = (index: number) => watch()?.flights?.[index].origin.key
  const watchedDestination = (index: number) =>
    watch()?.flights?.[index].destination.key

  const shouldShowCheckIcon = (index: number, prop: string, value: string) =>
    // @ts-ignore
    !errors?.flights?.[index]?.[prop] && value !== ''

  const addFlight = () => {
    append(flightFormState)
    onAction()
  }

  const removeFlight = (flightIndex: number) => {
    remove(flightIndex)
    onAction()
  }

  const applyAirlinesInitials = (
    e: ReactEvent,
    index: number,
    onChange: (value: string) => void
  ) => {
    const initials = watchedAirline(index)
    const newValue = e?.target?.value as string

    if (newValue.startsWith(initials)) {
      onChange(newValue.replace(/\s/g, ''))
    } else {
      const valueToSet = newValue.length >= 2 ? initials + newValue : initials
      onChange(valueToSet.replace(/\s/g, ''))
    }
  }

  const resetFlights = (forms: Record<'id', string>[]) => {
    const values = getValues()
    reset({
      ...values,
      flights:
        forms.length > 1
          ? [flightFormState, flightFormState]
          : [flightFormState],
    })
  }

  const isDifferentAirport = (index: number, field: string) => {
    if (
      watchedOrigin(index) === watchedDestination(index) &&
      watchedOrigin(index) !== '' &&
      watchedDestination(index) !== ''
    ) {
      setError(field, {
        type: 'manual',
        message: formatMessage({
          id: 'upgrade.saua.flightsForm.airportsInlineError',
        }),
      })
    } else {
      clearErrors(`flights[${index}].origin`)
      clearErrors(`flights[${index}].destination`)
    }
  }

  const asyncAutocompleteItems = useCallback(
    () => ({
      execute: async (input: string) => {
        const { data } = await getAirport(input)
        if (data) {
          const itemsData: Item[] = data.map((airport: Airport) => {
            const airportLocation =
              airport.cityName.trim().length > 0
                ? airport.cityName
                : airport.countryName

            return {
              text: `${airportLocation} | ${airport.airportName} (${airport.airportCode})`,
              key: airport.airportCode,
            }
          })

          return {
            result: itemsData,
          }
        }

        return {
          result: undefined,
          errorText: formatMessage({
            id: 'upgrade.saua.flightsForm.serviceError',
          }),
        }
      },
      keyStrokeDebounceTime: 1500,
      minRequiredCharacters: 3,
    }),
    [formatMessage, getAirport]
  )

  const sortedAirlines: Airline[] = useMemo(
    () =>
      airlines &&
      [...airlines]
        .map((airline: Airline) => ({
          ...airline,
          name: formatAirlineName(airline.name),
        }))
        .sort((a: Airline, b: Airline) => a.name.localeCompare(b.name)),
    [airlines]
  )

  return (
    <Box sx={classes.container}>
      <Box sx={classes.title}>
        {/* @ts-ignore */}
        <img src={AirplaneIcon} alt="" />
        <Typography variant="h3" color="grey.700">
          {formatMessage({
            id: 'upgrade.saua.flightsForm.title',
          })}
        </Typography>
      </Box>

      {fields.map((field, index) => (
        <Box key={field.id} sx={classes.flightContainer}>
          <Box sx={classes.subtitle}>
            <Typography variant="h4" color="grey.700">
              {formatMessage(
                {
                  id: 'upgrade.saua.flightsForm.flightTitle',
                },
                {
                  number: index + 1,
                }
              )}
            </Typography>

            {index !== 0 && (
              <ButtonCo
                variant="transparentPrimaryMain"
                onClick={() => removeFlight(index)}
                aria-label={formatMessage({
                  id: 'upgrade.saua.flightsForm.wcag.removeFlight',
                })}
                sx={{
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                {/* @ts-ignore */}
                <img src={CrossIcon} alt="" />
                {formatMessage({
                  id: 'upgrade.saua.flightsForm.removeFlight',
                })}
              </ButtonCo>
            )}
          </Box>

          <Box sx={classes.row}>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].origin`}
                render={({ field: { onChange, value } }) => (
                  <Box>
                    <AutocompleteRegular
                      ariaLabel={formatMessage({
                        id: 'upgrade.saua.flightsForm.wcag.autocomplete',
                      })}
                      id={`flights[${index}].origin`}
                      value={value.text === '' ? null : value}
                      required
                      asyncSearchOptions={asyncAutocompleteItems()}
                      items={[]}
                      onChange={item => {
                        onChange(item == null ? emptyItem : item)
                        isDifferentAirport(index, `flights[${index}].origin`)
                      }}
                      notFoundErrorText={formatMessage({
                        id: 'upgrade.saua.flightsForm.notFoundError',
                      })}
                      label={formatMessage({
                        id: 'upgrade.saua.flightsForm.originLabel',
                      })}
                      placeholder={formatMessage({
                        id: 'upgrade.saua.flightsForm.originPlaceholder',
                      })}
                      helperText={formatMessage({
                        id: 'upgrade.saua.flightsForm.wcag.autocomplete',
                      })}
                      errorHelperText={
                        // @ts-ignore
                        errors?.flights?.[index]?.origin?.message || ''
                      }
                      // @ts-ignore
                      hasErrors={!!errors?.flights?.[index]?.origin}
                    />
                  </Box>
                )}
              />
            </Box>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].destination`}
                render={({ field: { onChange, value } }) => (
                  <Box>
                    <AutocompleteRegular
                      ariaLabel={formatMessage({
                        id: 'upgrade.saua.flightsForm.wcag.autocomplete',
                      })}
                      id={`flights[${index}].destination`}
                      value={value.text === '' ? null : value}
                      required
                      asyncSearchOptions={asyncAutocompleteItems()}
                      items={[]}
                      onChange={item => {
                        onChange(item === null ? emptyItem : item)
                        isDifferentAirport(
                          index,
                          `flights[${index}].destination`
                        )
                      }}
                      notFoundErrorText={formatMessage({
                        id: 'upgrade.saua.flightsForm.notFoundError',
                      })}
                      label={formatMessage({
                        id: 'upgrade.saua.flightsForm.destinationLabel',
                      })}
                      placeholder={formatMessage({
                        id: 'upgrade.saua.flightsForm.destinationPlaceholder',
                      })}
                      errorHelperText={
                        // @ts-ignore
                        errors?.flights?.[index]?.destination?.message || ''
                      }
                      helperText={formatMessage({
                        id: 'upgrade.saua.flightsForm.wcag.autocomplete',
                      })}
                      // @ts-ignore
                      hasErrors={!!errors?.flights?.[index]?.destination}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].airline`}
                rules={VALIDATIONS.REQUIRED_DROPBOX({ formatMessage })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box>
                    <SelectRegular
                      id={`flights[${index}].airline`}
                      label={formatMessage({
                        id: 'upgrade.saua.flightsForm.airlineLabel',
                      })}
                      placeholder={formatMessage({
                        id: 'upgrade.saua.flightsForm.airlinePlaceholder',
                      })}
                      defaultValue=""
                      onChange={event => {
                        replaceFlightNumberInitials({
                          // @ts-ignore
                          index,
                          flightNumber: watchedFlightNumber(index),
                          // @ts-ignore
                          event,
                          setValue,
                          airlines: sortedAirlines,
                        })

                        onChange(event)
                      }}
                      required
                      onBlur={onBlur}
                      value={value}
                    >
                      {sortedAirlines &&
                        sortedAirlines.map((option: SauaDictionary) => (
                          <Option key={option.code} value={option.code}>
                            {option.name}
                          </Option>
                        ))}
                    </SelectRegular>
                  </Box>
                )}
              />
            </Box>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].number`}
                rules={VALIDATIONS.FLIGHT_NUMBER({ formatMessage })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    id={`flights[${index}].number`}
                    label={formatMessage({
                      id: 'upgrade.saua.flightsForm.flightNumber',
                    })}
                    placeholder={formatMessage({
                      id: 'upgrade.saua.flightsForm.flightNumberPlaceholder',
                    })}
                    // @ts-ignore
                    hasErrors={!!errors?.flights?.[index]?.number}
                    // @ts-ignore
                    helperText={errors?.flights?.[index]?.number?.message || ''}
                    required
                    value={value}
                    handleChange={event =>
                      applyAirlinesInitials(event, index, onChange)
                    }
                    onBlur={onBlur}
                    defaultValue={watchedAirline(index) || ''}
                    InputProps={getInputCheckIcon({
                      shouldShowValidation: () =>
                        shouldShowCheckIcon(index, 'number', value),
                    })}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].date`}
                rules={VALIDATIONS.NO_FUTURE_DATE({ formatMessage })}
                render={({ field: { onChange } }) => (
                  <InputsDatePicker
                    index={index}
                    setDate={onChange}
                    // @ts-ignore
                    hasErrors={!!errors?.flights?.[index]?.date}
                    // @ts-ignore
                    helperText={errors?.flights?.[index]?.date?.message}
                  />
                )}
              />
            </Box>
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].cabinClass`}
                rules={VALIDATIONS.REQUIRED_DROPBOX({ formatMessage })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box>
                    <SelectRegular
                      id={`flights[${index}].cabinClass`}
                      label={formatMessage({
                        id: 'upgrade.saua.flightsForm.cabin',
                      })}
                      placeholder={formatMessage({
                        id: 'upgrade.saua.flightsForm.cabinPlaceholder',
                      })}
                      defaultValue=""
                      onChange={onChange}
                      required
                      onBlur={onBlur}
                      value={value}
                    >
                      {cabinClasses &&
                        premiumCabin(cabinClasses).map(
                          (cabinClass: SauaDictionary) => (
                            <Option
                              key={cabinClass.code}
                              value={cabinClass.code}
                            >
                              {cabinClass.name}
                            </Option>
                          )
                        )}
                    </SelectRegular>
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              ...classes.row,
              marginTop: {
                xs: '0px',
                md: '-35px',
              },
              marginBottom: '20px',
            }}
          >
            <Box sx={classes.input}>
              <Controller
                control={control}
                name={`flights[${index}].pnr`}
                rules={VALIDATIONS.PNR({ formatMessage })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    id={`flights[${index}].pnr`}
                    label={formatMessage(
                      {
                        id: 'upgrade.saua.flightsForm.pnr',
                      },
                      {
                        airline:
                          sortedAirlines &&
                          sortedAirlines.find(
                            (airline: Airline) =>
                              airline.code === watchedAirline(index)
                          )?.name,
                      }
                    )}
                    placeholder={formatMessage({
                      id: 'upgrade.saua.flightsForm.pnrPlaceholder',
                    })}
                    // @ts-ignore
                    hasErrors={!!errors?.flights?.[index]?.pnr}
                    helperText={
                      // @ts-ignore
                      !errors?.flights?.[index]?.pnr ? (
                        <Box sx={classes.composeHelperText}>
                          <Markdown>
                            {formatMessage(
                              {
                                id: 'upgrade.saua.flightsForm.pnrHelperText',
                              },
                              { copa_url: process.env.REACT_APP_COPA_URL }
                            )}
                          </Markdown>
                        </Box>
                      ) : (
                        // @ts-ignore
                        errors?.flights?.[index]?.pnr?.message || ''
                      )
                    }
                    required
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                    defaultValue={watchedAirline(index) || ''}
                    InputProps={getInputCheckIcon({
                      shouldShowValidation: () =>
                        shouldShowCheckIcon(index, 'pnr', value),
                    })}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      ))}

      <Box sx={classes.actions}>
        <ButtonCo
          variant="outlineGray"
          rounded
          onClick={() => resetFlights(fields)}
          aria-label={formatMessage({
            id: 'upgrade.saua.flightsForm.wcag.clearForm',
          })}
          style={{ width: isXsOrSmall ? '100%' : 'unset' }}
        >
          {/* @ts-ignore */}
          <img src={TrashCanIcon} alt="" />
          {formatMessage({
            id: 'upgrade.saua.flightsForm.clearForm',
          })}
        </ButtonCo>

        <ButtonCo
          variant="outlinePrimaryMain"
          rounded
          onClick={() => addFlight()}
          aria-label={formatMessage({
            id: 'upgrade.saua.flightsForm.wcag.addFlight',
          })}
          style={{ width: isXsOrSmall ? '100%' : 'unset' }}
          disabled={fields.length >= 2}
        >
          {formatMessage({
            id: 'upgrade.saua.flightsForm.addFlight',
          })}
        </ButtonCo>
      </Box>
    </Box>
  )
}
