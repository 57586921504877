import React, { useEffect, useRef } from 'react'
import Helmet from 'react-helmet'

interface SeoWrapperProps {
  children: React.ReactNode
  ariaText: string
  id: string
  language?: string
  title: string
  keywords: string[]
}

interface MetaTag {
  name?: string
  content?: string
  property?: string
}

export function SeoWrapper({
  children,
  ariaText,
  id,
  language = 'en',
  title,
  keywords,
}: SeoWrapperProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus()
    }
  }, [])

  const keywordsTag: MetaTag =
    keywords.length > 0
      ? {
        name: `keywords`,
        content: keywords.join(`, `),
      }
      : {}

  const meta: MetaTag[] = [
    {
      property: `og:title`,
      content: title,
    },
    keywordsTag,
  ]
  return (
    <>
      {/* @ts-ignore */}
      <Helmet
        htmlAttributes={{
          language,
        }}
        title={title}
        meta={meta}
      />
      <div id={id} aria-label={ariaText} ref={containerRef} role="contentinfo">
        {children}
      </div>
    </>
  )
}

export default SeoWrapper
