import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import { useIntl } from 'react-intl'
import { MyTripsState } from '../../../../../../../../types/state'
// @ts-ignore
export function NotEligibleError({ template }) {
  const { formatMessage } = useIntl()
  const [eligibleErrorTemplate, setEligibleErrorTemplate] = useState(
    template.notEligibleError
  )
  const {
    flightChanges: { serviceError },
  } = useSelector((state: MyTripsState) => state.rebooking)

  const redirectToErrorPage = () => {
    const link = formatMessage(
      { id: eligibleErrorTemplate.link },
      { copa_url: process.env.REACT_APP_COPA_URL }
    )
    window.location.assign(link)
  }
  // @ts-ignore
  const setEligibleTemplate = newTemplate => {
    if (newTemplate) {
      setEligibleErrorTemplate(newTemplate)
    }
  }

  useEffect(() => {
    if (serviceError) {
      setEligibleTemplate(template.notEligibleError)
    }
  }, [serviceError, template])

  return (
    <TitleCombination
      avatarProps={{
        id: 'international-flight-icon',
        variant: 'huge',
        font: eligibleErrorTemplate.icon,
      }}
      titleProps={{
        children: formatMessage({ id: eligibleErrorTemplate.title }),
        color: 'primary.main',
        variant: eligibleErrorTemplate.titleVariant,
      }}
      descriptionProps={{
        children: formatMessage({ id: eligibleErrorTemplate.subtitle }),
        variant: eligibleErrorTemplate.subtitleVariant,
      }}
      buttonProps={{
        children: formatMessage({ id: eligibleErrorTemplate.cta }),
        variant: 'solidPrimaryMain',
        size: 'large',
        onClick: redirectToErrorPage,
      }}
    />
  )
}
