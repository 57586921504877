import { Flight } from '../../interfaces/flight.interface'
import { templates } from '../../flows/SC/templates/templates.enum'
import { notFlownLegs } from '../../../../selectors/destination'
import { Leg } from '../../interfaces/leg.interface'
import { rebookingCallCalendar } from '../../../../actions/rebooking'

interface GetRebookingCalendarRebooking {
  flight: Flight
  // @ts-ignore
  dispatch: (_action) => void
  typeModify: templates | string
  pnr: string
}

export default function getRebookingCalendar({
  flight,
  dispatch,
  typeModify,
  pnr,
}: GetRebookingCalendarRebooking) {
  const legs: Leg[] = notFlownLegs({ flight })

  const { issueDateTime, createDateTime } = flight
  const requestTypeModify =
    typeModify === templates.NR ? flight.revenue.nonRevenueType : typeModify

  const payload = {
    pnr,
    issueDateTime,
    createDateTime,
    typeModify: requestTypeModify,
    ods: legs.map((od, index) => ({
      index,
      firstFlightDate: od.segments[0].flightDate,
      departureGMT: od.segments[0].departureGMT,
      flightStatus: od.segments.map(segment => segment.statusCode),
    })),
  }

  dispatch(rebookingCallCalendar(payload))
}
