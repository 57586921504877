import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { templateByFlow } from '../../flows/SC/templates/templates.map'
import { flows, templates } from '../../flows/SC/templates/templates.enum'
import { findReservation } from '../../../../actions/flight'
import { flightChangesInfoRequest } from '../../../../actions/rebooking'
import { TYPE_MODIFY_QUERY_PARAM } from '../../../../constants/variables'
import { MyTripsState } from '../../../../types/state'
import { Template } from '../../utils/types'
import { getScTemplate } from './getScTemplate'
import { REBOOK_ORIGIN } from '../../utils/constants'
import { useRebookingOrigin } from './useRebookingOrigin'

interface GetFlowTemplatesParams {
  tmQueryParam: flows | string
  typeModify: flows | string
  rebookOrigin: typeof REBOOK_ORIGIN | string
}

export interface UseSrtTemplateReturn {
  template: Template
  isEligibleToRebook: boolean
  flow: flows
  showInterstitial: boolean
}

export const useSrtTemplate = (): UseSrtTemplateReturn => {
  const dispatch = useDispatch()
  // @ts-ignore
  const { pnr, surname } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const typeModifyQueryParam = queryParams.get(TYPE_MODIFY_QUERY_PARAM)
  const flight = useSelector((state: MyTripsState) => state.flight)
  const {
    redemptionElegibility: { isEligibleRedemptions },
    flightChanges: { isElegible, typeModify: typeModifyWCI },
  } = useSelector((state: MyTripsState) => state.rebooking)
  const isFetching = useSelector(
    (state: MyTripsState) => state.generalSpinner.fetching
  )
  const { calculatedFlow, calculatedTemplate } = useMemo(() => {
    const selectedFlow = typeModifyQueryParam ? flows.WCI : flows.SC
    return {
      calculatedFlow: selectedFlow,
      calculatedTemplate: templateByFlow[selectedFlow][templates.DEFAULT],
    }
  }, [typeModifyQueryParam])
  const [flow, setFlow] = useState<flows>(calculatedFlow)
  const [template, setTemplate] = useState<Template>(calculatedTemplate)
  const [isEligibleToRebook, setIsEligibleToRebook] = useState<boolean>(false)
  const [loading, setLoading] = useState<{
    showInterstitial: boolean
    fetchingIsStarted: boolean
  }>({
    showInterstitial: !flight.pnr,
    fetchingIsStarted: false,
  })

  const { origin } = useRebookingOrigin()
  const getFlowTemplates = ({
    tmQueryParam,
    typeModify,
    rebookOrigin,
  }: GetFlowTemplatesParams) => {
    let newFlow = flows.SC
    let newTemplate = getScTemplate(typeModify, rebookOrigin)

    if (tmQueryParam) {
      newFlow = flows.WCI
      // @ts-ignore
      newTemplate = templateByFlow[flows.WCI][typeModify]
    }

    return {
      newTemplate,
      newFlow,
    }
  }

  const payload = useMemo(
    () => ({
      code: pnr,
      lastName: surname,
      getAdditionalInfo: true,
    }),
    [surname, pnr]
  )

  useEffect(() => {
    if (typeModifyQueryParam) {
      dispatch(flightChangesInfoRequest(payload))
    } else {
      dispatch(findReservation(payload))
    }
  }, [typeModifyQueryParam, dispatch, payload])

  useEffect(() => {
    if (!flight.pnr && isElegible && typeModifyWCI) {
      dispatch(findReservation(payload))
    }
  }, [flight, isElegible, isEligibleToRebook, dispatch, payload, typeModifyWCI])

  useEffect(() => {
    if (flight.pnr) {
      const typeModify = typeModifyQueryParam
        ? typeModifyWCI
        : flight.rebooking.typeModify
      const { newTemplate, newFlow } = getFlowTemplates({
        // @ts-ignore
        tmQueryParam: typeModifyQueryParam,
        typeModify,
        rebookOrigin: origin,
      })
      setTemplate(prev => newTemplate ?? { ...prev, name: 'DO_NOT_EXIST' })
      setFlow(newFlow)

      if (newFlow === flows.SC) {
        setIsEligibleToRebook(
          typeModify === templates.RDP ? isEligibleRedemptions : newTemplate
        )
      } else {
        // @ts-ignore
        const typeModifyFromTemplate = templates[typeModifyQueryParam]
        setIsEligibleToRebook(
          typeModify === typeModifyFromTemplate && isElegible
        )
      }
    }
  }, [
    flight,
    isEligibleRedemptions,
    isElegible,
    typeModifyWCI,
    typeModifyQueryParam,
    origin,
  ])

  const { fetchingIsStarted } = loading
  useEffect(() => {
    if (isFetching && !fetchingIsStarted) {
      setLoading(prev => ({
        ...prev,
        fetchingIsStarted: true,
      }))
    }
    if (!isFetching && fetchingIsStarted) {
      setLoading(prev => ({
        ...prev,
        showInterstitial: false,
      }))
    }
  }, [isFetching, fetchingIsStarted, setLoading])

  return {
    template,
    isEligibleToRebook,
    flow,
    showInterstitial: loading.showInterstitial,
  }
}
