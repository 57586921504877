import { SeatTypes } from '../../utils/seatSelection/seatSelection'

export default {
  // ////////////////////////////// start translations My trips //////////////////////////////////
  loading: 'The page is loading',
  findReservation: {
    title: 'My Trips',
    description:
      'In My Trips you can view, manage and save your Copa Airlines reservations.',
    welcome: "Hello! Let's find your reservation.",
    innerDescription:
      'In My Trips you can view, manage and save your Copa Airlines reservations.',
    informationAlert:
      'You are using MyTrips Beta. Do you have comments or suggestions? ',
    linkInformationAlert: 'Leave a comment.',
    errorInvalidToken: 'Your ConnectMiles session has expired. Sign in again.',
  },
  connectMiles: {
    description:
      'You can also log in to your ConnectMiles account to manage your saved reservations.',
    loginButton: 'Log In',
  },

  findTrip: {
    title: 'Find a reservation',
    buttonFind: 'Find your reservation',
    checkLabel: 'Save to MyTrips',
    alreadyAdded: 'Your reservation is already added to MyTrips.',
    succesfully: 'Trip saved successfully',
    notFound: `We're sorry! We couldn't find your reservation with the information provided. Please check your entries and try again.`,
  },

  emptyState: {
    title: 'You have no saved trips yet.',
    description:
      'Trips associated to your ConnectMiles number will appear here automatically. You can also find a reservation to add here.',
    buttonAdd: 'Add trip',
  },

  holderCard: {
    title: 'Add another trip',
  },
  holderCardWCAG: {
    description: 'Add another trip.',
  },
  pastTrips: {
    title: 'Past Trips',
    destination: 'Destination',
    flightDates: 'Flight dates',
    reservation: 'Reservation',
  },

  pastTripsWCAG: {
    wcagTitle: 'Past Trips Dashboard',
    wcagDescription:
      'Past Trips: This section shows trips that have already been completed. Your past trips disappear automatically after 2 months',
    wcagAvatarImage:
      'Destination Avatar: This is the Avatar of {destinationCity}',
    wcagDestinationColumn:
      'Destination Column: This column shows the destinations of your past trips.',
    wcagFlightColumn:
      'Flight date column: This column shows the flight dates of your past trips.',
    wcagReservationColumn:
      'Reservation column:This column shows the reservation code of your past trips',
    wcagPastTripsDetail:
      'Your Trip Details are the following: {pasengerInformation} + {aditionalPassengers} {od} - {destinationCity} Flight Dates {startDate} - {endDate} Reservation Code: {reservationCode}',
    wcagTripSelection:
      'You are in your Past Trips dashboard. Here you can navigate between trips and hear your trip details',
    od: 'to',
  },

  removeModal: {
    title: 'Would you like to remove this trip?',
    description: `Don't worry, the reservation will remain active - it will only disappear from your My Trips page.`,
    pastTripDescription:
      'If you delete your trip now, you will no longer be able to see the details associated with it. Are you sure you want to continue?',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
    errorToastMessage:
      'Sorry! We were unable to delete your trip. Please try again later.',
    successToastMessage: 'Your trip was deleted successfully.',
  },

  editModal: {
    renameTrip: 'Rename this trip',
    tripName: 'Trip name',
    renameButton: 'Rename',
    editModalError: 'Special symbols are not allowed.',
  },

  fareRulesModal: {
    title: 'Fare rules',
    description:
      'Certain aspects of your reservation such as bags, seats, changes and reimbursements may be subject to restrictions depending on the type of fare you bought. Here, you will be able to read the fare rules that apply to each flight in this reservation.',
    link: 'Learn more about our fare families',
    href: '{copa_url}/en-gs/travel-information/fare-families/',
    infoNonCopa:
      'We do not have fare rules information for this flight because it is not operated by Copa Airlines.',
  },

  fareRulesModalWCAG: {
    description:
      'Fare Rules modal. From here you can see what rules apply to the fare you have purchased.',
    previousButton:
      'Use this button to view the fare rules that apply to the previous flight.',
    nextButton:
      'Use this button to view the fare rules that apply to the next flight.',
  },

  specialServicesModal: {
    title: 'Special services',
    subtitle:
      'Copa offers services for passengers with special needs. You may request special foods, in-flight assistance, among others.',
    introTitle: 'Add special services',
    passengerLabel: 'Passenger',
    specialServiceLabel: 'Special Service',
    appliesToLabel: 'Applies to',
    passengerSelectPlaceholder: 'Select passenger',
    serviceSelectPlaceholder: 'Select service',
    serviceLabel: 'Service type',
    mealLabel: 'Food type',
    chairLabel: 'Chair type',
    mealPlaceholder: 'Choose food type',
    wheelChairPlaceholder: 'Reason',
    addButton: '+ Add',
    removeButton: 'Remove',
    disclaimer: {
      title: 'Special services',
      meal: {
        title: 'Special Meals',
        description:
          'Requests for special meals can be made up to 24 hours prior to flight departure. Special meals available:',
        errorAlreadyAdded:
          'This service has already been added for this passenger.',
        item1: {
          title: 'Business Class: ',
          description:
            'vegetarian, kosher and gluten-free on certain flights. {linkDesc}',
          linkDesc: 'Learn about our meals and beverage onboard service.',
          link: '{copa_url}/en-gs/discover-copa-airlines/experience-on-board/food-on-board/',
        },
        item2: {
          title: 'Main Cabin: ',
          description:
            'gluten-free only on flights to and from Argentina and Paraguay.',
        },
      },
      assistance: {
        title: 'Special Assistance',
        item1: {
          title: 'Assistance for people with hearing or visual disabilities: ',
          description: 'Available on all our flights.',
        },
        item2: {
          title: 'Transportation of service and emotional support animals: ',
          description:
            'You can request it through our Reservation Center, sales offices or at the airport. {linkDesc}',
          linkDesc:
            'Learn about the conditions for transporting service and emotional support animals.',
          link: '{copa_url}/en-gs/travel-information/special-assistance/traveling-with-pets/',
        },
      },
      footer: {
        description: 'Learn more about our special services, here.',
        link: '{copa_url}/en-gs/travel-information/special-assistance/',
      },
    },
    goBackToReservationButton: 'Return to your reservation',
    saveServicesButton: 'Save services',
    specialFood: 'Special meal',
    wheelChair: 'Wheelchair',
    errorAlert:
      'There was an error when saving your special services. Please, try again later.',
    succesToastText: 'Special services saved successfully.',
    partialErrorsToastText:
      'There was an error when saving some of your special services. Please, try again later.',
  },

  specialServicesModalWCAG: {
    description:
      'Special services modal. From here you can request special services for specific passengers in your reservation.',
    passengerSelectionDescription:
      'MENU. You are in the passenger scroll-down menu.',
    serviceSelectionDescription:
      'MENU. You are in the service type scroll-down menu.',
    addButtonDescription: 'Add your selection to your trip.',
    removeButtonDescription:
      'Remove the selected service from your reservation.',
    goBackToReservationButton: 'Return to your reservation.',
    saveServicesButton: 'Save your selected services in your reservation',
  },

  sendReceiptModal: {
    title: 'Send reservation receipt',
    subtitle:
      "You can send this reservation's receipt to one or more e-mails of your preference.",
    emailLabel: 'Enter e-mail(s)',
    emailPlaceholder: 'Add one or more emails',
    descriptionLabel:
      'You can add multiple e-mails using the space or comma (",") keys.',
    goToSendReceipt: 'Send receipt',
    resultOk: 'Receipt sent successfully.',
    resultError:
      'There was an error when sending your receipt. Please, try again later.',
  },

  sendReceiptModalWCAG: {
    description:
      "You can send this reservation's receipt to one or more e-mails of your preference.",
  },

  changeItinerary: {
    title: 'Your itinerary has changed',
    subtitle_1: 'A schedule change has ocurred to your travel plans.',
    subtitle_2:
      'Make sure that the new schedule suits your travel plans and that you have enough time to make your connection.',
    alert: 'Changes in your itinerary are highlighted below',
    newItinerary: 'New travel itinerary',
    modifiedItinerary: 'Modified itinerary',
    titleFlight: 'Flight',
    titleFrom: 'From',
    titleDeparture: 'Departure',
    titleTo: 'To',
    titleArrival: 'Arrival',
    disclaimer_1:
      'If the schedule change does not work for your travel plans, other flight options may be available.',
    disclaimer_2:
      'Please review the new itinerary above before you choose the option below.',
    btnLearnMore: 'Learn more about schedule changes',
    link: '{copa_url}/en/web/gs/schedule-changes',
    btnAcept: 'Accept itinerary and continue',
  },

  confirmCancelReservation: {
    title: 'Are you sure you want to cancel this reservation?',
    warningLabel:
      'Once you cancel your reservation, you will not be able to recover it.',
    subtitle:
      'Your reservation will be cancelled in its entirety and you will receive a refund for {refundAmount} on your {creditCardFrachise} card ending in {creditCardNumberCensure}.',
    btnConfirmText: 'Cancel this reservation',
    btnBackText: 'Go back',
    flightDate: 'Travel dates',
    reservationCode: 'Reservation code',
  },

  confirmCancelReservationWCAG: {
    description:
      'Your reservation will be cancelled in its entirety and you will receive a refund for {refundAmount} on your {creditCardFrachise} card ending in {creditCardNumberCensure}.',
  },

  cancelledBooking: {
    title: 'We have received your refund request.',
    subtitle:
      'You will soon receive an email confirmation once your reservation is completely canceled.',
    btnNext: 'Continue',
    flightDate: 'Travel dates',
    reservationCode: 'Reservation code',
  },

  cancelledBookingWCAG: {
    description:
      'You will soon receive an email confirmation once your reservation is completely canceled.',
  },

  plusGrade: {
    resultError:
      'We are unable to access the upgrade request service at this time. Please try again later.',
  },

  requestRefund: {
    title: 'We are unable to process your request',
    description: {
      link: '{copa_url}/en-gs/customer-service/call-center/',
      linkText: 'Reservation Center',
      startText:
        'We are unable to process the web cancelation of this reservation, but you may open a refund request through our ',
      endText:
        '. You may also cancel your reservation and save your ticket value for a future trip; to do so, please click below and follow the instructions.',
    },
    btnCta: 'Cancel & Rebook Later',
    linkCta: '{copa_url}/en-gs/customer-service/changes-and-refunds/ ',
  },
  changeStatus: {
    onWindow: 'Available',
    outOfWindow: 'Not Available',
  },
  flightCard: {
    onTime: 'On Time',
    advanced: 'Early',
    delayed: 'Delayed',
    canceled: 'Cancelled',
    duration: 'Duration',
    checkInOpen: 'Check-In opens in',
    completed: 'Completed',
    alertSeatPassengers: 'Some passengers have not selected seats',
    alertMissingPassengerInfo: 'Missing passenger information',
    reservationCode: 'Reservation Code',
    nextFlight: 'Upcoming Flight',
    atTime: 'at',
    numberReservation: '# reservation:',
    to: 'to',
    btnCheckIn: 'Check-In',
    btnPayReservation: 'Pay reservation',
    btnBoardingPasses: 'Boarding passes',
    btnSeeReservation: 'View reservation',
    btnAutomaticSubscription: 'Subscribe to automatic Check-In.',
    flightCancelled: 'A flight in your reservation has been cancelled.',
    tripCancelled: 'Your trip has been cancelled.',
    flightPaidRequired: 'Payment required. Your reservation expires in',
    missingPassengerInfo: 'Missing passenger information',
    passengersNotSelectedSeats: 'Some passengers have not selected seats',
    yourUpcomingTrip: 'Your Upcoming Flight',
  },
  ellipsisMenu: {
    remove: 'Delete',
    rename: 'Rename',
  },

  ellipsisMenuWCAG: {
    title: 'MENU. Press enter to open the menu.',
    remove:
      'REMOVE, button. Use this option to remove this trip from your My Trips Dashboard.',
    rename: 'RENAME, button. Use this option to change the name of this trip.',
    descriptionPastTrips:
      'You are in the menu with one option, press Enter to remove this flight from your Past Trips Dashboard, press the Esc key to close this menu',
    descriptionSaveCard: 'You are in the menu with two options.',
    descriptionTripDetailCard: 'You are in the menu.',
  },

  segmentCard: {
    layover: 'Layover',
    header: {
      to: 'To',
      segments: `{stopsNumber} {stopsNumber, plural,
        one {segment}
        other {segments}
      }`,
    },
    flightDetailedInformation: {
      duration: 'Duration',
      flightNumberTitle: 'Flight',
      date: 'Date',
      departureTime: 'Departure time',
      arrivalTime: 'Arrival time',
    },
    flighBasicInformation: {
      emptySeats: 'No seats selected',
      title: 'Flight information',
      cabin: 'Cabin',
      fareFamily: 'Fare family',
      seats: 'Seats',
      aircraft: 'Aircraft model',
      meals: 'Meals',
      entertainment: 'Entertainment',
      interline: 'Interline',
    },
  },

  tripDetail: {
    sendReceipt: 'Send receipt',
    operated: 'Operated:',
    printItinerary: 'Print itinerary',
    addCalendar: 'Add to calendar',
    selectSeats: 'Select seats',
    requestUpgrade: 'Request an upgrade',
    changeNRItinerary: 'Change NR itinerary',
    cancelReservation: 'Cancel reservation',
    titlePassengerMultitraveller: 'Passengers in this reservation',
    checkedIn: 'Checked-In',
    readyforCheckIn: 'Information complete',
    infoNotcompleted: 'Incomplete information',
    addSpecialServices: 'Add special services',
    noSpecialServices: 'There are no special services for this passenger.',
    group: 'Group',
    tooltip: 'Seat selection is not available for this passenger.',
    cancelledReservation: 'Cancelled reservation',
    exchangesAccess: 'Modify Itinerary',
    sectionTitle: 'Travel Itinerary',
    subtitle: 'Review your flight details and stay informed',
    flightDetailsTitle: 'Flight Information',
    cabin: '{cabin_type} Cabin',
    gateLabel: 'Gate:',
    terminalLabel: 'Terminal:',
    noGate: '-',
    noTerminal: '-',
    gateAndTerminalLabel: 'Gate and Terminal:',
    noGateNoTerminal: 'Not Assigned',
    noFlightInformation: 'Information not available',
    fareFamilyAndEntertainment: {
      firstDescription: 'To learn more about your fare families,',
      secondDescription:
        'If you would like to discover how onboard entertainment works and obtain additional information, ',
      firstCTA: 'click here.',
      secondCTA: {
        text: 'visit our website to learn more.',
        link: '{copa_url}/en-gs/discover-copa-airlines/experience-on-board/entertainment/',
      },
    },
  },

  stackableAlerts: {
    economyBasic: {
      title: `You are flying in Economy Basic in one or more of your trip segments. Some options may be limited:`,
      description: '',
      callToActionText: '',
      itemList1:
        'Checked bags are available for an additional cost (exceptions apply for Venezuela)',
      itemList2: 'Booking changes are available for a fee',
      itemList3: 'Seat selection is available for an additional cost',
      itemList4: 'Refunds are not available.',
      itemList5: 'ConnectMiles Members will earn 50% of miles and segments.',
      preferMemberNote:
        'If you are a PreferMember, some of the conditions specified above may be different.',
    },
    migratory: {
      title: 'Important information about your trip',
      descriptionStart:
        'Find the immigration and health requirements for your next trip in our ',
      descriptionEnd: '.',
      link: '{copa_url}/travel-preparation-hub/en',
      linkText: ' information center',
      callToActionEvent: '{copa_url}/en/web/gs/travel-requirements',
      WCAGButtonClose: 'Button. Close alert.',
    },
    bookHold: {
      title: 'Payment required',
      description:
        'Your reservation will expire in {time}. To avoid cancellation, remember to complete your purchase.',
      callToActionText: 'Complete purchase',
    },
    expiredBookAndHold: {
      title: 'Expired reservation',
      description: {
        link: '{copa_url}/en-gs/customer-service/call-center/',
        linkText: 'Reservation Center',
        startText:
          'The time to pay this reservation has expired. Please make a new reservation or contact our ',
        endText: ' for more information.',
      },
    },
    bookAndHoldWithoutSSR: {
      title: 'Payment required',
      description: {
        startText:
          'To avoid cancellation of your reservation, remember to complete your purchase by contacting our ',
        reservationCenter: {
          text: 'Reservations Center',
          link: '{copa_url}/en-gs/customer-service/call-center/',
        },
        middleText: ' or visiting one of our ',
        salesOfices: {
          text: 'Sales Offices',
          link: '{copa_url}/en-gs/customer-service/sales-offices/',
        },
      },
    },
    indirectChannel: {
      title: 'Important information about your reservation',
      description:
        'This reservation was aquired through an intermediary. To make changes, cancel your reservation or other transactions, contact your point of purchase.',
    },
    cancelledReservation: {
      title: 'This reservation was canceled',
    },
    paymentAttemptsFailed: {
      title: 'We were unable to process your payment',
      description:
        'You have used up all of your attempts to process the payment. Please contact our',
      textLink: 'Reservation Center.',
      link: '{copa_url}/en-gs/customer-service/call-center/',
    },
    pendingPayment: {
      title: 'Processing Payment',
      description:
        'The payment for your trip to {to} is being proccessed. This process may vary depending on the payment method used. At the moment, some actions for your reservation are not enabled.',
    },
    minor01Alert: {
      title: 'Your itinerary has been adjusted',
      description:
        'It was necessary to perform minor changes to your itinerary. Please review your flight details below.',
      button: 'Acknowledge',
    },
  },

  tripDetailWCAG: {
    specialServicesSeeMoreWCAG:
      'See more information about your special service requests for this trip.',
    specialServicesSeeLessWCAG:
      'Hide information about your special service requests for this trip.',
    addSpecialServices: 'Request special services for this trip.',
    sendReceiptWCAG: 'Send a receipt for this trip.',
    printItineraryWCAG: 'Print a copy of this itinierary.',
    addCalendarWCAG:
      'Add the dates of this trip to the calendar of your choice.',
    renameTripWCAG:
      'RENAME, button. Use this option to change the name of this trip.',
    removeTripWCAG:
      'REMOVE, button. Use this option to remove this trip from your My Trips Dashboard.',
    selectSeatsWCAG:
      'Select Seats, Button. Use this button to select seats for each passenger in this trip',
    requestUpgradeWCAG:
      'Request Upgrade, Button. Use this button to request an upgrade for each passenger in this trip',
    cancelReservationWCAG:
      'Cancel Reservation, Button. Use this button to be redirected to the reservation cancellation flow.',
    changeItineraryButtonWCAG:
      'Modify Itinerary, Button. Use this button to be redirected to the modify itinerary flow.',
  },

  tripsButtons: {
    backToTripHub: 'Back to MyTrips',
    backToFindReservation: 'Find another reservation',
    save: 'Save to MyTrips',
    saved: 'Trip Saved',
  },

  tripsButtonsWCAG: {
    backToTripHubWCAG: 'Return to MyTrips',
    backToFindReservationWCAG: 'Return to Find my Reservation',
    saveWCAG: 'Save this trip in your MyTrips Dashboard',
    savedWCAG: 'Trip Saved',
    findReservation: 'Find your reservation, button.',
  },

  accordionSection: {
    seeMoreCTA: 'See more',
    seeLessCTA: 'See less',
    specialServices: 'Special services',
    specialServicesHyphen: '—',
    withMeal: 'Meals',
    withSpecialAssistance: 'Special assistance',
    withoutSpecialServicesAdded: 'No special services',
  },
  pastTripsDetail: {
    itinerary: 'Trip itinerary',
    from: 'From',
    toward: 'To',
    date: 'Date',
    flight: 'Flight',
    operated: 'Operated by',
    passenger: 'Passengers in this reservation',
    name: `Passenger name`,
    number: 'Ticket number',
    nameAndNumber: 'Name and ticket number',
  },
  memberMenu: {
    logOut: 'Log out',
    myAccount: 'My account',
    greeting: 'Hello,',
    status: 'Status',
    connectMiles: '# ConnectMiles',
    balance: 'Miles balance',
  },
  memberMenuWCAG: {
    pageDescription:
      'ConnectMiles Member Menu: Allows you to sign out, access your account, and view your ConnectMiles number and status',
    connectMilesLogo: 'ConnectMiles Logo',
    logOut:
      'Sign Out button: Press Enter to sign out from your ConnectMiles account',
    myAccount:
      'My account button: Press Enter to be redirected to your account page.',
    connectMiles: 'ConnectMiles number',
  },
  copaSPTooltip: {
    description:
      'Copa Showpass is our in-flight entertainment system, which allows you to wirelessly stream content to your own device through our Copa Airlines App.',
  },
  touchScreenOnDemand: {
    description:
      'Touch Screen On-demand is our personal entertainment system, where you can use screens located in your seat to select, control, and view content of your preference.',
  },
  overheadScreen: {
    description:
      'Overhead Screen is our shared in-flight entertainment option. Watch some of today’s best movies and hit shows on our overhead screens.',
    linkText: 'Learn more',
  },
  upcomingTripCardWCAG: {
    description:
      'Upcoming Trip Card Group. In this Group you will be able to see and manage details of your upcoming trip to {destination} on {departureDate}.',
    btnSeeReservation: 'See this trips details.',
    btnCheckIn: 'Check-in, button.',
  },
  saveTripWCAG: {
    description:
      'Button. Saved Trip to {destination} on {departureDate}. Use this button to see and manage details of this trip.',
  },

  FAQ: {
    title: 'Frequently asked questions',
    viewMore: 'More FAQs',
    linkViewMore:
      'https://help.copaair.com/hc/en-us/categories/360004256754-Preguntas-m%C3%A1s-frecuentes',
    contact: 'Get help',
    linkContact: '{copa_url}/en-gs/customer-service/',
    listOfFQA: {
      equipmentAdd:
        'Can I bring more bags than those indicated in the Baggage Allowance section?',
      equipmentAddBody:
        'Yes you can! During your baggage check process, our agents can help you check any additional baggage. You will need to pay an extra bag fee. Review our {link} for more information. With your safety and that of our employees in mind, many airports have implemented biosecurity protocols. Remember to arrive at the airport with enough time to complete your baggage check process without inconveniences.',
      nameLinkequipmentAdd: 'baggage policies',
      linkequipmentAdd:
        '{copa_url}/en-gs/travel-information/baggage-information/',
      webCheckIn: 'Can I check in through MyTrips?',
      webCheckInBody:
        'Yes you can! The option to check in will become available 24 hours before before your flight. Speed up your check in process by filling your personal information in the Passenger Information section.',
      accumulateMiles: 'How can I accumulate miles for my trip?',
      accumulateMilesBody:
        'To accumulate valuable miles for your trip, enter your ConnectMiles number, or the number of your frequent flyer program in the Passenger Information section. If you are not a member of our ConnectMiles program yet, you can fill out our {link} to sign up, and then add your new ConnectMiles number to the reservation.',
      linkAccumulateMiles: '{copa_url}/en-gs/enrollment',
      nameLinkAccumulateMiles: 'afiliation form',
      selectSeat: 'How can I select a seat before my trip?',
      selectSeatBody:
        'You can select a seat through the Passenger Information section. Depending on the fare that you have purchased, you will be able to select a seat for free if applicable, or you may also purchase a seat once your ticket has been confirmed. For your comfort, we have different types of seats available. You may find more information in our {link}.',
      linkSelectSeat:
        '{copa_url}/en-gs/legal/terms-and-conditions/terms-conditions-advanced-seat-assignment/',
      nameLinkSelectSeat: 'seats information page',
    },
  },

  alertScheduleChanges: {
    descriptionSing: {
      start:
        'There has been an update to your flight from {from} to {to}. Please contact our ',
      end: ' so that we may assist you. We apologize for the inconveniences.',
    },
    descriptionPlu: {
      start:
        'There has been an update to more than one of your flights. Please contact our ',
      end: ' so that we may assist you. We apologize for the inconveniences.',
    },
    link: '{copa_url}/en-gs/customer-service/call-center/',
    linkText: 'Reservation Center',
  },

  addToCalendar: {
    subjectFlight: 'Flight CM',
    subjectFrom: 'from',
    subjectTo: 'to',
    reservationCode: 'Reservation Code:',
    itinerary: 'ITINERARY: CM Flight',
    departure: 'Departure:',
    on: 'on',
    at: 'at',
    arrival: 'Arrival:',
    fareFamily: 'Fare Family:',
    travelerInfo: 'Traveler Information:',
  },

  retract: {
    header: {
      title: 'Cancel your reservation',
      infoText: 'Your trip to {destination}',
    },
    title: 'Do you want to cancel this reservation?',
    subTitle:
      'Your reservation is within our 24-hour Flexible Purchase, which means that by canceling, we will reimburse 100% of your payment according to our policy. Please read the following information carefully before proceeding.',
    dateTrip: 'Travel dates',
    reservationCode: 'Reservation code',
    fareFamily: 'Fare family',
    refundDetails: 'Refund details',
    baseAirfare: 'Airfare',
    taxes: 'Taxes',
    seats: 'Seats',
    subtotal: 'Subtotal',
    noRefundable: 'Nonrefundable',
    refundTotal: 'Refund total',
    infoImportant: 'Important information about your cancelation',
    item1: {
      start:
        'Copa Airlines offers full refunds of the reservations that meet the requirements of our 24-hour flexible purchase policy. Find the details of this policy ',
      linkText: 'here',
      link: '{copa_url}/en-gs/legal/terms-and-conditions/terms-conditions-flexible-cancelation/',
      end: '.',
    },
    item2:
      'The value of the reservation will be refunded to the payment method used to make the purchase.',
    item3:
      'The value of your refund should be reflected no more than two (2) bank account or credit card statements after the date of this refund request. This period may vary depending on your account statement cutoff date.',
    cancelReservation: 'Cancel this reservation',
    insuranceRefund1:
      'You have purchased travel insurance provided by Chubb for {value} {currency}. To process the refund of this service, you must communicate directly with Chubb through their',
    insuranceRefundLink: 'contact points',
    insuranceRefundUrl:
      'https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=en',
    insuranceRefund2:
      ', using your reservation code as your purchase reference.',
    cancelSummary:
      'Your reservation will be cancelled in its entirety and you will receive a reimbursement for {amount}',
    refundLink: '{copa_url}/en-gs/customer-service/call-center/',
    error: {
      toast: 'Something failed, please try again.',
      alert: {
        start:
          'Sorry! Due to an error, we were not able to cancel your reservation. Please, try again later or contact our ',
        end: '.',
        link: '{copa_url}/en-gs/customer-service/call-center/',
        linkText: 'Reservation Center',
      },
    },
  },

  shoppingCart: {
    title: 'Trip extras',
    subtitulo:
      'These are the trip extras that you have selected. You may pay them now or continue managing your trip.',
    verDetalle: 'View details',
    subtotal: 'Subtotal',
    impuestos: 'Taxes',
    total: 'Total ',
    cerrar: 'Close',
    pagoTotal: 'PAYMENT TOTAL',
    impuetso: ' in taxes',
    tiempoRestante: 'Remaining time:',
    pagarExtras: 'Pay trip extras',
    seatsPremium: 'Seat Premium ',
    barraTitle:
      'Your seat selection will expire in {time}. Complete your purchase now.',
    expireTitle: 'Your seat selection has expired',
    expireSubTitle:
      'The maximum time to purchase your selected seats has expired. You may select seats once again.',
    expireBtn: 'Return to reservation',
    WCAGBoton: 'Button. Return to reservation.',
    WCAGClose: 'Button. Close modal',
    WCAGTitle: 'Expired selection modal',
  },

  extraTrip: {
    title: 'Purchased trip extras',
    subtitle: 'These are the trip extras that you have already purchased.',
    tableDescription: 'Description',
    tableTraveler: 'Passenger',
    tableFlight: 'Flight',
    tableValue: 'Value',
    seat: 'Seat',
    'extra-bag': 'Luggage',
    'pet-in-cabin': 'Pet in cabin',
    'sport-equipment': 'Sports luggage',
    'music-instrument': 'Musical luggage',
  },

  reservationConfirmed: {
    title: 'We are processing your reservation',
    subtitle1: 'We are processing your trip to {to}. ',
    subtitle2: ' Your reservation code is ',
    subtitle3:
      'Once we have processed your payment, you will receive a confirmation email at ',
    subtitle4: '.',
    summary: 'Purchase summary',
    successfully: 'We are currently processing your payment of {monto}.',
    authorizationCode: 'Authorization code',
    nowYouCan: 'Now you can:',
    downloadCopaApp: 'Download the Copa Airlines App   ',
    downloadCopaAppText:
      'With the Copa Airlines App you can receive notifications regarding your flight status in realt time, check in, and access in-flight entertainment.*',
    downloadCopaAppSubText:
      '* In-flight entertainment only applies for flights with Copa Showpass',
    laptobTitle: 'Manage your reservation in My Trips ',
    laptobText:
      'In the My Trips section of copa.com you will be able to choose or change your seats, view your trip itinerary, and make changes to your reservation.',
    qrTitle: 'Web Check-In 24 hours before your flight',
    qrText:
      'Save time at the airport by completing Web Check-In up to 24 hours before your flight',
    btnGoToReservation: 'Go to reservation',
    lateBookingFee: 'Late Booking Fee',
    serviceFee: 'Service Fee',
  },

  payReservation: {
    titleBar: 'Pay reservation',
    title: 'Trip details',
    subTitle:
      'Review your reservation carefully before proceeding with checkout.',
    resumen: 'Reservation summary',
    btn: 'Continue to seat selection',
    layover: 'layover',
    airfare: 'Airfare',
    uplift: {
      description: 'You completed this purchase using Uplift.',
      linkText: 'More information.',
      link: '{copa_url}/es-pa/descubre-copa-airlines/uplift/',
    },
    alerts: {
      missingMilesTitle:
        'You are missing {miles} miles to purchase this reservation',
      missingMilesText:
        'Hold on to this trip! We have different options for you to complete this reservation: {link}',
      buyMiles: 'Buy Miles',
      buyMilesLink:
        'https://miles.copaair.com/login.html?product=buy&language=en',
      restrictedAccountTitle: 'Your account is restricted',
      restrictedAccountText:
        'Your account has been temporarily locked for security reasons. Please contact our ConnectMiles {link} for assistance.',
    },
    redemptions: {
      miles: 'Miles',
      others: 'Others',
      passengersGrouped: `{itemCount} {itemCount, plural,
        one { traveler}
        other { travelers}
      }`,
    },
  },

  taxes: {
    smallTitle: 'RESERVATION TOTAL',
    bigTitle: 'Taxes and fees detail',
    titleDescription: 'DESCRIPTION',
    titleMonto: 'AMOUNT',
    total: 'Total taxes and fees',
  },

  pseInformation: {
    title: 'Payment Information (PSE Colombia)',
    subtitle:
      'This is the information related to the purchase for future reference.',
    bank: 'Bank',
    date: 'Transaction date',
    status: 'Status',
    pendingStatus:
      'Pending transaction (please check if the debit was made effective in the bank)',
    approvedStatus: 'Transaction approved',
    orderReference: 'Reference of order',
    ipAddress: 'IP address',
    value: 'value',
    transaction: 'Transaction',
    descriptionLabel: 'Description of the payment',
    descriptionText: 'Purchase at Copa Airlines Colombia',
    transactionNumber: 'Reference number',
    company: {
      title: 'Company Contact',
      companyLabel: 'Company',
      companyText: 'COMPAÑÍA PANAMEÑA DE AVIACIÓN S.A.',
      nitLabel: 'NIT',
      nitText: '860.025.338-2',
      addressLabel: 'Address',
      addressText:
        'AV EL DORADO N° 103 – 08 INT 1 TERMINAL AEREO SIMON BOLIVAR Bogotá',
      phoneNumberLabel: 'Phone Number',
      phoneNumberText: '(571) 3578080',
    },
  },

  originalItinerary: {
    accordiontitle: 'View original itinerary',
    subtitle:
      'Once the new itinerary is confirmed, this itinerary will no longer be valid',
    close: 'See details',
    open: 'Hide details',
    noChanges: 'No Changes',
    flightModified: 'Flight {flight} has changed',
  },

  onBoardingModal: {
    title: 'We have made changes to this itinerary. Your action is required',
    descriptionBold:
      "We've had to make a schedule change to one or more of the travel segments of your upcoming trip to {destination}.",
    description2:
      'Please review the new itinerary; you will have the option to accept the changes or modify your travel dates.',
    subtitle: 'Follow these steps to accept or modify the new itinerary:',
    step1: 'Step 1',
    step1des: '— Review the updated travel itinerary.',
    step2: 'Step 2',
    step2des: '— Accept or modify the new itinerary.',
    step3: 'Step 3',
    step3des: '— Continue to My Trips to confirm your choice.',
    newItineraryBtn: 'View new itinerary',
    ctaWcag: 'Press here to close and go to your itinerary',
    buttonWcag: 'Press here to review your new itinerary',
  },

  loginBox: {
    title:
      'Sign in to your ConnectMiles account to continue with this reservation.',
    disclaimer:
      'You must be logged in with the account you originally made this reservation with.',
    button: 'Log In and continue',
  },

  loginSRT: {
    title: 'Sign in to your ConnectMiles account to continue.',
    userBox: 'Enter your username',
  },

  // ////////////////////////////// end translations My trips //////////////////////////////////

  languages: {
    english: 'English',
    spanish: 'Español',
    portuguese: 'Português',
    french: 'Français',
    dropdownDescription: 'IDIOMA/LANGUAGE/LÍNGUA',
  },

  headerNavigation: {
    signup: 'Join Now',
    language: 'Language',
    loggedInAs: 'Hello, {name}!',
    logout: 'Log Out',
    miles: '{number} miles',
  },
  loginForm: {
    user: 'Email, ConnectMiles number or username',
    passwordField: 'Password',
    loginButton: 'Log In',
    logoutButton: 'Log Out',
    inputPlaceHolderUser: 'Enter your username',
    inputPlaceHolderPrivatePhrase: 'Enter your password',
    forgotPrivatePhrase: 'Forgot Password? ',
    account: `Don't have an account?`,
    accountLink: ' Join now.',
  },
  clientService: {
    callCenter: 'Call Center',
    linkCallCenter: '{copa_url}/en-gs/customer-service/call-center/',
    serviceCenter: 'Service Center',
    linkServiceCenter: '{copa_url}/en-gs/connectmiles/',
  },
  loginFormAlerts: {
    loginError:
      "We're sorry! The information you provided does not match our records. Please check your entries and try again.",
    logOutError:
      'There was an error while trying to close your session. Try again.',
    logInUnknownError: 'Something failed. Please try again later.',
    invalidCredentials:
      'The information you provided doesn’t match our records. Please check your entries and try again.',
    blockedAccount: {
      title:
        'Your account has been temporarily locked for security reasons. Please contact the ConnectMiles service center for assistance. {link}',
      link: '{copa_url}/en-gs/',
      text: 'to ConnectMiles call center number',
    },
    wrongInformation:
      'The information you provided does not match our records. Please check your entries and try again.',
  },
  findReservationAlerts: {
    notFound:
      'We’re sorry! We couldn’t find your reservation with the information provided. Please check your entries and try again.',
    tooEarly:
      'It’s too soon to check in for your flight. Check-In opens 24 hours prior to departure, please come back then.',
    tooLate:
      'You are too late to check in. Please see an agent at the airport for assistanse.',
    otherCarrier:
      "Your first flight is operated by a partner airline. Please check in through our partner airline's website.",
    largeGroup:
      'You’re traveling in a large group which requires assistance from an airport agent. Please check in at the airport counter.',
    outOfSync:
      'It seems like your reservation needs to be reviewed by a service agent. Please contact our {link} for assistance.',
    noETKT:
      "We're sorry! We couldn't find your reservation with the information provided. Please check your entries and try again.",
    cancelledFlights:
      'It seems like your reservation needs to be reviewed by a service agent. Please contact our {link} for assistance.',
    restrictedScenarios:
      'There is a special request associated with your reservation. Please check in at the airport counter.',
    wrongLastName:
      'The last name you entered doesn’t match with our records. Please check your entries and try again.',
    incorrectETKT:
      "We're sorry! We couldn't find your reservation with the information provided. Please check your entries and try again.",
    eTicketRequired:
      'It seems like your reservation needs to be reviewed by a service agent. Please contact our {link} for assistance.',
    grouplarge:
      "You're traveling in a large group which requires assistance from an airport agent. Please check in at the airport counter.",
    PNRNoSegments:
      'It seems like your reservation needs to be reviewed by a service agent. Please contact our {link} for assistance.',
    serviceUnavailable:
      'Something failed while finding your reservation. Please try again.',
  },
  findReservationWCAG: {
    pageTitle: 'My Trips',
    pageDescription:
      'First page of the My Trips Flow, you need to provide your reservation number and last name to be able to find your reservation.',
    textSubtitle:
      'From My Trips you can see, manage and save your bookings with Copa Airlines',
    textWellcome: `To begin, let's find your reservation`,
    feebackBetaLink:
      'You´re using Web Check-In Beta. Press enter to send a email with your comments or suggestions from you email application',
    oldCheckinUrl: 'To check-in using the old version, click here ',
    copaLogoLink: 'Copa logo. Go to the Copa home page',
    extendedCopaLogo: 'Copa logo. Go to the Copa home page',
    copaLogoAltMobile: 'Copa logo. Go to the Copa home page',
    cmLogoAlt: 'ConnectMiles logo',
    cmSignupButton:
      'If you do not have a ConnectMiles account, press Enter to create one now.',
    cmLoginButton:
      'Log in, you can use your ConnectMiles profile to to find your trips even faster. Press Enter to enter the information',
    cmCloseLoginButton: 'Press enter to close this Connect miles Login pannel',
    cmLogoutButton: 'Press enter to logout from your ConnectMiles sesion.',
    languageSelection:
      'You are in a drop-down field with 3 options, use the up and down keys and then press Enter to select your preferred language',
    reservationSearchCodeField:
      'You can type either an alphanumeric code of 6 digits in case of you have a PNR code, or a numeric code of 13 digits in case of you have an e-ticket number, or a number of 9 digits if it is a ConnectMiles number.',
    reservationSearchLastNameField:
      'Enter the lastName that appears in your reservation',
    searcReservationButton:
      'Press enter to resultSearch your reservation and continue with your checkin process.',
  },
  loginFormWCAG: {
    pageDescription: `ConnectMiles Login Box: Allows you to get access to your member profile. Please use the Escape key whenever you'd like to return to the main menu.`,
    userNameField: 'Enter your email, ConnectMiles number or user',
    passwordField: 'Enter your ConnectMiles password',
    loginButton: 'Press Enter to log in with the credentials entered',
    forgotPrivatePhrase:
      'Press enter to go to ConnectMiles and recover your password',
    joinNow:
      'If you do not have a ConnectMiles account, press Enter to create one now',
  },
  customModalWCAG: {
    closeButton: 'Buttom close, press enter to close the pop up',
  },
  searchNumberForm: {
    inputLabelNumber: `Reservation Code or E-Ticket Number`,
    inputPlaceholderNumber: 'Enter code or number',
    inputLabelLastName: 'Last Name',
    inputPlaceholderLastName: 'Enter last name',
    search: 'Find your reservation',
  },
  ancillaries: {
    title: 'Travel Options',
    description: 'Upgrade your travel experience with these options.',
    carTitle: 'Rent a car',
    carDescription:
      'Different options to enjoy our destinations to the fullest.',
    carLinkDescription: 'Book now',
    carLink: 'https://cars.cartrawler.com/copa/en/?clientId=538733',
    hotelTitle: 'Book a hotel',
    hotelDescription: 'Find the perfect accomodation for your trip.',
    hotelLinkDescription: 'Find hotels',
    hotelLink:
      'https://sp.booking.com/index.html?aid=2027762;label=pagemanagebooking-ban-2027762-click_brand-mmbcanales',
    insuranceTitle: 'Travel insurance',
    insuranceDescription: 'Do you have travel insurance?',
    insuranceLinkDescription: 'Insure your trip here',
    redemption: {
      milesTitle: 'Replenish your mileage balance',
      milesDescription:
        'More miles now. More adventure later. Replenish your mileage balance and start planning your next trip.',
      milesCTA: 'Buy miles',
      milesLink: 'https://miles.copaair.com/?product=buy&language=en',
      hotelsTitle: 'Earn more miles in your stays!',
      hotelsDescription:
        'ConnectMiles Hotels is a site created exclusively for our members where you can earn from 500 to 15,000 miles per night with your hotel reservations. Choose from over 400,000 hotels and earn miles that will allow you to keep traveling to your dream destination.',
      hotelsCTA: 'Book now!',
      hotelsLink:
        'https://connectmileshotels.copaair.com/cm/home/en/?t=1625603506769',
    },
  },
  ancillariesWCAG: {
    contentDescription:
      'Travel Options Section. In this section you can choose among our additional Travel Options to upgrade your travel experience.',
    carImageDescription: 'Image. Family in a car.',
    carLinkDescription: 'Book now.',
    hotelImageDescription: 'Image. Man in hotel room.',
    hotelLinkDescription: 'Find hotels.',
    insuranceImageDescription: 'Image. Woman near a river in the woods.',
    insuranceLinkDescription: 'Insure your trip here.',
  },
  entertainment: {
    title: 'Entertainment',
    description:
      'Explore our entertainment options and enjoy our entire selection of content.',
    copaShow: 'Copa Showpass',
    showpassDescription:
      'Our inflight entertainment system will allow you to enjoy movies, series, music and magazines using your personal device.',
    showpassLinkDescription: 'Learn more here',
    showpassLink:
      '{copa_url}/en-gs/discover-copa-airlines/experience-on-board/entertainment/',
    panoramaTitle: 'Panorama of the Americas',
    panoramaDescription:
      'Discover our digital magazine and enjoy its wide variety of content from anywhere.',
    panoramaLinkDescription: 'Discover it here',
    panoramaLink: 'https://www.revistapanorama.com/digital/',
  },
  entertainmentWCAG: {
    contentDescription:
      'Entertainment Section. In this section, you can choose among our entertainment options to enjoy their wide variety of content.',
    showpassImageDescription: 'Image. Person holding a tablet',
    showpassLinkDescription:
      'Linkout to Copa Showpass Page. Double tap to go to our in-flight entertainment page and learn what it is about.',
    panoramaImageDescription: 'Image. Beach and phone with magazine title.',
    panoramaLinkDescription:
      'Linkout to our digital magazine. Double tap to go to Panorama of the Americas web page.',
  },
  whoPage: {
    title: 'Who travels with you?',
    description:
      'You are traveling in a group reservation; and we found more than one traveler with the information given',
    instruction: 'Please select passengers traveling with you:',
    chooseWhoButton: 'Continue with these passengers',
  },
  whoPageWCAG: {
    modalContainer:
      'You are traveling in a group reservation and there is more than one passenger with the same information provided. Please select from the list your name and the name of those passengers who you would like to continue.',
    checkboxWho: 'nombre de pasajero ',
    buttonSelectedPassengers: 'Press enter to selected passengers.',
    closeModal: 'Press enter to close this section',
  },
  travelerInfoForm: {
    year: 'Year',
    month: 'Month',
    day: 'Day',
    month01: 'January',
    month02: 'February',
    month03: 'March',
    month04: 'April',
    month05: 'May',
    month06: 'June',
    month07: 'July',
    month08: 'August',
    month09: 'September',
    month10: 'October',
    month11: 'November',
    month12: 'December',
    reviewTravelerInfo: 'Review Traveler Information',
    travelerNOfN: 'Traveler {current} of {total}',
    flightCheckIn: 'My Trips',
    passportInformation: 'Passport Information',
    travelerInfoIntro:
      'Please make sure to provide complete and accurate information about each passenger’s passport details. If you don’t comply with this requirement, the authorities may not permit you to travel or enter the boarding area.',
    ESTAText1:
      'All citizens intending to travel to the United States under the Visa Waiver Program are required to obtain authorization through the Electronic System for Travel Authorization (ESTA) before boarding a flight bound for the United States, ',
    ESTAText2:
      'please be sure to have the ESTA authorization before you complete your Check-In.',
    multipleProfiles:
      'You may select a traveler from your profile to pre-fill information below:',
    passportOptionUseProfile: 'Use Profile',
    inputPlaceHolderProfile: 'Select Profile',
    passportOptionManual: 'Manually Enter Passport Information',
    selectPassport:
      'You have two passports saved, please select the one you want to use:',
    inputLabelFirstName: 'First Name',
    inputPlaceHolderFirstName: 'Enter First Name',
    inputLabelLastName: 'Last Name',
    inputPlaceHolderLastName: 'Enter your last name',
    dateOfBirth: 'Date of Birth',
    dateOfBirthDescription:
      'The date format is: 2 digits to day from 01 to 31, 2 digits to month from 01 to 12, 4 digits to year',
    residencyCardDateDescription:
      'The date format is: 2 digits to day from 01 to 31, 2 digits to month from 01 to 12, 4 digits to year',
    genderOptionMale: 'Male',
    genderOptionFemale: 'Female',
    nationality: 'Country of Nationality',
    inputPlaceHolderNationality: 'Enter Passport Nationality',
    passportNumber: 'Passport Number',
    inputPlaceHolderPassportNumber: 'Enter Passport Number',
    expirationDate: 'Expiration Date',
    expirationDateDescription:
      'The date format is: 2 digits to day from 01 to 31, 2 digits to month from 01 to 12, 4 digits to year',
    expirationDateError: 'Please enter valid expiration date',
    countryOfIssue: 'Country of Issue',
    inputPlaceHolderCountryOfIssue: 'Enter Country of Issue',
    email: 'Email to send Boarding Pass',
    emailError: 'Enter valid Email address',
    useEmailForEverybody: 'Use this email for all traveler',
    knownTravelerInformation: 'Known Traveler Number',
    emergencyContactTitle: 'Emergency Contact',
    emergencyContactLabel: 'Name and Last Name',
    phoneCodeLabel: 'Country Code',
    phoneLabel: 'Phone Number',
    travelerNumber: 'Known Traveler Number',
    inputPlaceHolderTravelerNumber: 'Enter Number',
    redressNumber: 'Redress Number',
    redressNumberInputLabel: 'Redress Number',
    inputPlaceHolderRedressNumber: 'Enter Number',
    frequentFlyerProgram: 'Frequent Flyer Program',
    inputPlaceHolderFrequentFlyerProgram: 'Select Program',
    frequentFlyerNumber: 'Frequent Flyer Number',
    inputPlaceHolderFrequentFlyerNumber: 'Enter Number',
    warningFrequentFlyerNumber:
      'This is an award ticket, if this is not your frequent flyer number and you change it, you may lose the benefits of the account owner.',
    emergencyContact: 'Emergency Contact',
    fullName: 'Full Name',
    inputPlaceHolderFullName: 'Enter Full Name',
    PhoneNumber: 'Phone Number',
    inputPlaceHolderPhoneNumber: 'Enter Phone Number',
    countryOfResidence: 'Country of Residence',
    inputPlaceHolderCountryOfResidence: 'Enter Country of Residence',
    noResultMsgCountryOfIssue: 'No results found',
    noResultMsgNationality: 'No results found',
    noResultMsgCountryOfResidence: 'No results found',
    noResultMsgState: 'No results found',
    gender: 'Gender',
    whereAreYouStaying: 'Where are you staying in USA?',
    stayingStreetAddress: 'Street Address',
    inputPlaceHolderStreetAddress: 'Enter Address',
    stayingZipCode: 'Zip Code',
    inputPlaceHolderZipCode: 'Enter Zip Code',
    stayingCity: 'City',
    inputPlaceHolderCity: 'Enter City',
    stayingState: 'State',
    inputPlaceHolderState: 'Enter State',
    usResidencyCard: 'U.S. Residence Card',
    residencyCardNumber: 'Card Number',
    inputPlaceHolderCardNumber: 'Enter Card Number',
    residencyCardDate: 'Expiration Date',
    sentenceInfo:
      'Please make sure to provide complete and accurate information about each passenger’s travel and passport details. If you do not comply  with this requirement, the authorities may not permit you to travel or enter the boarding area.',
    addButton: 'Add',
    removeButton: 'Remove',
    estaText:
      'All citizens intending to travel to the United States under the Visa Waiver Program are required to obtain authorization through the Electronic System for Travel Authorization (ESTA) before boarding a flight bound for the United States, please be sure to have the ESTA authorization before you complete your Check in.',
    enterFFProgram: 'Enter Frequent Flyer Program',
    optionalSectionLabel: '(Optional)',
    saveButton: 'Continue',
    inputLabelEmail: 'Email to send Boarding Pass',
    inputPlaceHolderemail: 'Enter Email',
    redemptionText:
      'This is an award ticket, if this is not your frequent flyer number and you change it, you may lose the benefits of the account owner.',
    connectMilesSectionTitle:
      'You already have information stored in your profile, select the desired profile to pre-fill the passport information required below.',
    prePopulateEmail: 'Use this email for all travelers',
    prePopulatedWhereAreYouStaying: 'Use this address for all travellers',
    cmEnrollmentTitle:
      'Not a ConnectMiles Member? Join now and get 500 extra miles on this trip!',
    cmEnrollmentParagraph:
      'Join ConnectMiles and start earning miles today. When enrolling, we will send you an email to {email} for you to finalize activating your account. Remember that ConnectMiles requires that each member uses a unique email address to create their account.',
    cmEnrollmentAccept:
      'I would like to enroll to ConnectMiles and accept the {link}',
    cmEnrollmentLink:
      '{copa_url}/en-gs/legal/terms-and-conditions/terms-and-conditions-conectmiles/ ',
    cmEnrollmentLinkText: 'Terms & Conditions of the program.',
    linkAgreeWcag:
      'Press enter to review the Terms and Conditions of the program',
    updaProfileCheckBox: 'Save updated information to my ConnectMiles profile',
    inTransitCheckBox: 'In transit',
    inTransitCheckBoxWCAG:
      'Press to indicate that you are in transit in the United States',
  },
  formValidations: {
    requiredFieldError: 'This field is required',
    max20CharactersError:
      'You have entered too many characters. The maximum number of characters is 20.',
    max32CharactersError: 'Max 32 characters allowed',
    max50CharactersError: 'Max 50 characters allowed',
    max64CharactersError: 'Max 64 characters allowed',
    max100CharactersError:
      'You have entered too many characters. The maximum number of characters is 100.',
    max255CharactersError: 'Max 255 characters allowed',
    onlyLettersError: 'Only letters allowed',
    onlyNumbersError: 'Only numbers allowed',
    min3AndMax12CharactersError: 'Minimum 3 and maximum 12 characters allowed',
    alphaNumericError: 'Letters and numbers allowed',
    dateFormatError: 'Invalid date format',
    dateLowerThanCurrentDateError: 'Should be lower than current date',
    dateGreaterThanCurrentDateError: 'Should be greater than current date',
    dateOfBirthError: 'Enter a valid date of birth',
    emailFormatError: 'Invalid email format',
    invalidFormat: 'Invalid format',
    lengthZipCode: 'Minimum 5, maximum 10 characters are allowed',
    lengthAdress: 'Minimum 1 and maximum 31 characters allowed',
    lengthCity: 'Minimum 1 and maximum 15 characters allowed',
    lengthEmergencyContact: 'Max 15 characters allowed',
    lengthPhoneCode: 'Maximum 8 characters are allowed',
    lengthPhoneNumber: 'Max 10 characters allowed',
    ssrRepeatedValue: 'This service has already been added for this passenger.',
  },
  travelerInfoFormAlerts: {
    pageTitle: 'Página de información de pasajeros',
    pageDescription:
      'Para completar el flujo de Check-In debed proporcionar información, algunos campos son obligatorios y otros son opcionales.',
    FFNChanged:
      'Your Frequent Flyer Information has been updated. Please get a new boarding pass to reflect this change.',
    UpdateErrorFFN:
      'Something failed while updating the frequent flyer information for this traveler. Please try again.',
    UpdateError:
      'Something failed while retrieving your reservation. Please try again later.',
    UpdateDefaultError:
      'Something failed while updating the frequent flyer information for this traveler. Please try again.',
    saveSuccess: 'The traveler information has been saved successfully ',
    UpdateErrorAPIS:
      'Something failed while updating the traveler information for this traveler. Please try again.',
    leaveScreenWarning:
      'You have not saved the passenger information if you go back you will lose all the information and you will have to start again later. Are you sure you want to return to the itinerary?',
    repeatedPassport:
      'The passport number you entered is being used by another passenger in your group. Please enter a unique passport number.',
    FFNMatchError:
      "Something's wrong! The frequent flyer number you entered doesn't match the traveler's name. Please check your entries and try again.",
    FFNNotFoundError:
      "Something's wrong! The frequent flyer number you entered cannot be found. Please check your entries and try again.",
    expiredPassport:
      'Your passport is expired. Please enter a valid expiration date.',
    expiredResidenceCard:
      'Your residence card is expired. Please enter a valid expiration date.',
    emailError:
      'Something failed while updating your email information. Please try again.',
    leavePageTitle: 'Are you sure you want to leave this page?',
    leavePageMessage:
      'You have not saved the information, if you go back you will lose all the information and you will have to start again later. Are you sure you want to return to the itinerary?',
    leavePageCTA: 'Leave This Page',
    stayCTA: 'Stay on This Page',
    leaveTraveler: 'Are you sure you want to leave ?',
    leavePageTravelerMessage:
      'You have not saved the passenger information, if you go to the next traveler you will lose all the information. Are you sure you want to continue?',
    leaveTravelerCTA: 'Continue',
    stayOnTravelerCTA: 'Stay on This Page',
    fillOutInfantInfo:
      "You're travelling with an infant, please make sure that his information is completed before you continue.",
    fillOutAdultInfo:
      "You are traveling with an infant, please complete the adult's traveler information form first.",
    genericEnterError:
      'Something failed while loading the traveler information page. Please try again.',
    cmEnrollmentConfirmation: 'Thanks for choosing ConnectMiles!',
    cmEnrollmentContinue: 'Continue',
    cmEnrollmentNumber: 'Your ConnectMiles number is',
    cmEnrollmentEmail: 'Your account email is',
    cmEnrollmentInstrucction:
      'We have sent you an email with instructions to complete your new profile setup',
    cmEnrollmentError:
      'Something failed while creating your frequent flyer account. Please try again.',
  },
  travelerInfoFormWCAG: {
    pageTitle: 'Travelers information page',
    pageDescription:
      'To complete the check-In flow you need to provide information, some fields are mandatory and others are opcional. You are in {name} information section,',
    travelerName:
      'You are in {name} information section, were you need to provide all passport information; if you don´t comply with this the authorities may not permit you to travel or enter the boarding area.',
    backButton:
      'If you press enter yo will go back to the dashboard to fill the traveleres information press tab',
    backButtonAlt: 'Icon go to back',
    firstName:
      'Type your given name, only letters and white spaces are allowed.',
    lastName:
      'Type your last name or ´surname´, only letters and white spaces are allowed.',
    gender:
      'Gender, Use tap key to change between male and female gender, after that press enter or space.',
    passportNumber:
      'Type your passport number, this is an alphanumeric field, minimum 3 characters, maximum 12 characters are allowed.',
    countryOfIssue:
      'Auto complete field, type the first 3 letters of your country of issue and press enter.',
    nationality:
      'Auto complete field, type the first 3 letters of your country of birth and press enter.',
    countryOfResidence:
      'Auto complete field, type the first 3 letters of your country of residence and press enter.',
    emergencyContact:
      'Contact information for a person who is not traveling with you.',
    address:
      'Type the address where you are going to stay on the United States.',
    city: 'Type the destination city.',
    state: 'Auto complete field, type the first 3 letters of State.',
    residentCardNumber:
      'Type your residence card number,   it`s an alphanumeric field, minimum 3 maximum of 12  characters are allowed.',
    residentCardExpirationDay:
      'Use the up and down arrow keys to select the expiration day the residence card.',
    residentCardExpirationMonth:
      'Use the up and down arrow keys to select the expiration month the residence card.',
    residentCardExpirationYear:
      'Use the up and down arrow keys to select the expiration year the residence card.',
    useProfileRadioB:
      'Radio button section, Use up and down keys to change between options, Option 1 use your profile to auto complete information or option 2 use manual input, after that press enter or space.',
    manualInfoRadioB:
      'Radio Button section.  Use up and down keys to change between options, Option 2, enter manually the information',
    selectProfile:
      'Drodpdown, use the up and down arrow keys to select the profile to autocomplete',
    radioButtonPassportOptions:
      'Radio Button section. This profile has more than 1 passport, use you up and down keys to  select which passport you want to use.',
    knownTravelerNumberAdd:
      'The known Traveler number, is an U.S. identification record for the U.S. TSA PreCheck program, permitting travelers to pass through an expedited security screening at certain U.S. airports. Press enter.',
    emergencyContactAdd: 'Press to add an emergency contact',
    emergencyContactRemove: 'Press to hide the emergency contact',
    enterEmergencyContact: 'Enter Name and Last Name',
    enterCodeEmergencyContact: 'Enter Country Code',
    enterPhoneEmergencyContact: 'Enter Phone Number',
    knownTravelerNumberRemove:
      'To remove the Known Traveler Number press enter',
    knownTravelerNumber:
      'Type your known traveler number, It`s an alphanumeric field, minimum 3, maximum of 12 characters are allowed',
    redressNumberAdd:
      'The Redress number, is a Security clearance number issued by the Department of Homeland Security for certain travelers. Press Enter.',
    redressNumberRemove: 'To remove the Redress number press enter.',
    redressNumber:
      'Type your redress number, it`s an alphanumeric field, minimum 3 maximum of 12  characters are allowed.',
    frequentFlyerAdd:
      'If you have a frequent flyer program you can enter it. Press Enter',
    frequentFlyerRemove:
      'To remove or close the Frequent Flyer number press enter',
    frequentFlyerNumber:
      'Type your frequent flyer number, it`s an alphanumeric field.',
    frequentFlyerProgram:
      'Frequent flyer program dropdown. Use the up and down arrow keys to move and select the correspondent program.',
    dayOfBirth:
      "Use the up and down arrow keys to select the day of the passenger's birth.",
    monthOfBirth:
      "Use the up and down arrow keys to select the month of the passenger's birth.",
    yearOfBirth:
      "Use the up and down arrow keys to select the year of the passenger's birth.",
    dayOfExpiration:
      "Use the up and down arrow keys to select the passenger's passport expiration day.",
    monthOfExpiration:
      "Use the up and down arrow keys to select the passenger's passport expiration month.",
    yearOfExpiration:
      "Use the up and down arrow keys to select the passenger's passport expiration year.",
    email: '. Type the email were you will like to receive your boarding pass.',
    saveTIFButton:
      'Press enter to save the information and go back to the dashboard, if there is more than one passsenger it will redirect you to the next passenger.',
    continueModal:
      'You have not completed all the passport information, if you press enter you will loose the information. If you click tab you can stay on this page and complete the information.',
    stayModal:
      'press enter to stay on the traveler´s information page and complete the required fields.',
    zipCode:
      'Type the zip code of the address where you are going to stay on the United States.',
  },
  dashboard: {
    pendingPayment: 'Payment pending',
    greetingTitle: 'Welcome! Check-In is available for your trip',
    checkInCompleteTitle:
      'Check-in completed! You may get your boarding pass or change your seat',
    reviewUpcomingFlights: 'Review Flight Details',
    layover: 'Layover',
    date: 'Date',
    flight: 'Flight',
    airline: 'Airline',
    terminalGate: 'Terminal / Gate',
    onTime: 'On Time',
    advanced: 'Early',
    delayed: 'Delayed',
    canceled: 'Canceled',
    duration: 'Duration',
    operatedBy: 'Operated by',
    travelerInformation: 'TRAVELER INFORMATION',
    seatTo: 'SEAT TO {}',
    SeatSelectedButtonName: 'Change',
    altImagen: 'Image choose',
    NoSelectedSeatButtonName: 'Choose',
    readyCheckIn: 'Ready for Check-In',
    checkedIn: 'Checked-In',
    failedCheckIn: 'Failed Check-In',
    edit: 'Edit',
    getBoardingPass: 'Get Boarding pass',
    selected: 'Selected',
    noSelected: 'Choose Your Seat',
    infoApis:
      'You must complete this traveler’s information before checking-in',
    updateInformation: 'Complete Information',
    titleSegment: '{departure} TO {arrival}',
    changeSeat: 'Change',
    businessSeat: 'Business Class',
    emergencySeat: 'Emergency Seat',
    seatSelected:
      'Traveler {passengerName} has seat {seatCode} asigned for the flight {origin} and {destination}. Press enter to change seat.',
    seatNotSelected:
      'Traveler {passengerName} seat not selected for the flight {origin} and {destination}. Press enter if you want to change the seat.',
    editInformation: 'Edit information',
    starAllianceMemberAltText: 'Copa is a member of the Star Alliance network',
    copyRight:
      'Copyright © 2020 Copa Airlines, Incorporated. All rights reserved.',
    LAXAirport:
      'Due to construction work at LAX, we recommend that you arrive at least four hours before departure of your flight to have enough time to arrive at your gate.',
  },
  dashboardAlerts: {
    scheduleChange:
      'There’s been an update to your itinerary, please review your new flight details. We apologize for the inconvenience.',
    delay:
      'There’s been an update to your itinerary, please review your new flight details. We apologize for the inconvenience.',
    paxINF: 'Seat selection not available for infants.',
    paxNRS:
      'Seat selection not available for stand-by passengers. Your seat will be assigned at the gate.',
    interline:
      'Flight not operated by Copa Airlines. Your seat will be assigned by the operating airline.',
  },
  connectMilesBlock: {
    message: 'Log In to check in even faster!',
  },
  wcag: {
    pageTitle: 'Web Check-In dashboard page',
    pageDescription: 'Main page of Web Check-In',
    openPanel: 'Add {title} section',
    closePanel: 'Remove {title} section',
    seatSelectedDashboardMessage:
      'Traveler {passengerName}, seat {seat} of the flight {flightNumber} operated by {airlineName} from {departureCity} {departureAirportName} airport to {arrivalCity} {arrivalAirportName} airport. Press enter to change this seat',
    seatNoSelectedDashboardMessage:
      'Traveler {passengerName}, seat not selected for the flight {flightNumber} operated by {airlineName} from {departureCity} {departureAirportName} airport to {arrivalCity} {arrivalAirportName} airport. Press enter to choose a seat',
    editTravelerInfoButtonMessage:
      'Traveler {passengerName}, to edit the traveler information related to this and other travelers please press enter',
    boardingPassButtonMessage:
      'Traveler {passengerName}, to get a boardingPass for this traveler please press enter',
    updateinfoButtonMessage:
      'Traveler {passengerName}, to update the traveler information related to this and other travelers please press enter',
    nextSegmentsBTN: 'next segments',
    previousSegmentsBTN: 'previous segments',
    termsAndConditionsCheckboxDescription:
      'Restricted materials: some everyday products can be dangerous when transported on the aircraft in carry-on and/or checked baggage. Please ensure there are no forbidden dangerous goods in your baggage like: weapons, lithium batteries, gases, flammables liquids and solids, poisonous and other dangerous. Required documents: The passenger is responsible for all necessary travel documents including passport, visas and any other additional document required by law or regulation in the countries of destination or transit. It is recommended to all passengers to be aware of their vaccination and health history, specifically, the International Certificate of Vaccination against Yellow Fever, if they are traveling to an endemic country. If you agree please check this checkbox',
    subscribeACI:
      'You have future flights that are elegible for Automatic Check-in. All travelers will be checked in automatically when the 24hr check-in period opens up. ',
    restrictedItemsLink:
      'Some everyday products can be dangerous when transported on the aircraft in carry-on and/or checked baggage. Please ensure there are no forbidden dangerous goods in your baggage like: Weapons, Batteries, Gases, Flammables liquids or solids, Powder Substances, Other Dangerous. For more information enter on this link, to go to the Restricted Items section of Copa´s baggage policy',
    segmentsmessage:
      'flight {current} of {total},  FLight number {flightNumber} date {flightDate} from {departureCity} airport {departureAirport} at {departureTime} terminal {departureTerminal} Gate {departureGate} seat:  {seatCode}  duration flight time: {duration} - Arrival to {arrivalCity} airport {arrivalCityAirport} at {arrivalTime}  Terminal {arrivalTerminal} , gate {arrivalGate}. ',
  },
  seatMap: {
    seat: '{seatType} Seat',
    norecline: 'No recline',
    mouseoverPrice: 'From',
    legendTitle: 'Legend',
    section: '{section} Section',
    descriptionTravellerList: 'Select passenger',
    yourFlight: 'Your flight',
    flightNOfN: 'Flight {current} of {total}',
    flightTo: 'to {destination}',
    seatN: 'Seat {seat}',
    noSeatSelected: 'No seat selected',
    tapOnSeat: 'Choose who sits here',
    tapOnSeatMobile: 'Tap a seat to select it',
    chooseWhoSitsHere: 'Choose who sits here',
    travelTime: 'Travel time: ',
    notApplicable: 'N/A',
    businessClass: 'Business Class',
    economyClass: 'Economy Class',
    dreamsBusinessClass: 'Dreams Business Class',
    backToTop: 'Back to Top',
    restroom: 'Restroom',
    galley: 'Galley',
    seatDetails: 'Seat Details',
    businessSeat: 'Business',
    unavailable: 'Unavailable',
    preferMembersAlert:
      'ConnectMiles PreferMembers enjoy advanced seat assignment for free',
    extraLeg: 'Extra Legroom',
    favorableLocation: 'Favorable Location',
    premiumSeat: 'Premium',
    convenientSeat: 'Convenient',
    regularSeat: 'Regular',
    exitRow: 'Emergency Exit',
    row: 'Row {row}',
    boardingTittle: 'Boarding',
    boardingSeatGroup: 'Group {group}',
    boardingGroup: 'Boarding Group: {group}',
    pitch: 'Pitch: {pitch}',
    flatBed: 'Flat Bed',
    seatRecline: '{recline} Seat Recline ',
    readingLight: 'Reading Light',
    copaShowPass: 'Copa Showpass',
    outlets: 'USB & AC Outlet',
    usbPort: 'USB Port',
    rests: 'Footrest & Leg Rest',
    sharedAcOutlet: 'Shared AC Outlet',
    electronicDeviceHolder: 'Electronic Device Holder',
    inFlightEntertainment: 'In Flight Entertainment',
    overheadScreen: 'Overhead Screen',
    dispositivoPersonal: 'Personal Device',
    onDemandScreen: 'Touch Screen On-demand',
    onDemandScreen16: '16" Touch Screen On-demand',
    seatbackOnDemand: 'Seatback on-demand',
    scroll: 'Scroll',
    exitSeat: 'Exit',
    legendPrice: 'From {price}',
    legendFreePrice: 'Free',
    addFFNModalTitle: 'Include your ConnectMiles Number',
    addFFNModalLabel: 'ConnectMiles Number',
    addFFNModalPlaceHolder: 'Enter ConnectMiles number',
    addFFNModalParagraph:
      'ConnectMiles PreferMembers enjoy exclusive benefits when flying with Copa Airlines, including free advanced seat assignment. Please include your ConnectMiles number now to receive this benefit. ',
    previousFlight: 'Previous Flight',
    nextFlight: 'Next Flight',
  },
  seatMapButtonBar: {
    addConnetMilesNumber: 'Include now',
  },
  seatMapWCAG: {
    seat: 'You are in the seat {seat} {location}, {seatType}, {topPerk}, {norecline}, Price: {price}. Press enter if you wish to assign this seat to a passenger.',
    pageDescription:
      'Seat selection page to {arrival}. You can select seat for all passengers in the reservation (except for infants); remember that seat selection is per flight.',
    backToDashboard:
      'Button, if you press enter yo will go back to Trip Detail.',
    passengerNumberAndSeat: 'passenger {number}, seat {seat}',
    passengerNumber: 'passenger {number}',
    passengersList:
      'Select a passenger from the list: {passengers}. To navigate between passengers press Tab and to select press Enter.',
    continueButton: 'Continue button.',
    backArrowButton:
      'Back Button, if you press Enter you will go back to the passenger list.',
    addConnetMiles: 'Press Enter to add your ConnectMiles number',
    openLegend: 'Press enter to view the seat map legend.',
    addFFNTitleModal:
      'Add your ConnectMiles number to enjoy advanced seat assigment for free. You can add a frequent flyer number per each passenger',
    addFFNCloseModal: 'Close buttom , press enter to close the pop up',
    addFFNContinueModal: 'Press enter to continue with your seat selection',
    addFFNFieldWCAG:
      'Type the frequent flyer number for {passenger}. Remember it`s an alphanumeric field',
  },
  pageNotFound: {
    error: '404 ERROR',
    description: 'Sorry, this page couldn’t be found.',
    btn: 'Back to My Trips',
  },
  scrollAdvisor: {
    text: 'Scroll',
  },
  baggageAllowance: {
    priceLockMessage:
      'Please note that the baggage allowance will become available once you have completed the payment for your reservation.',
    title: 'Baggage Allowance',
    checkedBag: {
      plural: '{amount} Checked Bags',
      singular: '{amount} Checked Bag',
    },
    carryOnBag: {
      plural: '{amount} Carry-on Bags',
      singular: '{amount} Carry-on Bag',
    },
    personalItems: {
      plural: '{amount} Personal items',
      singular: '{amount} Personal item',
    },
    moreInfoBanner: {
      startText: 'For more information, please see ',
      linkText: 'Copa Airlines Baggage Policy',
      endText: '.',
      link: '{copa_url}/en-gs/travel-information/baggage-information',
    },
    interline: {
      startText:
        'One of your flights is not operated by Copa Airlines. Please verify our reference to ',
      linkText: 'Interline Baggage Allowance Policies from partner airlines ',
      endText: 'or get in touch with one of our service agents.',
      link: '{copa_url}/en-gs/discover-copa-airlines/alianzas-con-aerolineas/interline-agreements/',
    },
    infant: {
      startText:
        'Infants may not carry checked bags. For more information, please see ',
      linkText: 'Copa Airlines Infant Baggage Policy',
      endText: '.',
      link: '{copa_url}/en-gs/travel-information/baggage-information/luggage-for-infants/',
    },
    baggageInfoNotAvailable: 'Information not available',
    link: 'Link.',
    WCAG: {
      checkedBag: 'You are allowed {amount} {weight}{unit} checked pieces',
      carryOnBag:
        'You are allowed {amount} {weight}{unit} pieces of hand luggage',
      personalItem: 'You are allowed {amount} personal items',
      openSection:
        'See more information about your baggage allowance for this trip, {baggage}',
      closeSection:
        'Hide information about your baggage allowance for this trip.',
      title: 'This is your baggage allowance for your trip to',
    },
    error: {
      message:
        'We are unable to retrieve your baggage allowance information at this time.',
      startText: 'Be sure to see ',
      linkText: 'Copa Airlines Baggage Policy',
      link: '{copa_url}/en-gs/travel-information/baggage-information/',
      endText: 'for more information.',
    },
  },
  summaryDetails: {
    title: 'Selection Summary',
    subtitle: 'YOUR TRIP EXTRAS',
    seats: 'SEATS',
    flightDestination: '{departure} to {arrival}',
    flightLength: 'Flight {first} of {last}',
    subtotal: 'Subtotal',
    taxes: 'Taxes',
    total: 'Total',
    freeSeat: 'Free',
    freeTotal: 'Free selection',
    exitRow: 'Emergency Exit',
    convenientSeat: 'Convenient',
    premiumSeat: 'Premium',
    noSelection: 'No selection',
    interlineSegment:
      'This flight is not operated by Copa Airlines, seat selection. is not available.',
  },
  summaryDetailsWCAG: {
    title: 'Details of the seat selection per segment, per passenger',
    closeModal: 'Press button to close the seat selection summary modal ',
    summaryButtonDashBoard:
      'Press button to view the details of your trip extras',
  },
  sellSeatsSuccess: {
    title: 'Purchase successful',
    titleError: 'Purchase confirmation',
    subtitle:
      'We have charged {amount}. You will receive a confirmation to the email {email}.',
    purchaseSummary: 'Purchase summary',
    authCode: 'Authorization code:',
    subtotal: 'Subtotal',
    taxes: 'Taxes',
    total: 'Total',
    back: 'Return to reservation',
    alertErrorSellSeats: {
      start:
        'We processed your payment successfully, but your seats could not be assigned at this time. Please contact our ',
      linkText: 'Reservation Center',
      link: '{copa_url}/en-gs/customer-service/call-center/',
      end: ' for assistance. We apologize for the inconvenience.',
    },
    WCAG: {
      title: 'Detalles de compra de sillas exitosa',
      closeModal:
        'Fees and taxes modal close button. Press the button to close the modal and continue with the purchase',
      back: 'Presiona el botón para volver a tu reserva',
    },
  },
  sellSeatsError: {
    title: 'We were unable to process your payment',
    notice:
      'You have used up all of your attempts to process the payment. Please contact our ',
    back: 'Return to reservation',
  },
  seats: {
    types: {
      [SeatTypes.PREMIUM]: 'Premium',
      [SeatTypes.CONVENIENT]: 'Convenient',
      [SeatTypes.EXIT_ROW]: 'Exit Row',
      [SeatTypes.REGULAR]: 'Regular',
      [SeatTypes.ECONOMY_EXTRA]: 'Economy Extra',
      [SeatTypes.BUSINESS]: 'Business',
      [SeatTypes.DREAM_ROW]: 'Flat Bed',
    },
    descriptionWithType: '{type} Seat',
  },
  paytmentsError: {
    title: 'We were unable to process your payment',
    redemptionTitle: 'We were unable to process your payment',
    notice: 'An error occurred while processing your payment',
    redemptionNotice:
      'Sorry, something went wrong while trying to redeem your miles.',
    message: 'Please try again later',
    redemptionMessage: 'Please try again at another time.',
    goToMyTrips: 'Return to My Trips',
    redemptionGoToMyTrips: 'Go back to My Trips',
    WCAG: {
      title: 'We were unable to process your payment',
      back: 'Button. Return to My Trips',
    },
  },
  invalidAccount: {
    title: 'Sign in with the correct account to continue',
    message1:
      'We have detected that you logged in with a different account than the one you used to book this reservation with miles. Please login with the correct account ',
    message2: ' to complete the purchase.',
    button: 'Sign in with another account',
  },
  differentAccountRedemption: {
    title: 'Sign in with the correct account to continue',
    message1:
      'We have detected that you logged in with a different account than the one you used to book this reservation with miles. Please login with the correct account ',
    message2: ' to continue.',
    defaultMessage:
      'We have detected that you logged in with a different account than the one you used to book this reservation with miles. Please login with the correct account number to complete the purchase.',
    CTA: 'Sign in with another account',
  },
  modalBackOrigin: {
    modalTitle: 'Do you want to exit this page?',
    modalBody:
      'You have not yet completed the payment. If you go back now, you will not complete your purchase and your selection will not be confirmed.',
    stayPageCTA: 'Stay on this page',
    backOriginCTA: 'Exit this page',
    backOriginTitleWCAG:
      'Modal.Are you sure you want to exit payment? If you go back now, you will not complete your purchase and your selection will not be confirmed.',
    stayPageButonWCAG:
      'Buttom, press enter to stay on this page and continue with the payment',
    backOriginButonWCAG: 'Buttom, press exit this page.',
  },
  rebooking: {
    newSrtOnboardingModal: {
      title: 'We have made changes to this itinerary. Your action is required.',
      subtitle:
        'Please review the new itinerary; you will have the option to accept the changes or modify your travel dates.',
      step1: {
        title: 'Step 1',
        subtitle: 'Review the travel itinerary.',
      },
      step2: {
        title: 'Step 2',
        subtitle: 'Accept or modify the itinerary.',
      },
      step3: {
        title: 'Step 3',
        subtitle: 'Proceed to My Trips to confirm.',
      },
      cta: 'Continue',
    },
    screenTitle: 'Action Required: Accept or Modify The New Travel Itinerary',
    pageDescription: `The itinerary for your upcoming trip to {destination} has been modified. We've booked you on the best available alternative for your trip. If this new itinerary suits your travel plans, please choose the option "This itinerary works for me".  Otherwise, you can modify your travel dates using the "Modify itinerary" option.`,
    newItinerary: 'New itinerary',
    noChanges: 'No Changes',
    buttons: {
      itineraryOK: 'This itinerary works for me',
      modifyItinerary: 'Modify itinerary',
      acceptItinerary: 'Accept new itinerary.',
      seeFlightOptions: 'See flight options',
    },
    stackableAlert: {
      title:
        'Did you request any special service? Read this information carefully.',
      description: `###### If you have already requested any special service (special baggage, pet on board, special assistance or similar) you will have to contact our {link} to transfer these services to your new flights.`,
      linkDescription: 'Reservations Center',
      link: '{copa_url}/en-gs/customer-service/call-center/',
    },
    search: {
      title: 'To {destination}',
      info: 'Change flight dates',
      datePickLabel: 'Choose a new date for your flight to {destination}',
      dateLabel: 'Departure date',
      flightOptions: 'See flight options',
      notModifyInfo:
        "If you don't want to modify this flight, go to the next one.",
      notModifyFlight: "Don't modify this flight",
      availableFlights: 'Available flights',
      previousDay: 'Previous day',
      nextDay: 'Next day',
      flightAssigned: 'Current flight',
      error: {
        info: 'Oops! An error occurred while searching for flights.',
        suggestion: 'Please try again.',
        tryAgainBtn: 'Try again',
      },
      notFound: {
        info: 'Oops! No flights found for this date',
        suggestion: 'Try selecting another date.',
      },
      pickFlightBtn: 'Select this flight',
      checkinFlightChanges: {
        header: {
          title: 'Your trip to {destination}',
          subtitle: 'Modify Itinerary',
        },
        title: 'Available flights',
        subtitle:
          'Select the day in which you want to see available flights for your change.',
        error: {
          200: {
            title: 'Oops! No flights were found on this date.',
            suggestion: 'Try again by selecting a new date',
          },
          201: {
            title: 'No flight availability for this date',
            suggestion: 'Please try again by selecting another date',
          },
          202: {
            title: 'Flights for this date are fully booked',
            suggestion: 'Please try again by selecting another date',
          },
          203: {
            title: 'Flights for this date are fully booked',
            suggestion: 'Please try again by selecting another date',
          },
          204: {
            title:
              'Sorry, something went wrong while trying to load the available flights',
            suggestion: 'Please try again later',
          },
          tryAgainBtn: 'Actualizar',
        },
        seatmapPreviewBtn: 'View seats',
        outboundFlight: 'Vuelo de ida',
        selectFlightBtn: 'Select this flight',
        odNonStop: 'Nonstop',
      },
    },
    confirmationModal: {
      title: 'Your new reservation has been confirmed.',
      description:
        'We have successfully modified your reservation. You can view the reservation details in the following page.',
      continueBtn: 'Continue to my reservation',
      segmentAlert:
        'We have successfully confirmed some flights in your reservation; however, other flights require special attention from one of our service agents. Please contact our call centers for assistance.',
    },
    // TODO: Remove confirmationPage translations on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
    acceptItineraryModal: {
      title: 'Do you want to confirm this itinerary?',
      subtitle:
        "When you press 'Confirm new itinerary', we will proceed to change your reservation.",
      descriptionBitModal:
        'Remember to reselect your seats and any other special services you have purchased.',
      continueBtnOld: 'Confirm new itinerary',
      descriptionOld:
        'By clicking on "Confirm itinerary" you accept the new flight itinerary that Copa Airlines has assigned for you.',
      continueBtn: 'Continue and select seats',
      description:
        "When you press 'Continue', we will proceed to change your reservation. After the change, you will be directed to the seat map to select your seats.",
      cancelBtn: 'Cancel',
      cancelBtnBitModal: 'Cancel',
      WCAG: {
        continueBtn: 'Press here to confirm your itinerary',
        cancelBtn: 'Press here to cancel and return to the previous screen.',
        title: 'Pending title',
      },
    },
    cancelBanner: {
      title:
        'None of the options suit your travel plans? Cancel now and travel later',
      description:
        "If the proposed itinerary or flight options do not fit well with your travel plans, you can cancel your itinerary and keep the value of the ticket to travel later. When you're ready, you just have to contact us to choose a new itinerary.",
      cta: {
        text: 'View other options',
        link: '{copa_url}/en-gs/customer-service/changes-and-refunds/',
      },
    },
    nonRevenue: {
      title: 'This is your current itinerary.',
      subtitle: `Here you'll be able to change your NR itinerary.`,
      pills: {
        standby: 'Stand-by',
        confirmed: 'Confirmed',
        inTransit: 'In-transit',
      },
    },
    ovs: {
      screenTitle:
        'Do you have a flexible schedule? Switch to a different flight at no additional cost.',
      pageDescription:
        "If interested, you'll be able to modify your itinerary through this site at no additional cost.",
      btnSearch: 'Find flights',
      disclaimerText: 'Things to consider before modifying your itinerary.',
      disclaimerItem1:
        'You will be able to change your flight date up to 14 days before or after your original flight schedule.',
      disclaimerItem2:
        "When switching flights, you'll have to select seats again. If you've already purchased seats, you will be able to choose similar ones for your new flights, if available.",
      disclaimerItem3: 'You can only change your itinerary once.',
      disclaimerItem4:
        'When proceeding with the changes, remember to adjust your travel plans to your new itinerary.',
    },
    minor02: {
      headerTitle: 'Itinerary change',
      screenTitle: 'Your itinerary has been adjusted',
      pageDescription:
        'It has been necessary to make minor changes to your itinerary with to {destination}. Please, check the detail of your new schedule below.',
    },
    srtRedemption: {
      screenTitle: 'This is your current itinerary.',
      pageDescription: "Here you'll be able to change your itinerary.",
      disclaimerText: 'Things to consider before modifying your itinerary.',
      disclaimerAllPassengers:
        'Modifications will apply to all passengers on this reservation.',
      disclaimerSelectedSeats:
        'If you already have selected seats, when you complete the change you will have to select your seats again. You will be able to select equivalent seats on your new flight.',
      disclaimerChanges:
        'You can make changes up to one year from the date of your purchase.',
      disclaimerBenefits:
        'The change will not modify the original benefits and attributes of your fare (ex. baggage allowance).',
      disclaimerDateRestrictions:
        'If you want to advance your trip, some dates may not be available.',
      disclaimerConfirmation:
        'Your current flights will not change until you confirm your new itinerary, later on this page.',
      newItineraryTitle: 'Review your selection',
      newItinerarySubTitle:
        'Please verify the information before you continue.',
      newItineraryHeader: 'Modify Itinerary',
      newItineraryCTAConfirm: 'Confirm new itinerary',
    },
    checkinFlightChanges: {
      screenTitle: 'This is your current itinerary',
      pageDescription:
        'Here you will be able to modify the upcoming flights that are open for Check- In.',
      disclaimerText:
        'Before modifying the itinerary, it is important to know:',
      disclaimerAllPassengers:
        'Only one change can be made and will apply to all passengers in the reservation.',
      disclaimerSelectedSeats:
        'When completing the change it will be necessary to select the seats again. If you have previously selected seats, those may not be available after completing the change.',
      disclaimerChanges:
        'Some special services previously selected may not be available in the new flight, as those require prior request.',
      disclaimerSSR:
        'If services have been purchased with third parties (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the providers the availability of the service on the new flight.',
      disclaimerAll: 'See all the Terms and Conditions.',
      disclaimerAllLink:
        '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in/',
      checkoutPage: {
        title: 'Review and confirm your new travel itinerary',
        description:
          'You have chosen a new itinerary for your next trip to {destination}. Please review your itinerary carefully before continuing.',
        errorToast:
          'Sorry, we were unable to update your itinerary. Please try again',
        pills: {
          newItinerary: 'New itinerary',
          noChanges: 'No Changes',
        },
        orginalItinerary: 'View original itinerary',
        modifyButton: 'Modify selection',
        confirmButton: 'Confirm new itinerary',
        modal: {
          title: 'Do you want to confirm this itinerary?',
          description1:
            'By clicking on "Confirm new itinerary", I agree that the new itinerary suits my travel plans.',
          description2:
            'Remember to select again your seats and any other special services that you have requested.',
          staroverButton: 'Return',
          confirmButton: 'Confirm new itinerary',
        },
      },
      // TODO: Remove confirmationPage translation on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
      confirmationPage: {
        title: 'Your new itinerary has been confirmed.',
        modalTitle: 'Your change has been confirmed!',
        description:
          'We have successfully modified your reservation. You can view the reservation details in the following page.',
        modalSubTitle:
          'You can review the details of your trip in the MyTrips section on Copa.com and on our mobile app.\n',
        modalDescriptionOld:
          'You can review the details of your trip in the MyTrips section on Copa.com and on our mobile app.',
        modalDescription:
          'After the change, you will be directed to the seat map to select your seats.',
        continueButton: 'Continue',
        modalContinueButtonOld: 'Continue with my reservation',
        modalContinueButton: 'Select seats',
      },
      wcag: {
        mainPageDescription:
          'This is your current itinerary. Through this page you will be able to modify the flights of your reservation that are available for Check-In.',
        searchPageMainDescription:
          'These are the available flights. You must select the day you would like to travel to obtain the available flights and make the change.',
        flightAppliesToChange: 'This flight applies to be modified.',
        flightDoesNotAppliesToChange:
          'This flight does not apply for modification.',
        termsAndConditionLink:
          'Redirection to see all the terms and conditions that apply in a new tab.',
        btnAvaliableFlights: 'Press to see available flights',
        searchPageDescription:
          'These are the available flights. You must select the day you would like to travel to obtain the available flights and make the change.',
        btnSelectDays: 'Press to select this day',
        btnViewSeats:
          'A new page will open to validate the availability of seats on this flight',
        btnSelectFlight: 'Press to select this flight',
        flightNewItineraryDescription: 'This is the new itinerary',
        flightNoChangesDescription: 'This is the Itinerary without changes',
        originalItineraryCollapsed:
          'Press to expand the section of your original itinerary.',
        originalItineraryExpanded:
          'Press to compress the section of your original itinerary.',
        btnModifySelection: 'Press if you wish to modify your selection',
        btnConfirmItinerary: 'Press to confirm your new itinerary',
        btnReturnToFlow:
          'Press to continue. You will be redirected to the landing page of {redirectionPage}',
        redirectionPage: 'My Trips',
        errorReservationCenterLink:
          'Redirection to go to the reservation center page. The page will open in a new tab',
      },
    },
    srtOVS: {
      disclaimers: {
        allPassengers:
          'The change will apply to all passengers on the reservation. ',
        selectedSeats:
          'When completing the change it will be necessary to select seats again. If you previously had selected seats,' +
          ' they may not be available to be selected after the change. ',
        SSR: 'Some previous selected special services may not be available on the new flight as they need to be requested in advance. ',
        thirdPartiesServices:
          'If other services from third parties have been purchased (i.e., travel insurance or car rental), it will be ' +
          'necessary for the passenger to coordinate directly with the third party the availability of the service on the new flight. ',
      },
      termsAndConditions: {
        text: 'See all the Terms and Conditions.',
        link: '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in-ovs/',
      },
    },
    odInfo: {
      flyover: `{stopsNumber} {stopsNumber, plural,
        one { layover}
        other { layovers}
      }`,
    },
  },
  rebook: {
    header: {
      infoText: 'Your trip to {destination}',
      title: 'Accept Itinerary or Modify Reservation',
      ovsAndNrTitle: 'Modify Itinerary',
      minor02Title: 'Itinerary change.',
      checkinFlightChangesTitle: 'Modify Itinerary',
    },
    subHeader: 'Important Trip Information',
    dateTrip: 'Travel dates',
    reservationCode: 'Reservation code',
    information:
      "Due to the ongoing flight restrictions and travel requirements in many of our destinations, we've had to make changes to several of our travel itineraries. As a result of this situation, the itinerary for your upcoming flight has changed.",
    itinerary: 'Updated trip itinerary',
    itinerarySubTitle:
      'This is your updated itinerary. If it suits your travel plans, please click on Accept this itinerary at the end of this page.',
    finalNote:
      'By clicking on accept this itinerary, I confirm that I agree with the itinerary change proposed by Copa Airlines. If the proposed itinerary does not suit your travel plans, please click on modify reservation to resultSearch for travel alternatives.',
    finalNoteNewItinerary:
      'By clicking on confirm new reservation, I agree that the new reservation suits my travel plans.',
    modifyItinerary: 'Modify Reservation',
    acceptItinerary: 'Accept this itinerary',
    errorMessage:
      'Sorry, we were unable to update your itinerary. Please try again.',
    titleNotAllowed:
      'Sorry, at least one of the conditions of your reservation does not allow you to self-serve your flight change through this application.',
    messageNotAllowed: {
      start:
        'Please visit My trips to check for changes options or contact our ',
      linkText: 'Reservation Center',
      link: '{copa_url}/en-gs/customer-service/call-center/',
      end: ' to process your change.',
    },
    options: 'See other options',
    optionsLink: '{copa_url}/en/web/gs/options',
    newItineraryHeader: 'Modify Reservation',
    newIntineraryTitle: 'Review your selection',
    newIntinerarySubTitle: 'Please verify the information before you continue.',
    oldIntinerary: 'See previous itinerary',
    startOver: 'Start over',
    confirmItinerary: 'Confirm new reservation',
    messageConfirmItinerary:
      'We have successfully modified your travel itinerary.',
    messageErrorItinerary:
      'Sorry, we couldnt update your itinerary. Try again.',
    messageItineraryNoModify:
      'You didnt make any changes to your itinerary. Select “Start over” to make changes or select “Confirm new itinerary” to continue with this itinerary.',
    redemption: {
      notElegibleTitle: 'Sorry, we are unable to process your change.',
      notElegibleMessage:
        'This is a limited version that does not process changes less than 7 days before the flight. To modify your reservation, please contact our',
      notElegibleLinkDesc: 'ConnectMiles Service Center.',
      notElegibleLink: '{copa_url}/en-gs/connectmiles/',
      notElegibleButton: 'Go back to My Trips',
    },
  },
  confirmContactInfo: {
    title: 'Let us know where to reach you.',
    description:
      "By providing us your contact information, we'll keep you informed of changes or other important updates that may occur to your itinerary. You can change this information later at any time.",
    privacyDisclaimer:
      'We will use this information only to contact you with regards to this trip.',
    modal: {
      confirmBtn: 'Confirm contact information',
    },
    form: {
      currentEmail: 'Email address',
      email: {
        label: 'Email address (mandatory)',
        placeholder: 'Enter email address',
        recommendation: 'We recommend using a personal email address.',
      },
      phone: {
        addLabel: 'Add Phone',
        label: 'Phone Number',
        placeholder: 'Enter number',
      },
      countryCode: {
        placeholder: 'Country Code',
      },
      cancelBtn: 'Cancel',
      editEmailBtn: 'Edit Email',
    },
  },
  GDPRBanner: {
    text: 'On our website, copa.com, we use cookies to ensure the correct functioning of this page and to improve your experience. If you decide to continue browsing, we understand that you accept the cookies policy that is included in our ',
    link: '{copa_url}/en-gs/legal/terms-and-conditions/privacy-policy/',
    linkText: 'Privacy Policy.',
    ctaButton: 'Accept',
    ctaWCAGButton:
      'Important message. On our website, copa.com, we use cookies to ensure the correct functioning of this page and to improve your experience. If you decide to continue browsing, we understand that you accept the cookie policy that is part of our Privacy Policy. Press Enter to accept.',
  },
  directChannelAlert: {
    directDescription:
      'Thank you for buying directly with Copa Airlines, you can now enjoy preferential prices on both seats and baggage.',
    indirectDescription:
      'This reservation was made through a third-party booking system. For better pricing on total fares, seats, and/or bags, next time we recommend you purchase directly with Copa Airlines or a travel agency with direct access.',
  },
  directChannelPill: {
    directDescription: 'Direct Booking',
    indirectDescription: 'Third-Party Booking',
  },
  CiamLoginBox: {
    title: 'Sign in to continue.',
    description:
      'You must log in with the account you originally used to create this reservation.',
    loginBtn: 'Log In to continue',
    wcagBtn: 'Press to login',
  },
  srt: {
    flight: {
      layover: 'Layover:',
      freeChargeLabel: 'Free changes',
    },
    notEligibleError: {
      title: 'At this moment we are unable to process your change',
      subtitle:
        'Please try again later or contact our Reservations Center to process the change.',
      link: '{copa_url}/en-gs/customer-service/call-center/',
      cta: 'Go back',
    },
    calendarServiceError: {
      title:
        'Sorry, something went wrong while trying to modify your itinerary.',
      subtitle:
        'Please try again later or contact our Call Center to process the change.',
      cta: 'Go to Call Center',
      link: '{copa_url}/en-gs/customer-service/call-center/',
    },
    navBackBtnWCAG: 'Press to return to My Trips',
    wrongAccountModal: {
      title: 'To continue, sign in with the correct account',
      subtitle:
        'This account is different from the one you used to make this reservation with miles. Please log in with the correct account ',
      cta: 'Sign in',
    },
    availableFlightsErrors: {
      201: {
        title: 'No flight availability for this date',
        suggestion: 'Please try again by selecting another date',
      },
      202: {
        title: 'Flights for this date are fully booked',
        suggestion: 'Please try again by selecting another date',
      },
      203: {
        title: 'Flights for this date are fully booked',
        suggestion: 'Please try again by selecting another date',
      },
      204: {
        title:
          'Sorry, something went wrong while trying to load the available flights',
        suggestion: 'Please try again later',
      },
    },
    sc: {
      default: {
        onboardingPage: {
          navTitle: 'Modify itinerary',
          acceptItinerary: {
            title: 'This is your current itinerary',
            subtitle: `Here you'll be able to modify the flights in your reservation.`,
            modifyBtn: 'Modify itinerary',
            acceptBtn: 'This itinerary works for me',
          },
          confirmDefaultItineraryModal: {
            title: 'Do you want to confirm this itinerary?',
            subtitle:
              "When you press 'Continue', we will proceed to change your reservation.",
            seatmapDescription:
              'After the change, you will be directed to the seat map to select your seats.',
            oldDescription:
              'Remember to reselect your seats and any other special services you have purchased.',
            oldContinueBtn: 'Confirm new itinerary',
            seatMapContinueBtn: 'Continue and select seats',
            cancelBtn: 'Cancel',
            WCAG: {
              continueBtn: 'Press here to confirm your itinerary',
              cancelBtn:
                'Press here to cancel and return to the previous screen.',
              title: 'Pending title',
            },
          },
        },
        resultSearchPage: {
          availableFlightSection: {
            nextCTA: 'Next day',
            previousCTA: 'Previous day',
            subtitle: 'Select the flight of your preference:',
            title: 'Available flights',
          },
          dateSection: {
            ctaText: 'See flight options',
            title: 'Choose a new date',
          },
          flightSection: {
            buttonText: "Don't modify this flight",
            subtitle: '{date} · To {destination}',
            title: 'Find alternatives for this trip',
          },
          header: {
            title: 'Modify itinerary',
          },
        },
        checkout: {
          confirmationModal: {
            title: 'Your change has been confirmed!',
            subtitle:
              'You can review the details of your trip in the MyTrips section on Copa.com and on our mobile app.\n',
            oldDescription:
              'You can review the details of your trip in the MyTrips section on Copa.com and on our mobile app.',
            seatmapDescription:
              'Next, you will be directed to the seat map to select your new seats.',
            oldContinueButton: 'Continue with my reservation',
            seatContinueButton: 'Select seats',
            redirectToMyTrips: 'Go to My Trips',
          },
        },
      },
      maj01: {
        onboardingPage: {
          navTitle: 'Accept or modify itinerary',
          acceptItinerary: {
            title: 'Action required: accept or modify the new travel itinerary',
            subtitle: `We have accommodated you in the best available travel alternative.`,
            modifyBtn: 'Modify itinerary',
            acceptBtn: 'This itinerary works for me',
          },
          collapsable: {
            title: 'Original itinerary',
            subtitle:
              'Once the new itinerary is confirmed, this one will no longer be valid.',
            btnOpen: 'See details',
            btnClose: 'Hide details',
          },
        },
      },
      rdp: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### You can change your travel dates up to 24 hours before or after your original travel time.\n\n- ###### When changing flights, you will need to select seats again. You can choose the same or similar seats on your new flights, subject to availability and at no cost.\n\n- ###### Through Web Check-In (24 hours before your flight departure), you can make changes to your itinerary once on the outbound and once on the return, if needed.\n\n- ###### If you purchased any additional services (i.e., travel insurance or car rental), please contact the respective provider to make modifications.\n\n- ###### If you acquired any special services (e.g., wheelchair assistance or vegetarian meals), you will need to request them again through our channels.`,
          },
          notEligibleError: {
            title: 'At this moment we are unable to process your change',
            subtitle:
              'Our application currently supports changes for trips with a departure date at least 7 days in advance. To modify your itinerary, kindly reach out to our ConnectMiles Service Center.',
            link: '{copa_url}/en-gs/connectmiles/',
            cta: 'Go back to My Trips',
          },
        },
      },
      adhoc: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### Only one change can be made and it will apply to all passengers in the reservation.\n\n- ###### When completing the change it will be necessary to select seats again. If you previously had selected seats, they may not be available to be selected after the change.\n\n- ###### Some previously selected special services may not be available on the new flight as they need to be requested in advance.\n\n- ###### If other services from third parties have been purchased (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the third party the availability of the service on the new flight.`,
          },
          notEligibleError: {
            title: 'At this moment we are unable to process your change',
            subtitle:
              'Due to the conditions of your reservation we cannot process the change through our application. To modify your itinerary, please contact our Reservations Center',
            link: '{copa_url}/en-gs/customer-service/call-center/',
            cta: 'Go back to My Trips',
          },
        },
      },
      nr: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### You will be able to change your flight up to 45 minutes before the original departure time.\n\n- ###### You can switch to flights with a departure time that is at least 45 minutes from the time of the change.\n\n- ###### If you have already checked baggage, it may be delayed when making the change. In these cases, we recommend contacting an agent at the airport for assistance.\n\n- ###### It is the responsibility of each employee to ensure that the change to be made is correct and to be present at the boarding gate with enough time.`,
          },
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### Only one change can be made and it will apply to all passengers in the reservation.\n\n- ###### You will be able to change your flight up to 14 days before or after your original travel date.\n\n- ###### When changing flights, you will need to select seats again. You can choose the same or similar seats on your new flights, subject to availability.\n\n- ###### Some previous selected special services may not be available on the new flight as they need to be requested in advance.\n\n- ###### If other services from third parties have been purchased (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the third party the availability of the service on the new flight.`,
          },
        },
      },
    },
    wci: {
      default: {
        onboardingPage: {
          navTitle: 'Modify itinerary',
          acceptItinerary: {
            title: 'This is your current itinerary',
            subtitle: `Here you will be able to modify the upcoming flights that are open for Check-In.`,
            ctaBtn: 'See available flights',
          },
          itineraryCard: {
            availablePill: 'Available',
            notAvailablePill: 'Not Available',
          },
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### Only one change can be made and it will apply to all passengers in the reservation.\n\n- ###### When completing the change it will be necessary to select seats again. If you previously had selected seats, they may not be available to be selected after the change.\n\n- ###### Some previous selected special services may not be available on the new flight as they need to be requested in advance.\n\n- ###### If other services from third parties have been purchased (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the third party the availability of the service on the new flight.`,
          },
          termsAndConditions: {
            text: 'See all Terms and Conditions.',
            ovsLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in-ovs/',
            cfcLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in/',
          },
          notEligibleError: {
            title: 'At this moment we are unable to process your change',
            subtitle:
              'Due to the conditions of your reservation we cannot process the change through our application. To modify your itinerary, please contact our Reservations Center',
            link: '{copa_url}/en-gs/customer-service/call-center/',
            cta: 'Go to Reservations Center',
          },
        },
        resultPage: {
          title: 'Select outbound flight',
          description: '{date} · To {destination}',
          searchResult: {
            title: 'Available flights',
            description: 'Select the flight of your preference:',
          },
          selectFlightBtn: 'Select flight',
          viewSeatsBtn: 'View seats',
        },
        checkoutPage: {
          headerTitle: 'Review itinerary',
          title: 'Review your selection',
          subtitle: 'Double-check your selection before continuing',
          modifyCTA: 'Modify selection',
          confirmCTA: 'Confirm new itinerary',
        },
        confirmationPage: {
          headerTitle: 'Confirmation page',
          title: 'This is your new itinerary',
          subtitle: 'Summary of your new travel itinerary',
          confirmCTA: 'View my reservation',
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### Only one change can be made and it will apply to all passengers in the reservation.\n\n- ###### When completing the change it will be necessary to select seats again. If you previously had selected seats, they may not be available to be selected after the change.\n\n- ###### Some previous selected special services may not be available on the new flight as they need to be requested in advance.\n\n- ###### If other services from third parties have been purchased (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the third party the availability of the service on the new flight.`,
          },
          termsAndConditions: {
            text: 'See all Terms and Conditions.',
            ovsLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in-ovs/',
            cfcLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in/',
          },
        },
      },
      cfc: {
        onboardingPage: {
          disclaimers: {
            title: 'Some considerations to keep in mind:',
            messages: `- ###### Only one change can be made and it will apply to all passengers in the reservation.\n\n- ###### When completing the change it will be necessary to select seats again. If you have previously selected seats, those may not be available after completing the change.\n\n- ###### Some special services previously selected may not be available in the new flight, as those require prior request.\n\n- ###### If services have been purchased with third parties (i.e., travel insurance or car rental), it will be necessary for the passenger to coordinate directly with the providers the availability of the service on the new flight.`,
          },
          termsAndConditions: {
            text: 'See all Terms and Conditions.',
            ovsLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in-ovs/',
            cfcLink:
              '{copa_url}/en-gs/legal/terms-and-conditions/exchanges-check-in/',
          },
        },
      },
    },
  },
  upgrade: {
    errorPage: {
      wcag: 'You are on an error page. This may be due to some failure in our services, we recommend you try again later.',
    },
    saua: {
      errorPage: {
        title: 'Your itinerary has flights operated by other airlines',
        subtitle:
          'Upgrade requests for flights operated by other airlines is not available at the moment. If you have flights operated by Copa and other airlines, you can request an upgrade only for the Copa Airlines segments through our [Reservations Center]({copa_url}/en-gs/customer-service/call-center/)',
        cta: 'Go back',
        ctaWcag: 'Press to go back. You will be redirected to another page',
      },
      passengerForm: {
        title: 'Passengers',
        paxTitle: 'Passengers',
        nameField: 'Name',
        namePlaceholder: 'Enter name',
        lastNameField: 'Last name',
        lastNamePlaceholder: 'Enter last name',
        eTicketField: 'E-ticket number',
        eTicketPlaceholder: 'Enter e-ticket number',
        connectMilesField: 'ConnectMiles Number',
        connectMilesPlaceholder: 'Enter ConnectMiles number',
        addPaxBtn: 'Add traveler',
        addPaxBtnWCAG:
          'Press to add an additional passenger. You will have to complete the travel information for each passenger you add',
        removePaxBtn: 'Remove',
        removePaxBtnWCAG: 'Press to remove this passenger',
        invalidFormatMessage: 'Invalid format',
        requireMessage: 'This field is required',
        minimumCharsAllowedMessage: `The minimum number of characters allowed is {number}`,
      },
      onboarding: {
        pnrLabel: 'Reservation Code',
        title: 'Upgrades for Star Alliance airlines',
        subtiTitle:
          'Copa Airlines allows you to redeem your available miles and vouchers for an upgrade on the airline of your choice within the Star Alliance network. To request this upgrade, you must fill in the following details:',
        incontentAlertText:
          '###### The upgrade request applies to all passengers in the reservation and for a maximum of two flights.',
      },
      flightsForm: {
        title: 'Flight information',
        flightTitle: 'Flight {number}',
        originToDestination: '{origin} to {destination}',
        airlineLabel: 'Airline',
        airlinePlaceholder: 'Select the operating airline of your flight',
        flightNumber: 'Flight number',
        flightNumberPlaceholder: 'Enter flight number',
        pnr: 'Reservation number {airline}',
        pnrPlaceholder: 'Enter reservation number',
        pnrHelperText:
          'If you do not have the reservation number, contact our [Call Center]({copa_url}/en-gs/customer-service/call-center/)',
        originLabel: 'Origin',
        originPlaceholder: 'Select your city of origin',
        destinationLabel: 'Destination',
        destinationPlaceholder: 'Select your destination city',
        flightDate: 'Flight Date',
        flightDatePlaceholder: { day: 'DD', month: 'MM', year: 'YYYY' },
        cabin: 'Cabin',
        cabinPlaceholder: 'Select the cabin of your upgrade',
        clearForm: 'Clean form',
        addFlight: 'Add another flight',
        removeFlight: 'Remove',
        airportsInlineError: 'The fields cannot be the same',
        dateInlineError: 'You can only select future dates',
        serviceError: 'Something failed. Try again later',
        notFoundError: 'No results found',
        wcag: {
          clearForm:
            'Press to clear the form. This will remove the entered flight information',
          addFlight:
            'Press to add an additional flight for which you wish to request an upgrade. When adding an additional flight you will have to fill out required information for this flight',
          removeFlight: 'Press to remove this flight from the upgrade request',
          autocomplete:
            'You must enter at least 3 letters to search for an airport or city.',
        },
      },
      generalErrorPage: {
        title: 'We cannot process your request',
        subtitle:
          'We are currently experiencing service issues that prevent us from processing your upgrade request. Please try again later or contact our [Call Center]({copa_url}/en-gs/customer-service/call-center/) to request your upgrade using your miles or certificates.',
        cta: 'Go back',
        ctaWcag: 'Press to go back. You will be redirected to another page',
      },
      noMilesErrorPage: {
        title: 'We are unable to process your request',
        subtitle:
          'To request an upgrade, you need to have miles or certificates available.',
        cta: 'Start over',
        ctaWcag: 'Press to go back. You will be redirected to another page',
        pageText:
          'You are on an error page. In order to request an upgrade, the account must have miles or certificates available.',
      },
    },
  },
  upgradeHub: {
    header: {
      title: 'Request an upgrade',
      wcagText: 'Press to go back. You will be redirected to another page',
    },
    onboarding: {
      destinationCard: {
        flightTo: 'To {destination}',
      },
      loginBanner: {
        description:
          'Access your ConnectMiles account if you want to request an upgrade using miles or certificates.',
        ctaText: 'Log In',
        wcagText: 'Press to log in. You will be redirected to another page',
      },
      title: 'How do you want to request an upgrade?',
      subtitle:
        'Copa Airlines gives you the opportunity to request an upgrade to the Business Class and enjoy its benefits. You can achieve this by purchasing your seat with money or using your available miles or certificates.',
      plusUpgradeCard: {
        title: 'Pay with money',
        subtitle:
          'Make an offer with the amount you are willing to pay for an upgrade to Business Class.*',
        cta: 'Select',
        wcagText:
          'Press to request the promotion by paying with money. You will be redirected to another page',
        logoTinyAlt:
          "FlyUp is a program where you can choose to be promoted to business class and enjoy it's benefits",
        wcagTextDisableCard:
          'The option to request an upgrade to business class by paying with money is not available.',
        ariaIcon: 'Fly Up Icon',
      },
      upgradeHub: {
        title: 'Use miles or certificates from ConnectMiles',
        subtitle:
          'Purchase your seat using your available miles or certificates.**',
        cta: 'Select',
        wcagText: 'Press to request the upgrade using miles or certificates.',
        logoTinyAlt:
          'You can request an upgrade to business class using your available miles or certificates',
        wcagTextDisableCard:
          'The option to upgrade to business class using your miles or certificates is not available.',
      },
      disclaimer:
        '*This program does not affect complimentary upgrades granted to ConnectMiles PreferMembers. Remaining seats will be sold after granting complimentary upgrades in accordance with program rules.',
      disclaimer2:
        '**Certificates are exclusive for Platinum and Presidential members.',
    },
    generalError: {
      plusUpgrade:
        'We are sorry, your reservation is not eligible to participate of this program.',
      title: "We're unable to process your request",
      subTitle:
        'Due to issues with our service, we suggest you try again later',
      button: 'Go back',
      buttonWcag: 'Press to go back. You will be redirected to another page',
    },
    selectFlight: {
      title: 'Select the flights for which you wish to request an upgrade',
      destination: {
        subtitle: 'These are your flight options.',
      },
      memberCard: {
        miles: '{miles} Miles',
        expirationDate: 'Expiration date: {date}',
        certificates: '{certificates} Certificates',
        noCertificates: 'No certificates availables',
        globlalPlusRegional: '({global} Global + {regional} Regional)',
      },
      alert: {
        text:
          '- ###### The upgrade request applies to all passengers in the reservation.\n' +
          '- ###### Seats, bags and other additional services previously purchased will not be refundable',
        link: '{copa_url}/en-gs/customer-service/call-center/',
      },
      itineraryCard: {
        available: 'Select flight',
        notAvailable: 'No availability',
        ariaLabelCheckboxActivated: 'Press to select this flight',
        ariaLabelCheckboxDisabled:
          'This flight no longer has availability for upgrade',
      },
      pricesSection: {
        initialTitle:
          'Select and apply your payment method to see the cost in this section',
        initialSubtitle:
          'You will be able to see the estimated cost of the upgrade with the payment method applied',
        title: 'Purchase summary',
        subtitle: 'Travel extras',
        departureCodeAndArrivalCode: 'Upgrade {departureCode} - {arrivalCode} ',
        adultPassengerCount: '{count, plural, one {# adult} other {# adults}}',
        childPassengerCount:
          '{count, plural, one {# child} other {# children}}',
        certificatesCount:
          '{count, plural, one {# certificate} other {# certificates}}',
        milesCount: '{count} award miles',
        regionalCertificatesCount:
          '{count, plural, one {# certificate regional} other {# certificates regional}}',
        globalCertificatesCount: '{count} global',
        subtotal: 'Subtotal',
        taxes: 'Taxes and fees',
        total: 'Total',
        ctaText: 'Request Upgrade',
        ctaWcag:
          'Press to request the promotion. You will be redirected to another page',
      },
      paxCardText: {
        memberText: 'ConnectMiles Member',
        connectMiles: 'PreferMember {CM}',
        noFFN: 'No frequent flyer number',
      },
      passengerText: {
        title: 'Passengers on the reservation',
        subtitle: 'Passengers who will be upgraded to Business Class.',
      },
      errorToast:
        'Something went wrong while modifying your selection. Please try again',
    },
    confirmation: {
      header: 'Confirmation',
      confirmationText: {
        title: {
          successfulUpgrade:
            "Congratulations, you've been upgraded to Business Class!",
          partialUpgrade:
            'Confirmation of upgrade to Business Class for the following flights:',
          failedUpgrade:
            'We were unable to process your upgrade for the selected flights',
        },
        subtitle: {
          successfulUpgrade:
            'Your upgrade to Business Class may take a few minutes to reflect.',
          partialUpgrade:
            'Your upgrade to Business Class may take a few minutes to reflect.',
          failedUpgrade:
            'We recommend you try again later or call our [Reservations Center.]({copa_url}/en-gs/customer-service/call-center/)',
        },
        successfulUpgradeAlert:
          '###### Remember to select your seats when completing the upgrade request.',
        successfulUpgradeAlertWCAG: 'Press to delete the alert',
      },
      pillStatus: {
        successful: 'Upgrade received',
        failed: 'Not upgraded',
      },
      partialUpgrade: {
        title: 'Some flights were not upgraded',
        subtitle:
          'Your request for an upgrade to business class could not be processed for the following flights. Please try again later or contact our ',
        linkText: 'Reservations Center.',
        link: '{copa_url}/en-gs/customer-service/call-center/',
      },
      passengerText: {
        title: 'Passengers on the reservation',
        subtitle: 'Passengers who will be upgraded to Business Class.',
      },
      paxCardText: {
        connectMiles: 'PreferMember {CM}',
        noFFN: 'No frequent flyer number',
      },
      ctaText: {
        successfulUpgrade: 'Go to My Trips',
        partialUpgrade: 'Go to My Trips',
        failedUpgrade: 'Start over',
      },
      wcag: {
        pageText:
          'You are on a partial confirmation page. This means that some of your travel segments were successfully upgraded, while others, unfortunately, were not. If you would like to make a request for the segments that could not be upgraded, we recommend you trying again later.',
        goBackText: 'Press to go back. You will be redirected to another page',
        errorPageDescription:
          'You are on an error page. This may be due to some failure in our services, we recommend you try again later.',
        ctaText: {
          successfulUpgrade:
            'Press to go to My Trips and manage your reservation. You will be redirected to another page',
          partialUpgrade:
            'Press to go to My Trips and manage your reservation. You will be redirected to another page',
          failedUpgrade:
            'Press to return to the beginning. You will be redirected to another page',
        },
      },
    },
    upgradeBox: {
      title: 'Calculate the value of your upgrade',
      subTitle:
        'With your chosen itineraries, select the payment method you want to use to request your upgrade:',
      fopTitle: 'Select payment method:',
      fopPlaceHolder: 'Payment method',
      totalCostTitle: 'Total cost:',
      totalCostPlaceHolder: 'Value in miles or certificates',
      totalCostInlineHelper: 'Calculation based on your flight selection',
      regional: 'regional,',
      global: 'global',
      miles: 'award miles',
      errors: {
        serviceError: "We couldn't calculate the total cost. Please try again.",
        voucherRequired: "You haven't selected upgrade certificates",
        milesRequired: "You don't have enough miles.",
        notEnoughVouchers: "You don't have enough certificates.",
      },
      fopDropDown: {
        regionalVoucher: 'Regional Certificate',
        available: 'Available',
        globalVoucher: 'Global Certificate',
        save: 'Save',
        error: 'Select an upgrade certificate to continue',
        miles: 'Award miles',
        certificates: 'Certificates',
        helperText:
          'The selection shown is suggested, but you can customize it as desired',
      },
      cta: {
        apply: 'Apply',
        reset: 'Clean',
        vouchersToRedim: 'Certificates to redeem',
      },
      banner: {
        milesSuccess:
          'You have selected miles as the payment method for your upgrade to Business Class',
        voucherSuccess:
          'You have selected certificates as the payment method for your upgrade to Business Class',
        serviceError:
          "Your payment method couldn't be applied. Please verify the information and try again.",
      },
      upgradeBoxContainerWCAG:
        'In this section, you will be able to choose your payment method, calculate the cost of your upgrade using this method, and apply it. When you apply your payment method, the deduction of miles or the use of the certificate will not be processed yet. You will need to continue in the flow to obtain the upgrade confirmation.',
      certificatesNotAvailable: 'not available',
    },
    saua: {
      priceSummary: {
        ascendToArrival: 'Upgrade to {arrivalCode}',
      },
      confirmationPage: {
        header: 'Confirmation',
        pageDescription:
          'You are on a confirmation page. To know the result of your request, please check the confirmation details. Depending on availability, your request may have been approved, partially approved, or failed. If it has been approved, the upgrade may take a few minutes to reflect.',
        title:
          'We have received your upgrade request for the following flights',
        cases: {
          success: {
            title:
              'We have received your upgrade request for the following flights',
            alertText:
              'Your request was successfully received. You will soon receive an upgrade confirmation in your email.',
            pillText: 'Upgrade received',
          },
          partial: {
            title:
              'We have received the upgrade request for one of your flights.',
            alertText:
              'We were unable to process your request for an upgrade to Business Class for one of your flights. Please try again later.',
          },
          error: {
            title:
              'We were unable to process your upgrade request for the following flights:',
            alertText:
              'The upgrade request for your flights could not be processed. Please try again later.',
            pillText: 'No upgrade',
          },
        },
        errorTitle: 'Reasons why your upgrade request failed:',
        errorReasons: {
          error1:
            'The reservation number does not correspond to the airline operating the flight. If you do not have the reservation number, please contact the Call Center.',
          error2:
            'The flight information is incorrect (e.g., date, flight number, reservation number, or cabin).',
          error3:
            'The requested class is not eligible for an upgrade or there is no availability.',
          error4:
            'An upgrade request has already been made for this reservation.',
          error5: 'There are not enough miles to complete the upgrade request.',
          error6:
            'The reservation includes special services that cannot be honored with a class upgrade (e.g., pet in cabin).',
          error7:
            'The provided flight departure date is outside the available dates for an upgrade.',
        },
        flight: {
          title: 'Flight {number}',
          origin: 'Origin airport',
          destination: 'Destination airport',
          airline: 'Airline',
          number: 'Flight number',
          date: 'Flight Date',
          cabin: 'Cabin',
        },
        passenger: {
          title: 'Passengers for whom you requested an upgrade',
          subtitle:
            'These are the passengers that will be able to enjoy a seat in business cabin.',
        },
        paxCard: {
          title: 'E-ticket: {eticket}',
        },
        cta: 'Go back',
        failed:
          'We were unable to process your upgrade request for the following flights:',
      },
    },
  },
}
