import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { maskConnectMilesNumber } from '../../../../../../utils/utils'
import NewAccountIcon from '../../../../../../assets/images/connect-miles-new-account.svg'
import { styles } from './styles'
import { spacing } from '../../../../../../utils/spacing'
// @ts-ignore
export function WrongAccountContent({ ownerFFN }) {
  const { formatMessage } = useIntl()
  const classes = styles()

  return (
    <Box sx={classes.container}>
      <Box sx={classes.icon}>
        {/* @ts-ignore */}
        <img src={NewAccountIcon} alt="" />
      </Box>

      <Typography
        sx={{ marginBottom: spacing.minimal }}
        color="primary.main"
        variant="h3"
      >
        {formatMessage({ id: 'srt.wrongAccountModal.title' })}
      </Typography>

      <Typography color="grey.600" variant="body1">
        {`${formatMessage({
          id: 'srt.wrongAccountModal.subtitle',
        })} (${maskConnectMilesNumber(ownerFFN)})`}
      </Typography>
    </Box>
  )
}
