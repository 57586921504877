import { SeatTypes } from '../../utils/seatSelection/seatSelection'

export default {
  loading: 'La page est en train de se charger',
  findReservation: {
    title: 'Mes Voyages',
    description:
      'Dans Mes Voyages, vous pouvez consulter, gérer et enregistrer vos réservations auprès de Copa Airlines.',
    welcome: 'Bonjour ! Trouvons votre réservation.',
    innerDescription:
      'Dans Mes Voyages, vous pouvez consulter, gérer et enregistrer vos réservations auprès de Copa Airlines.',
    informationAlert:
      'Vous utilisez Mes Voyages Beta. Avez-vous des commentaires ou des suggestions ?',
    linkInformationAlert: 'Laissez un commentaire.',
    errorInvalidToken:
      'Votre session ConnectMiles a expiré. Veuillez vous connecter à nouveau.',
  },
  connectMiles: {
    description:
      'Vous pouvez également vous connecter à votre compte ConnectMiles pour gérer vos réservations enregistrées.',
    loginButton: 'Se Connecter',
  },

  findTrip: {
    title: 'Rechercher une réservation',
    buttonFind: 'Rechercher votre réservation',
    checkLabel: 'Enregistrer dans Mes Voyages',
    alreadyAdded: 'Votre réservation a déjà été ajoutée à Mes Voyages.',
    successfully: 'Voyage enregistré avec succès',
    notFound:
      "Désolé ! Nous n'avons pas pu trouver votre réservation avec les informations fournies. Veuillez vérifier vos données et réessayer.",
  },

  emptyState: {
    title: "Vous n'avez pas encore de voyages enregistrés.",
    description:
      "Les voyages associés à votre numéro ConnectMiles s'afficheront automatiquement ici. Vous pouvez également rechercher une réservation pour l'ajouter ici.",
    buttonAdd: 'Ajouter un Voyage',
  },

  holderCard: {
    title: 'Ajouter un autre voyage',
  },
  holderCardWCAG: {
    description: 'Ajouter un autre voyage.',
  },
  pastTrips: {
    title: 'Voyages Passés',
    destination: 'Destination',
    flightDates: 'Dates de vol',
    reservation: 'Réservation',
  },

  pastTripsWCAG: {
    wcagTitle: 'Tableau des Voyages Passés',
    wcagDescription:
      'Voyages passés : Cette section affiche les voyages déjà effectués. Vos voyages passés disparaissent automatiquement après 2 mois.',
    wcagAvatarImage:
      "Avatar de la Destination : Il s'agit de l'Avatar de {destinationCity}",
    wcagDestinationColumn:
      'Colonne de Destination : Cette colonne affiche les destinations de vos voyages passés.',
    wcagFlightColumn:
      'Colonne des Dates de Vol : Cette colonne affiche les dates de vol de vos voyages passés.',
    wcagReservationColumn:
      'Colonne de Réservation : Cette colonne affiche les codes de réservation de vos voyages passés.',
    wcagPastTripsDetail:
      'Les détails de votre voyage sont les suivants : {pasengerInformation} + {aditionalPassengers} {od} - {destinationCity} Dates de vol {startDate} - {endDate} Code de réservation : {reservationCode}',
    wcagTripSelection:
      'Vous êtes dans le tableau de vos voyages passés. Vous pouvez naviguer entre les voyages et écouter les détails de votre voyage.',
    od: 'vers',
  },

  removeModal: {
    title: 'Souhaitez-vous supprimer ce voyage ?',
    description:
      'Ne vous inquiétez pas, votre réservation restera active - elle disparaîtra simplement de votre page Mes Voyages.',
    pastTripDescription:
      'Si vous supprimez votre voyage maintenant, vous ne pourrez plus voir les détails qui y sont associés. Êtes-vous sûr de vouloir continuer ?',
    cancelBtn: 'Annuler',
    deleteBtn: 'Supprimer',
    errorToastMessage:
      "Désolé ! Nous n'avons pas pu supprimer votre voyage. Veuillez réessayer ultérieurement.",
    successToastMessage: 'Votre voyage a été supprimé avec succès.',
  },

  editModal: {
    renameTrip: 'Renommer ce voyage',
    tripName: 'Nom du voyage',
    renameButton: 'Renommer',
    editModalError: 'Les caractères spéciaux ne sont pas autorisés.',
  },

  fareRulesModal: {
    title: 'Règles tarifaires',
    description:
      'Certains aspects de votre réservation, tels que les bagages, les sièges, les modifications et les remboursements, peuvent être soumis à des restrictions en fonction du type de tarif que vous avez acheté. Ici, vous pouvez consulter les règles tarifaires applicables à chacun des vols de cette réservation.',
    link: 'En savoir plus sur nos familles tarifaires.',
    href: '{copa_url}/fr-gs/information-au-voyage/familles-de-tarifs/',
    infoNonCopa:
      "Nous n'avons pas d'informations sur les règles tarifaires pour ce vol, car il n'est pas exploité par Copa Airlines.",
  },

  fareRulesModalWCAG: {
    description:
      'Modal des règles tarifaires. Vous pouvez consulter ici les règles applicables à la tarification que vous avez achetée.',
    previousButton:
      'Utilisez ce bouton pour consulter les règles tarifaires applicables au vol précédent.',
    nextButton:
      'Utilisez ce bouton pour consulter les règles tarifaires applicables au vol suivant.',
  },
  specialServicesModal: {
    title: 'Services spéciaux',
    subtitle:
      'Copa propose des services pour les passagers ayant des besoins spéciaux. Vous pouvez demander des repas spéciaux, assistance en vol, entre autres.',
    introTitle: 'Ajouter des services spéciaux',
    passengerLabel: 'Passager',
    specialServiceLabel: 'Service',
    appliesToLabel: "S'applique à",
    serviceLabel: 'Type de service',
    passengerSelectPlaceholder: 'Sélectionnez le passager',
    serviceSelectPlaceholder: 'Sélectionnez le service',
    mealLabel: 'Type de repas',
    chairLabel: 'Type de siège',
    mealPlaceholder: 'Choisissez le type de repas',
    wheelChairPlaceholder: 'Motif',
    addButton: '+ Ajouter',
    removeButton: 'Supprimer',
    disclaimer: {
      title: 'Demande de services spéciaux',
      meal: {
        title: 'Repas spéciaux',
        description:
          "Les demandes de repas spéciaux peuvent être effectuées jusqu'à 24 heures avant le départ du vol. Types de repas spéciaux disponibles :",
        errorAlreadyAdded: 'Ce service a déjà été ajouté pour ce passager.',
        item1: {
          title: 'Classe Affaires :',
          description:
            'végétarien, casher et sans gluten sur certains vols. {linkDesc}',
          linkDesc: 'Découvrez notre service de repas et de boissons à bord.',
          link: '{copa_url}/fr-gs/decouvre-copa-airlines/experience-a-bord/repas-a-bord/',
        },
        item2: {
          title: 'Cabine Principale :',
          description:
            "sans gluten uniquement sur les vols à destination et en provenance d'Argentine et du Paraguay.",
        },
      },
      assistance: {
        title: 'Assistance spéciale',
        item1: {
          title:
            'Assistance pour les personnes malentendantes ou malvoyantes :',
          description: 'Disponible sur tous nos vols.',
        },
        item2: {
          title: "Transport d'animaux d'assistance et de soutien émotionnel :",
          description:
            "Vous pouvez en faire la demande auprès de notre Centre de Réservations, de nos bureaux de vente ou à l'aéroport. {linkDesc}",
          linkDesc:
            "Découvrez les conditions de transport des animaux d'assistance et de soutien émotionnel.",
          link: '{copa_url}/fr-gs/information-au-voyage/assistance-speciale/voyage-avec-animaux-de-compagnie/',
        },
      },
      footer: {
        description:
          "Pour plus d'informations sur nos services spéciaux, cliquez ici.",
        link: '{copa_url}/fr-gs/information-au-voyage/assistance-speciale/',
      },
    },
    goBackToReservationButton: 'Retour à votre réservation',
    saveServicesButton: 'Enregistrer les services',
    specialFood: 'Repas spécial',
    wheelChair: 'Chaise roulante',
    errorAlert:
      "Une erreur s'est produite lors de la tentative d'enregistrement de vos services spéciaux. Veuillez réessayer ultérieurement.",
    succesToastText: 'Services spéciaux enregistrés avec succès.',
    partialErrorsToastText:
      "Une erreur s'est produite lors de la tentative d'enregistrement de certains de vos services spéciaux. Veuillez réessayer ultérieurement.",
  },

  specialServicesModalWCAG: {
    description:
      "Modalité des services spéciaux. Vous pouvez demander des services spéciaux pour des passagers spécifiques dans votre réservation à partir d'ici.",
    passengerSelectionDescription:
      'MENU. Vous êtes dans le menu déroulant du passager.',
    serviceSelectionDescription:
      'MENU. Vous êtes dans le menu déroulant du type de service.',
    addButtonDescription: 'Ajoutez votre sélection à votre voyage.',
    removeButtonDescription:
      'Supprimez le service sélectionné de votre réservation.',
    goBackToReservationButton: 'Retour à votre réservation',
    saveServicesButton:
      'Enregistrez les services sélectionnés dans votre réservation',
  },

  sendReceiptModal: {
    title: 'Envoyer le reçu de la réservation',
    subtitle:
      'Vous pouvez envoyer le reçu de cette réservation à une ou plusieurs adresses e-mail de votre choix.',
    emailLabel: 'Saisir le(s) courrier(s) électronique(s)',
    emailPlaceholder: 'Saisir une ou plusieurs adresses e-mail.',
    descriptionLabel:
      'Vous pouvez ajouter plusieurs adresses en utilisant la touche d\'espace ou la virgule (",").',
    goToSendReceipt: 'Envoyer le reçu',
    resultOk: 'Reçu envoyé avec succès.',
    resultError:
      "Une erreur s'est produite lors de l'envoi de votre reçu. Veuillez réessayer plus tard.",
  },

  sendReceiptModalWCAG: {
    description:
      'Vous pouvez obtenir le reçu de cette réservation à une ou plusieurs adresses e-mail de votre choix.',
  },

  changeItinerary: {
    title: 'Votre itinéraire a changé',
    subtitle_1:
      "Un changement d'itinéraire s'est produit dans vos plans de voyage.",
    subtitle_2:
      'Vérifiez que le nouvel itinéraire correspond à vos projets de voyage et que vous avez suffisamment de temps pour vos connexions.',
    alert:
      'Les changements dans votre itinéraire sont mis en évidence ci-dessous.',
    newItinerary: 'Nouvel itinéraire de voyage',
    modifiedItinerary: 'Itinéraire modifié',
    titleFlight: 'Vol',
    titleFrom: 'De',
    titleDeparture: 'Départ',
    titleTo: 'Vers',
    titleArrival: 'Arrivée',
    disclaimer_1:
      "Si le changement d'itinéraire ne convient pas à vos projets de voyage, d'autres options peuvent être disponibles.",
    disclaimer_2:
      'Veuillez vérifier le nouvel itinéraire ci-dessus avant de choisir une option ci-dessous.',
    btnLearnMore: "En savoir plus sur les changements d'itinéraire",
    link: '{copa_url}/es/web/gs/cambios-de-itinerario',
    btnAcept: "Accepter l'itinéraire et continuer",
  },

  confirmCancelReservation: {
    title: 'Êtes-vous sûr de vouloir annuler cette réservation ?',
    warningLabel:
      'Une fois que vous avez annulé votre réservation, vous ne pourrez pas la récupérer.',
    subtitle:
      'Votre réservation sera annulée en totalité et vous recevrez un remboursement de {refundAmount} sur votre carte {creditCardFrachise} se terminant par {creditCardNumberCensure}.',
    btnConfirmText: 'Annuler cette réservation',
    btnBackText: 'Revenir en arrière',
    flightDate: 'Dates de voyage',
    reservationCode: 'Code de réservation',
  },

  confirmCancelReservationWCAG: {
    description:
      'Votre réservation sera annulée dans son intégralité et vous recevrez un remboursement de {refundAmount} sur votre carte {creditCardFranchise} se terminant par {creditCardNumberCensure}.',
  },

  cancelledBooking: {
    title: 'Nous avons reçu la demande de remboursement.',
    subtitle:
      "Prochainement, vous recevrez une confirmation par e-mail une fois que nous aurons achevé le processus d'annulation de votre réservation.",
    btnNext: 'Continuer',
    flightDate: 'Dates de voyage',
    reservationCode: 'Code de réservation',
  },

  cancelledBookingWCAG: {
    description:
      "Prochainement, vous recevrez une confirmation par e-mail une fois que nous aurons achevé le processus d'annulation de votre réservation.",
  },

  plusGrade: {
    resultError:
      'Nous ne pouvons pas accéder au service de demande de surclassement en ce moment. Veuillez réessayer plus tard.',
  },

  requestRefund: {
    title: 'Nous ne pouvons pas traiter votre demande',
    description: {
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
      linkText: 'Centre de Réservations',
      startText:
        "Nous ne pouvons pas traiter l'annulation en ligne de cette réservation, cependant, vous pouvez escalader votre demande d'annulation via notre ",
      endText:
        '. Vous pouvez également annuler votre réservation et conserver le montant pour un voyage futur. Pour choisir cette option, cliquez sur le bouton et suivez les étapes indiquées.',
    },
    btnCta: 'Annuler et planifier ultérieurement',
    linkCta: '{copa_url}/fr-gs/soutien-au-client/echange-et-remboursement/',
  },
  changeStatus: {
    onWindow: 'Modifications disponibles',
    outOfWindow: 'Non disponible',
  },
  flightCard: {
    onTime: "À l'heure",
    advanced: 'En avance',
    delayed: 'En retard',
    canceled: 'Annulé',
    duration: 'Durée',
    checkInOpen: 'Check-In ouvert dans',
    completed: 'Terminé',
    alertSeatPassengers: "Certains passagers n'ont pas sélectionné de sièges.",
    alertMissingPassengerInfo: 'Informations manquantes sur les passagers',
    reservationCode: 'Code de réservation',
    nextFlight: 'Prochain vol',
    atTime: 'à',
    numberReservation: 'N° de réservation :',
    to: 'vers',
    btnCheckIn: 'Check-In',
    btnPayReservation: 'Payer la réservation',
    btnBoardingPasses: "Cartes d'embarquement",
    btnSeeReservation: 'Voir la réservation',
    btnAutomaticSubscription: "S'abonner à le Check-In automatique",
    flightCancelled: 'Un vol de votre réservation a été annulé',
    tripCancelled: 'Votre voyage a été annulé',
    flightPaidRequired: 'Paiement requis. Votre réservation expirera dans',
    missingPassengerInfo: 'Informations sur les passagers manquantes',
    passengersNotSelectedSeats:
      "Certains passagers n'ont pas sélectionné de sièges",
    yourUpcomingTrip: 'Votre prochain voyage',
  },
  ellipsisMenu: {
    remove: 'Supprimer',
    rename: 'Renommer',
  },

  ellipsisMenuWCAG: {
    title: 'MENU.',
    remove:
      'SUPPRIMER, bouton. Utilisez cette option pour supprimer ce voyage de votre Panneau de Mes Voyages.',
    rename:
      'RENOMMER, bouton. Utilisez cette option pour changer le nom de ce voyage.',
    descriptionPastTrips:
      'Vous êtes dans le menu avec une option, appuyez sur Entrée pour supprimer ce vol de votre Panneau de Voyages Antérieurs, appuyez sur la touche Échap pour fermer ce menu',
    descriptionSaveCard: 'Vous êtes dans le menu avec deux options.',
    descriptionTripDetailCard: 'Vous êtes dans le menu.',
  },

  segmentCard: {
    layover: 'Escale',
    header: {
      to: 'Vers',
      segments: `{stopsNumber} {stopsNumber, plural,
      one {segment}
      other {segments}
      }`,
    },
    flightDetailedInformation: {
      duration: 'Durée',
      flightNumberTitle: 'VOL',
      date: 'Date',
      departureTime: 'Heure de départ',
      arrivalTime: "Heure d'arrivée",
    },
    flighBasicInformation: {
      emptySeats: 'Pas de sièges sélectionnés',
      title: 'INFORMATIONS SUR LE VOL',
      cabin: 'Cabine',
      fareFamily: 'Famille de tarifs',
      seats: 'Sièges',
      aircraft: "Modèle d'aéronef",
      meals: 'Repas',
      entertainment: 'Divertissement',
      interline: 'Interligne',
    },
  },

  tripDetail: {
    sendReceipt: 'Envoyer le reçu',
    operated: 'Exploité:',
    printItinerary: "Imprimer l'itinéraire",
    addCalendar: 'Ajouter au calendrier',
    selectSeats: 'Sélectionner des sièges',
    requestUpgrade: 'Demander un surclassement',
    cancelReservation: 'Annuler la réservation',
    titlePassengerMultitraveller: 'Passagers dans cette réservation',
    checkedIn: 'Check-In effectué',
    readyforCheckIn: 'Informations complètes',
    infoNotcompleted: 'Informations incomplètes',
    addSpecialServices: 'Ajouter des services spéciaux',
    noSpecialServices: 'Aucun service spécial pour ce passager.',
    group: 'Groupe',
    tooltip: "La sélection de sièges n'est pas disponible pour ce passager.",
    cancelledReservation: 'Réservation annulée',
    changeNRItinerary: "Modifier l'itinéraire NR",
    exchangesAccess: "Modifier l'itinéraire",
    sectionTitle: 'Itinéraire de Voyage',
    subtitle: 'Vérifiez les détails de votre vol et restez informé',
    flightDetailsTitle: 'Information de vol',
    cabin: '{cabin_type} Cabine',
    gateLabel: 'Porte:',
    terminalLabel: 'Terminal:',
    noGate: '-',
    noTerminal: '-',
    gateAndTerminalLabel: 'Porte et Terminal:',
    noGateNoTerminal: 'Non Assignée',
    noFlightInformation: 'Information non disponible',
    fareFamilyAndEntertainment: {
      firstDescription: 'Pour en savoir plus sur votre famille tarifaire, ',
      secondDescription:
        ' Si vous souhaitez découvrir le fonctionnement du divertissement à bord et obtenir des informations complémentaires, ',
      firstCTA: 'cliquez ici.',
      secondCTA: {
        text: 'visitez notre site pour en savoir plus.',
        link: '{copa_url}/fr-gs/decouvre-copa-airlines/experience-a-bord/divertissement/',
      },
    },
  },

  stackableAlerts: {
    economyBasic: {
      title: `Vous voyagez en classe Économique de Base sur un ou plusieurs segments de votre voyage. Certaines options peuvent être limitées :`,
      description: '',
      callToActionText: '',
      itemList1:
        "Les bagages enregistrés sont disponibles moyennant des frais supplémentaires (des exceptions s'appliquent au Venezuela)",
      itemList2:
        'Des modifications de la réservation sont disponibles moyennant des frais supplémentaires',
      itemList3:
        'La sélection de sièges est disponible moyennant des frais supplémentaires',
      itemList4: 'Les remboursements ne sont pas disponibles',
      itemList5:
        'Les membres ConnectMiles accumuleront 50% de milles et de segments',
      preferMemberNote:
        'Si vous êtes un membre Préférentiel, veuillez noter que certaines des conditions mentionnées ci-dessus peuvent varier.',
    },
    migratory: {
      title: 'Informations importantes sur votre voyage',
      descriptionStart:
        "Vérifiez les exigences en matière d'immigration et de santé pour votre prochain voyage dans notre ",
      descriptionEnd: '.',
      link: '{copa_url}/travel-preparation-hub/fr',
      linkText: "centre d'information",
      callToActionEvent: '{copa_url}/fr/web/gs/travel-requirements',
      WCAGButtonClose: "Bouton. Fermer l'alerte.",
    },
    bookHold: {
      title: 'Paiement requis',
      description:
        "Votre réservation expirera dans {time}. Pour éviter qu'elle ne soit annulée, veuillez finaliser votre achat.",
      callToActionText: "Finaliser l'achat",
    },
    expiredBookAndHold: {
      title: 'Réservation expirée',
      description: {
        link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
        linkText: 'Centre de Réservations',
        startText:
          'Le délai pour effectuer le paiement de cette réservation a expiré. Veuillez effectuer une nouvelle réservation ou contacter notre ',
        endText: " pour plus d'informations.",
      },
    },
    bookAndHoldWithoutSSR: {
      title: 'Paiement requis',
      description: {
        startText:
          "Pour éviter l'annulation de votre réservation, veuillez finaliser votre achat en contactant notre ",
        reservationCenter: {
          text: 'Centre de Réservations',
          link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
        },
        middleText: " ou en visitant l'un de nos ",
        salesOfices: {
          text: 'Bureaux de Vente',
          link: '{copa_url}/fr-gs/soutien-au-client/bureau-de-ventes/',
        },
      },
    },
    indirectChannel: {
      title: 'Informations importantes sur votre réservation',
      description:
        "Cette réservation a été effectuée via un intermédiaire. Pour effectuer des modifications, annuler votre réservation ou effectuer d'autres transactions, veuillez contacter le point d'achat.",
    },
    cancelledReservation: {
      title: 'Cette réservation a été annulée',
    },
    paymentAttemptsFailed: {
      title: "Nous n'avons pas pu traiter votre paiement",
      description:
        'Vous avez épuisé toutes les tentatives de traitement du paiement. Veuillez contacter notre ',
      textLink: 'Centre de Réservations.',
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    },
    pendingPayment: {
      title: 'Traitement du paiement en cours',
      description:
        'Le paiement pour votre voyage vers {to} est en cours de traitement ; ce processus peut varier en fonction de la méthode de paiement utilisée. Pour le moment, certaines actions pour la réservation ne sont pas disponibles.',
    },
    minor01Alert: {
      title: 'Votre itinéraire a été ajusté',
      description:
        'Des ajustements mineurs ont été nécessaires pour votre itinéraire. Veuillez vérifier les détails des horaires de vos vols.',
      button: 'Vérifié',
    },
  },

  tripDetailWCAG: {
    specialServicesSeeMoreWCAG:
      "Voir plus d'informations sur vos demandes de services spéciaux pour ce voyage",
    specialServicesSeeLessWCAG:
      'Réduire les informations sur vos demandes de services spéciaux pour ce voyage',
    addSpecialServices: 'Demander des services spéciaux pour ce voyage',
    sendReceiptWCAG: 'Envoyer un reçu de ce voyage',
    printItineraryWCAG: 'Imprimer une copie de cet itinéraire',
    addCalendarWCAG:
      'Ajouter les dates de ce voyage à votre calendrier préféré',
    renameTripWCAG:
      'REBIROIT, bouton. Utilisez cette option pour changer le nom de ce voyage.',
    removeTripWCAG:
      'SUPPRIMER, bouton. Utilisez cette option pour supprimer ce voyage de votre Panneau de Mes Voyages.',
    selectSeatsWCAG:
      'Sélection de sièges, bouton. Utilisez ce bouton pour sélectionner les sièges de chaque passager de ce voyage',
    requestUpgradeWCAG:
      'Demander un surclassement, bouton. Utilisez ce bouton pour demander un surclassement pour chaque passager de ce voyage',
    cancelReservationWCAG:
      "Annuler la réservation, bouton. Utilisez ce bouton pour être redirigé vers le processus d'annulation de la réservation.",
    changeItineraryButtonWCAG:
      "Modifier l'itinéraire, bouton. Utilisez ce bouton pour être redirigé vers le processus de modification de l'itinéraire.",
  },

  tripsButtons: {
    backToTripHub: 'Retour à Mes Voyages',
    backToFindReservation: 'Rechercher une autre réservation ',
    save: 'Enregistrer dans Mes Voyages',
    saved: 'Voyage Enregistré',
  },

  tripsButtonsWCAG: {
    backToTripHubWCAG: 'Retour à Mes Voyages',
    backToFindReservationWCAG: 'Retour à la Recherche de Réservation',
    saveWCAG: 'Enregistrer ce voyage dans votre Panneau de Mes Voyages',
    savedWCAG: 'Voyage Enregistré',
    findReservation: 'Rechercher votre réservation, bouton',
  },

  accordionSection: {
    seeMoreCTA: 'Voir plus',
    seeLessCTA: 'Voir moins',
    specialServices: 'Services spéciaux',
    specialServicesHyphen: '—',
    withMeal: 'Avec repas',
    withSpecialAssistance: 'Avec assistance spéciale',
    withoutSpecialServicesAdded: 'Sans services spéciaux',
  },

  pastTripsDetail: {
    itinerary: 'Itinéraire de voyage',
    from: 'De',
    toward: 'Vers',
    date: 'Date',
    flight: 'Vol',
    operated: 'Opéré par',
    passenger: 'Passagers dans cette réservation',
    name: 'Nom du passager',
    number: 'Numéro de billet',
    nameAndNumber: 'Nom et numéro de billet',
  },
  memberMenu: {
    logOut: 'Déconnexion',
    myAccount: 'Mon compte',
    greeting: 'Bonjour,',
    status: 'Statut',
    connectMiles: '# ConnectMiles',
    balance: 'Solde des milles',
  },
  memberMenuWCAG: {
    pageDescription:
      "Menu des membres ConnectMiles : vous permet de vous déconnecter, d'accéder à votre compte ConnectMiles et de voir votre numéro et votre statut ConnectMiles",
    connectMilesLogo: 'Logo ConnectMiles',
    logOut:
      'Bouton de déconnexion : Appuyez sur Entrée pour vous déconnecter de votre compte ConnectMiles.',
    myAccount:
      'Bouton Mon compte : Appuyez sur Entrée pour être redirigé vers la page de votre compte ConnectMiles.',
    connectMiles: 'Numéro de ConnectMiles',
  },
  copaSPTooltip: {
    description:
      'Copa Showpass est notre système de divertissement à bord, qui vous permet de diffuser du contenu sans fil sur votre appareil via notre application Copa Airlines.',
  },
  touchScreenOnDemand: {
    description:
      'Écran tactile personnel est notre système de divertissement personnel, où vous pouvez utiliser les écrans situés sur votre siège pour sélectionner, contrôler et diffuser le contenu de votre choix.',
  },
  overheadScreen: {
    description:
      'Écran général est notre option de divertissement à bord partagé. Regardez certains des meilleurs films actuels et des émissions les plus populaires sur nos écrans généraux.',
  },
  upcomingTripCardWCAG: {
    description:
      'Groupe Votre prochain voyage. Dans ce groupe, vous pouvez voir et gérer les détails de votre prochain voyage à {destination} le {departureDate}.',
    btnSeeReservation: 'Voir les détails de ce voyage.',
    btnCheckIn: 'Check-In, bouton',
  },
  saveTripWCAG: {
    description:
      'Bouton. Voyage enregistré à {destination} le {departureDate}.   Utilisez ce bouton pour voir et gérer les détails de ce voyage.',
  },

  FAQ: {
    title: 'Foire aux questions',
    viewMore: 'Plus de questions fréquentes',
    linkViewMore:
      'https://help.copaair.com/hc/es-419/categories/360004256754-Preguntas-m%C3%A1s-frecuentes',
    contact: "Obtenir de l'aide",
    linkContact: '{copa_url}/fr-gs/soutien-au-client/',
    listOfFQA: {
      equipmentAdd:
        'Puis-je emporter plus de bagages que ce qui est indiqué dans la section Bagages autorisés?',
      equipmentAddBody:
        "Oui, vous le pouvez ! Lors de l'enregistrement de vos bagages, nos agents peuvent vous aider avec l'enregistrement de bagages supplémentaires. Vous devrez payer des frais pour les bagages supplémentaires. Consultez nos {link} pour plus d'informations. Pour des raisons de santé et de sécurité, de nombreux aéroports ont mis en place des protocoles de biosécurité. N'oubliez pas d'arriver à l'aéroport avec suffisamment de temps pour enregistrer vos bagages sans problème.",
      nameLinkequipmentAdd: 'politiques de bagages',
      linkequipmentAdd:
        '{copa_url}/fr-gs/information-au-voyage/informations-de-bagages/',
      webCheckIn: 'Puis-je effectuer Check-In en ligne via Mes Voyages?',
      webCheckInBody:
        "Oui, vous le pouvez ! L'option de Check-In en ligne sera disponible 24 heures avant votre vol. Accélérez votre Check-In en remplissant vos informations personnelles dans la section Informations du passager.",
      accumulateMiles: 'Comment puis-je accumuler des milles pour mon voyage?',
      accumulateMilesBody:
        "Pour accumuler des milles précieux pour votre voyage, saisissez votre numéro de ConnectMiles ou de votre programme de voyageur fréquent dans la section Informations du passager. Si vous n'êtes pas encore membre de notre programme ConnectMiles, vous pouvez vous inscrire sur notre {link} et ensuite ajouter votre nouveau numéro à la réservation.",
      linkAccumulateMiles: '{copa_url}/fr-gs/enrollment',
      nameLinkAccumulateMiles: "formulaire d'inscription",
      selectSeat: 'Comment puis-je sélectionner un siège avant mon voyage?',
      selectSeatBody:
        "Vous pouvez sélectionner un siège dans la section Informations du passager. En fonction de la tarification que vous avez achetée, vous pouvez sélectionner un siège gratuitement si cela s'applique, ou acheter un siège dès que votre billet est confirmé. Nous proposons différents types de sièges pour votre confort. Vous pouvez trouver plus de détails sur notre {link}.",
      linkSelectSeat:
        '{copa_url}/fr-gs/legal/termes-et-conditions/selection-de-sieges/',
      nameLinkSelectSeat: "page d'informations sur les sièges",
    },
  },

  alertScheduleChanges: {
    descriptionSing: {
      start:
        'Il y a eu une mise à jour de votre vol de {from} à {to}. Veuillez contacter notre ',
      end: " pour obtenir de l'aide. Veuillez nous excuser pour les désagréments.",
    },
    descriptionPlu: {
      start:
        "Il y a eu une mise à jour sur plus d'un de vos vols. Veuillez contacter notre ",
      end: " pour obtenir de l'aide. Veuillez nous excuser pour les désagréments.",
    },
    link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    linkText: 'Centre de Réservation',
  },

  addToCalendar: {
    subjectFlight: 'Vol CM',
    subjectFrom: 'de',
    subjectTo: 'à',
    reservationCode: 'Code de Réservation :',
    itinerary: 'ITINÉRAIRE : Vol CM',
    departure: 'Départ :',
    on: 'le',
    at: 'à',
    arrival: 'Arrivée :',
    fareFamily: 'Famille Tarifaire :',
    travelerInfo: 'Informations du Passager :',
  },
  retract: {
    header: {
      title: 'Annuler votre réservation',
      infoText: 'Votre voyage vers {destination}',
    },
    title: 'Souhaitez-vous annuler cette réservation ?',
    subTitle:
      "Votre réservation est couverte par la période d'achat flexible de 24 heures. Cela signifie qu'en cas d'annulation, nous vous rembourserons 100 % du montant payé, comme indiqué dans notre politique. Veuillez lire attentivement les informations suivantes avant de procéder.",
    dateTrip: 'Dates de voyage',
    reservationCode: 'Code de réservation',
    fareFamily: 'Famille tarifaire',
    refundDetails: 'Détails du remboursement',
    baseAirfare: 'Tarif de base',
    taxes: 'Taxes',
    seats: 'Sièges',
    subtotal: 'Sous-total',
    noRefundable: 'Non remboursable',
    refundTotal: 'Total à rembourser',
    infoImportant: 'Informations importantes sur votre annulation',
    item1: {
      start:
        "Copa Airlines offre des remboursements complets pour les réservations qui respectent les conditions de la politique d'achat flexible de 24 heures. Consultez les détails de cette politique ",
      linkText: 'ici',
      link: '{copa_url}/fr-gs/legal/termes-et-conditions/annulation-flexible/',
      end: '.',
    },
    item2:
      "Le coût de la réservation sera remboursé sur la méthode de paiement utilisée pour effectuer l'achat.",
    item3:
      'Le montant du remboursement doit apparaître sur votre relevé bancaire ou de carte de crédit dans les deux (2) relevés de compte suivants à compter de la date de demande de remboursement. Cette période peut varier en fonction de la date de clôture de votre relevé.',
    cancelReservation: 'Annuler cette réservation',
    insuranceRefund1:
      'Vous avez souscrit une assurance voyage fournie par Chubb pour un montant de {value} {currency}. Pour demander le remboursement de ce service, veuillez contacter directement Chubb via leurs',
    insuranceRefundLink: 'points de contact',
    insuranceRefundUrl:
      'https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=es',
    insuranceRefund2:
      ", en utilisant votre code de réservation comme référence d'achat.",
    cancelSummary:
      'Votre réservation sera entièrement annulée et vous recevrez un remboursement de {amount}',
    refundLink: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    error: {
      toast: "Une erreur s'est produite. Veuillez réessayer.",
      alert: {
        start:
          "Nous sommes désolés ! En raison d'une erreur, nous n'avons pas pu annuler votre réservation. Veuillez réessayer ultérieurement ou contactez notre ",
        end: '.',
        link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
        linkText: 'Centre de Réservation',
      },
    },
  },

  shoppingCart: {
    title: 'Extras de voyage',
    subtitulo:
      'Voici les extras de voyage que vous avez sélectionnés. Vous pouvez les payer maintenant ou continuer à gérer votre voyage.',
    verDetalle: 'Voir les détails',
    subtotal: 'Sous-total',
    impuestos: 'Taxes',
    total: 'Total ',
    cerrar: 'Fermer',
    pagoTotal: 'TOTAL À PAYER',
    impuetso: ' en taxes',
    tiempoRestante: 'Temps restant :',
    pagarExtras: 'Payer les extras de voyage',
    seatsPremium: 'Siège Premium ',
    barraTitle:
      'Votre sélection de sièges expirera dans {time}. Complétez votre achat maintenant.',
    expireTitle: 'Votre sélection de sièges a expiré',
    expireSubTitle:
      'Le délai maximal pour acheter les sièges sélectionnés a expiré. Vous pouvez sélectionner à nouveau des sièges.',
    expireBtn: 'Revenir à la réservation',
    WCAGBoton: 'Bouton. Revenir à la réservation.',
    WCAGClose: 'Bouton. Fermer la fenêtre modale.',
    WCAGTitle: 'Fenêtre modale de sélection expirée.',
  },

  extraTrip: {
    title: 'Extras de voyage achetés',
    subtitle: 'Voici les extras de voyage que vous avez déjà achetés.',
    tableDescription: 'Description',
    tableTraveler: 'Passager',
    tableFlight: 'Vol',
    tableValue: 'Valeur',
    seat: 'Siège',
    'extra-bag': 'Bagage supplémentaire',
    'pet-in-cabin': 'Animal de compagnie en cabine',
    'sport-equipment': 'Équipement sportif',
    'music-instrument': 'Instrument de musique',
  },

  reservationConfirmed: {
    title: 'Nous traitons votre réservation',
    subtitle1: 'Nous traitons votre voyage à {to}.',
    subtitle2: ' Votre code de réservation est ',
    subtitle3:
      'Une fois que nous aurons traité votre paiement, vous recevrez une confirmation par courriel à ',
    subtitle4: '.',
    summary: "Résumé de l'achat",
    successfully: 'Nous traitons votre paiement de {monto}.',
    authorizationCode: "Code d'autorisation",
    nowYouCan: 'Vous pouvez maintenant :',
    downloadCopaApp: "Télécharger l'application Copa Airlines",
    downloadCopaAppText:
      "Avec l'application Copa Airlines, vous pouvez recevoir des notifications en temps réel sur l'état de votre vol, effectuer le Check-In en ligne et accéder au divertissement à bord.*",
    downloadCopaAppSubText:
      "* Le divertissement à bord s'applique uniquement aux vols équipés du système Copa Showpass.",
    laptobTitle: 'Gérer votre réservation via Mes Voyages',
    laptobText:
      'À travers la section Mes Voyages sur copa.com, vous pouvez choisir ou modifier vos sièges, consulter votre itinéraire de voyage et apporter des modifications à votre réservation.',
    qrTitle: 'Effectuer le Check-In en ligne 24 heures avant votre vol',
    qrText:
      "Gagnez du temps à l'aéroport en vous enregistrant en ligne 24 heures avant votre vol. Vous pouvez choisir ou modifier vos sièges et obtenir vos cartes d'embarquement numériques.",
    btnGoToReservation: 'Aller à la réservation',
    lateBookingFee: 'Frais de réservation tardive',
    serviceFee: 'Frais de service',
  },
  payReservation: {
    titleBar: 'Payer la réservation',
    title: 'Détails du voyage',
    subTitle:
      "Veuillez examiner attentivement votre réservation avant de procéder à l'achat.",
    resumen: 'Résumé de la réservation ',
    btn: 'Continuer vers la sélection des sièges',
    layover: 'escale',
    airfare: 'Tarif aérien',
    uplift: {
      description: 'Vous avez choisi de payer avec Uplift.',
      linkText: "Plus d'informations.",
      link: '{copa_url}/fr-pa/decouvrir-copa-airlines/uplift/',
    },
    alerts: {
      missingMilesTitle:
        'Il vous manque {miles} milles pour acheter cette réservation',
      missingMilesText:
        'Assurez-vous de ce voyage ! Nous avons différentes options pour vous permettre de compléter cette réservation : {link}',
      buyMiles: 'Acheter des milles',
      buyMilesLink:
        'https://miles.copaair.com/login.html?product=buy&language=fr',
      restrictedAccountTitle: 'Votre compte est restreint',
      restrictedAccountText:
        "Votre compte a été temporairement bloqué pour des raisons de sécurité. Veuillez contacter notre {link} de ConnectMiles pour obtenir de l'aide.",
    },
    redemptions: {
      miles: 'Milles',
      others: 'Autres',
      passengersGrouped: `{itemCount} {itemCount, plural,
            one { passager}
            other { passagers}
      }`,
    },
  },

  taxes: {
    smallTitle: 'TOTAL DE LA RÉSERVATION',
    bigTitle: 'Détail des taxes et impôts',
    titleDescription: 'DESCRIPTION',
    titleMonto: 'MONTANT',
    total: 'Total des taxes et impôts',
  },

  pseInformation: {
    title: 'Informations de paiement (PSE Colombie)',
    subtitle:
      "Voici les informations liées à l'achat à des fins de référence future.",
    bank: 'Banque',
    date: 'Date de transaction',
    status: 'État',
    pendingStatus:
      'Transaction en attente (veuillez vérifier si le débit a été effectué à la banque)',
    approvedStatus: 'Transaction approuvée',
    orderReference: 'Référence de commande',
    ipAddress: 'Adresse IP',
    value: 'Valeur',
    transaction: 'Transaction',
    descriptionLabel: 'Description du paiement',
    descriptionText: 'Achat chez Copa Airlines Colombie',
    transactionNumber: 'Numéro de référence',
    company: {
      title: "Coordonnées de l'entreprise",
      companyLabel: 'Société',
      companyText: 'COMPAÑÍA PANAMEÑA DE AVIACIÓN S.A.',
      nitLabel: 'NIT',
      nitText: '860.025.338-2',
      addressLabel: 'Adresse',
      addressText:
        'AV EL DORADO N° 103 – 08 INT 1 TERMINAL AEREO SIMON BOLIVAR Bogota',
      phoneNumberLabel: 'Téléphone',
      phoneNumberText: '(571) 3578080',
    },
  },

  originalItinerary: {
    accordiontitle: "Voir l'itinéraire d'origine",
    subtitle:
      "Une fois le nouvel itinéraire confirmé, l'ancien ne sera plus valide.",
    close: 'Voir les détails',
    open: 'Masquer les détails',
    noChanges: 'Pas de changement',
    flightModified: 'Vol {flight} a été modifié',
  },

  onBoardingModal: {
    title:
      "Nous avons apporté des modifications à l'itinéraire de ce voyage. Votre action est requise.",
    descriptionBold:
      "Nous avons dû apporter des modifications à l'itinéraire d'un ou de plusieurs segments de votre voyage à {destination}.",
    description2:
      "Veuillez vérifier le nouvel itinéraire ; vous aurez la possibilité d'accepter les modifications ou de modifier vos dates de voyage.",
    subtitle:
      'Suivez ces étapes pour accepter ou modifier le nouvel itinéraire :',
    step1: 'Étape 1',
    step1des: "— Vérifiez l'itinéraire de voyage mis à jour.",
    step2: 'Étape 2',
    step2des: '— Acceptez ou modifiez le nouvel itinéraire.',
    step3: 'Étape 3',
    step3des: '— Passez à Mes Voyages pour confirmer votre sélection.',
    newItineraryBtn: 'Voir le nouvel itinéraire',
    ctaWcag: 'Cliquez ici pour fermer et accéder à votre itinéraire.',
    buttonWcag: 'Cliquez ici pour vérifier votre nouvel itinéraire',
  },

  loginBox: {
    title:
      'Connectez-vous à votre compte ConnectMiles pour continuer cette réservation.',
    disclaimer:
      'Vous devez vous connecter avec le compte que vous avez utilisé pour effectuer cette réservation initiale.',
    button: 'Se connecter et continuer',
  },

  loginSRT: {
    title: 'Connectez-vous à votre compte ConnectMiles pour continuer.',
    userBox: 'Entrez votre identifiant',
  },
  // ////////////////////////////// end translations My trips//////////////////////////////////

  languages: {
    english: 'English',
    spanish: 'Español',
    portuguese: 'Português',
    french: 'Français',
    dropdownDescription: 'LANGUE/LANGUAGE/LÍNGUA',
  },

  headerNavigation: {
    signup: "S'inscrire",
    language: 'Langue',
    miles: '{number} milles',
  },
  loginForm: {
    user: "Adresse e-mail, numéro de ConnectMiles ou nom d'utilisateur",
    passwordField: 'Mot de passe',
    loginButton: 'Se connecter',
    logoutButton: 'Déconnexion',
    inputPlaceHolderUser: "Entrez votre nom d'utilisateur",
    inputPlaceHolderPrivatePhrase: 'Entrez votre mot de passe',
    forgotPrivatePhrase: 'Mot de passe oublié ?',
    account: "Vous n'avez pas de compte ?",
    accountLink: 'Créez-en un maintenant.',
  },
  clientService: {
    callCenter: "Centre d'appels",
    linkCallCenter: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    serviceCenter: 'Centre de service',
    linkServiceCenter: '{copa_url}/fr-gs/connectmiles/',
  },
  loginFormAlerts: {
    loginError:
      'Désolé ! Les informations que vous avez fournies ne correspondent pas à nos dossiers. Veuillez les vérifier et réessayer.',
    logOutError:
      "Une erreur s'est produite lors de la tentative de déconnexion. Réessayez.",
    logInUnknownError:
      "Une erreur s'est produite lors de la mise à jour. Réessayez.",
    invalidCredentials:
      'Les informations que vous avez fournies ne correspondent pas à nos dossiers. Veuillez les vérifier et réessayer.',
    blockedAccount: {
      title:
        'Votre compte a été temporairement bloqué pour des raisons de sécurité. Contactez notre {link}',
      link: '{copa_url}/fr-gs/',
      text: "pour obtenir de l'aide.",
    },
    wrongInformation:
      'Les informations que vous avez fournies ne correspondent pas à nos dossiers. Veuillez les vérifier et réessayer.',
  },
  findReservationAlerts: {
    notFound:
      "Vous voyagez en groupe et avez besoin de l'assistance d'un agent. Rendez-vous à l'aéroport pour effectuer le Check-In.",
    tooEarly:
      'Il est trop tôt pour effectuer le Check-In. Le Check-In commence 24 heures avant le départ de votre vol. Revenez plus tard.',
    tooLate:
      "Il est trop tard pour effectuer le Check-In. Contactez un agent à l'aéroport pour obtenir de l'aide.",
    otherCarrier:
      'Votre premier vol est opéré par une autre compagnie aérienne. Veuillez effectuer le Check-In sur leur site web.',
    largeGroup:
      "Vous voyagez en groupe et avez besoin de l'assistance d'un agent. Rendez-vous à l'aéroport pour effectuer le Check-In.",
    outOfSync:
      "Il semble que votre réservation doit être examinée par l'un de nos agents. Veuillez contacter notre {link} pour obtenir de l'aide.",
    noETKT:
      "Il semble que votre réservation doit être examinée par l'un de nos agents. Veuillez contacter notre {link} pour obtenir de l'aide.",
    cancelledFlights:
      "Il semble que votre réservation doit être examinée par l'un de nos agents. Veuillez contacter notre {link} pour obtenir de l'aide.",
    restrictedScenarios:
      "Une demande spéciale est associée à votre réservation. Veuillez effectuer le Check-In à l'aéroport.",
    wrongLastName:
      'Le nom que vous avez fourni ne correspond pas à nos dossiers. Veuillez le vérifier et réessayer.',
    incorrectETKT:
      "Désolé ! Nous n'avons pas pu trouver votre réservation avec les informations fournies. Veuillez vérifier vos données et réessayer.",
    eTicketRequired:
      "Il semble que votre réservation doit être examinée par l'un de nos agents. Veuillez contacter notre {link} pour obtenir de l'aide.",
    groupLarge:
      "Vous voyagez en groupe et avez besoin de l'assistance d'un agent. Rendez-vous à l'aéroport pour effectuer le Check-In.",
    PNRNoSegments:
      "Il semble que votre réservation doit être examinée par l'un de nos agents. Veuillez contacter notre {link} pour obtenir de l'aide.",
    serviceUnavailable:
      "Une erreur s'est produite lors de la recherche de votre réservation. Réessayez.",
  },
  findReservationWCAG: {
    pageTitle: 'Mes voyages',
    pageDescription:
      "Première page du flux 'Mes voyages', vous devez fournir votre numéro de réservation et votre nom de famille pour trouver votre réservation.",
    textSubtitle:
      'Depuis Mes voyages, vous pouvez consulter, gérer et enregistrer vos réservations Copa Airlines.',
    textWellcome: 'Pour commencer, trouvons votre réservation.',
    feebackBetaLink:
      'Vous utilisez la version bêta de Check-in en ligne. Appuyez sur Entrée pour envoyer un e-mail avec vos commentaires et suggestions depuis votre application de messagerie.',
    oldCheckinUrl:
      "Pour effectuer le Check-in avec l'ancienne version, cliquez ici ",
    copaLogoLink: "Logo de Copa. Aller à la page d'accueil de Copa",
    copaLogoAltMobile: "Logo de Copa. Aller à la page d'accueil de Copa",
    extendedCopaLogo: "Logo de Copa. Aller à la page d'accueil de Copa",
    cmLogoAlt: 'Logo de ConnectMiles',
    cmSignupButton:
      "Si vous n'avez pas de compte ConnectMiles, appuyez sur Entrée pour en créer un maintenant",
    cmLoginButton:
      'Connectez-vous, vous pouvez utiliser votre profil ConnectMiles pour trouver vos voyages encore plus rapidement. Appuyez sur Entrée pour saisir les informations',
    cmCloseLoginButton:
      'Appuyez sur Entrée pour fermer ce panneau ConnectMiles',
    cmLogoutButton:
      'Appuyez sur Entrée pour vous déconnecter de votre compte ConnectMiles.',
    languageSelection:
      "C'est un menu déroulant avec 3 options, utilisez les touches Haut et Bas, puis appuyez sur Entrée pour sélectionner votre langue préférée",
    reservationSearchCodeField:
      "Vous pouvez saisir un code alphanumérique de 6 chiffres si vous avez un code PNR, ou un numéro numérique de 13 chiffres si vous avez un numéro de billet électronique, ou un numéro de 9 chiffres si c'est un numéro de ConnectMiles.",
    reservationSearchLastNameField:
      'Entrez le nom de famille figurant sur votre réservation',
    searcReservationButton:
      "Appuyez sur Entrée pour rechercher votre réservation et poursuivre le processus d'enregistrement.",
  },
  loginFormWCAG: {
    pageDescription:
      "Boîte de connexion ConnectMiles. Vous permet de vous connecter à votre profil d'utilisateur. Veuillez utiliser la touche Échap pour revenir au menu principal.",
    userNameField:
      "Entrez votre adresse e-mail, numéro de ConnectMiles ou nom d'utilisateur",
    passwordField: 'Entrez votre mot de passe ConnectMiles',
    loginButton:
      'Appuyez sur Entrée pour vous connecter avec les informations saisies',
    forgotPrivatePhrase:
      'Appuyez sur Entrée pour accéder à ConnectMiles et récupérer votre mot de passe',
    joinNow:
      "Si vous n'avez pas de compte ConnectMiles, appuyez sur Entrée pour en créer un maintenant",
  },
  customModalWCAG: {
    closeButton:
      'Bouton de fermeture, appuyez sur Entrée pour fermer la fenêtre contextuelle.',
  },
  searchNumberForm: {
    inputLabelNumber: 'Code de réservation ou numéro de billet électronique',
    inputPlaceholderNumber: 'Saisissez le code ou le numéro',
    inputPlaceholderLastName: 'Entrez votre nom de famille',
    inputLabelLastName: 'Nom de famille',
    search: 'Rechercher votre réservation',
  },
  ancillaries: {
    title: 'Options de voyage',
    description: 'Améliorez votre expérience de voyage avec ces options.',
    carTitle: 'Location de voiture',
    carDescription:
      'Différentes options pour profiter au maximum de nos destinations.',
    carLinkDescription: 'Réserver maintenant',
    carLink: 'https://cars.cartrawler.com/copa/fr/?clientId=538733',
    hotelTitle: 'Réserver un hôtel',
    hotelDescription: "Trouvez l'hébergement parfait pour votre voyage.",
    hotelLinkDescription: 'Rechercher des hôtels',
    hotelLink:
      'https://sp.booking.com/index.html?aid=2027762;label=pagemanagebooking-ban-2027762-click_brand-mmbcanales',
    insuranceTitle: 'Assurance voyage',
    insuranceDescription: 'Vous avez déjà souscrit une assurance voyage ?',
    insuranceLinkDescription: 'Assurez votre voyage ici',
    redemption: {
      milesTitle: 'Rechargez votre solde de miles',
      milesDescription:
        "Plus de milles maintenant. Plus d'aventures plus tard. Rechargez votre solde de milles et commencez à planifier vos prochaines vacances.",
      milesCTA: 'Acheter des milles',
      milesLink: 'https://miles.copaair.com/?product=buy&language=es',
      hotelsTitle: 'Accumulez plus de milles lors de votre séjour !',
      hotelsDescription:
        "ConnectMiles Hotels est une plateforme créée exclusivement pour nos membres, où vous pouvez gagner de 500 à 15 000 milles par nuit avec vos réservations d'hôtel. Choisissez parmi plus de 400 000 hôtels et gagnez des milles qui vous permettront de continuer à voyager vers votre destination de rêve.",
      hotelsCTA: 'Réservez maintenant !',
      hotelsLink: 'https://connectmileshotels.copaair.com/cm/home/es-MX/',
    },
  },
  ancillariesWCAG: {
    contentDescription:
      'Section des Options de Voyage. Dans cette section, vous pouvez choisir parmi nos options de voyage supplémentaires pour améliorer votre voyage.',
    carImageDescription: 'Image. Famille dans une voiture.',
    carLinkDescription: 'Réserver maintenant.',
    hotelImageDescription: "Image. Homme dans une chambre d'hôtel.",
    hotelLinkDescription: 'Rechercher des hôtels.',
    insuranceImageDescription: "Image. Femme près d'une rivière dans la forêt.",
    insuranceLinkDescription: 'Assurez votre voyage ici.',
  },
  entertainment: {
    title: 'Divertissement',
    description:
      'Explorez nos options de divertissement et profitez de notre large sélection de contenus.',
    copaShow: 'Copa Showpass',
    showpassDescription:
      'Notre système de divertissement à bord vous permet de profiter de films, de séries, de musique et de magazines en utilisant votre appareil personnel.',
    showpassLinkDescription: 'En savoir plus',
    showpassLink:
      '{copa_url}/fr-gs/decouvre-copa-airlines/experience-a-bord/divertissement/',
    panoramaTitle: 'Panorama des Amériques',
    panoramaDescription:
      'Découvrez notre magazine numérique et profitez de sa grande variété de contenus, où que vous soyez.',
    panoramaLinkDescription: 'Découvrez-le ici',
    panoramaLink: 'https://www.revistapanorama.com/digital/',
  },
  entertainmentWCAG: {
    contentDescription:
      'Section Divertissement. Dans cette section, vous pouvez choisir parmi nos options de divertissement pour profiter de notre grande variété de contenus.',
    showpassImageDescription: 'Image. Personne tenant une tablette',
    showpassLinkDescription:
      'Lien vers la page Copa Showpass. Appuyez deux fois pour accéder à notre système de divertissement à bord et en savoir plus.',
    panoramaImageDescription:
      'Image. Plage et téléphone avec le titre du magazine',
    panoramaLinkDescription:
      'Lien vers notre magazine numérique. Appuyez deux fois pour accéder à la page Panorama des Amériques.',
  },
  whoPage: {
    title: 'Qui voyage avec vous ?',
    description:
      "Vous voyagez sur une réservation de groupe et nous avons trouvé plus d'un passager avec les informations fournies.",
    instruction: 'Veuillez sélectionner les passagers qui voyagent avec vous :',
    chooseWhoButton: 'Continuer avec ces passagers',
  },
  whoPageWCAG: {
    modalContainer:
      "Vous voyagez sur une réservation de groupe et il y a plus d'un passager avec les mêmes informations fournies. Veuillez sélectionner dans la liste votre nom et les noms des passagers avec lesquels vous souhaitez continuer.",
    checkboxWho: 'Nom du passager',
    buttonSelectedPassengers:
      'Appuyez sur Entrée pour sélectionner les passagers sélectionnés.',
    closeModal: 'Appuyez sur Entrée pour fermer cette section.',
  },
  travelerInfoForm: {
    year: 'Année',
    month: 'Mois',
    day: 'Jour',
    month01: 'Janvier',
    month02: 'Février',
    month03: 'Mars',
    month04: 'Avril',
    month05: 'Mai',
    month06: 'Juin',
    month07: 'Juillet',
    month08: 'Août',
    month09: 'Septembre',
    month10: 'Octobre',
    month11: 'Novembre',
    month12: 'Décembre',
    reviewTravelerInfo: 'Informations du voyageur',
    travelerNOfN: 'Passager {current} sur {total}',
    flightCheckIn: 'Mes Voyages',
    passportInformation: 'Informations sur le Passeport',
    travelerInfoIntro:
      "Assurez-vous de fournir des informations complètes et exactes sur les détails du voyage et du passeport de chaque passager. Si cette exigence n'est pas respectée, les autorités pourraient vous refuser l'embarquement ou l'accès à la zone d'embarquement.",
    ESTAText1:
      "Tous les passagers prévoyant de se rendre aux États-Unis dans le cadre du programme d'exemption de visa doivent obtenir une autorisation via l'ESTA (Système électronique d'autorisation de voyage) avant d'embarquer pour cette destination ;",
    ESTAText2:
      "assurez-vous d'avoir l'autorisation ESTA avant de effectuer le Check-In.",
    multipleProfiles:
      'Vous pouvez sélectionner un passager de votre profil pour remplir automatiquement les informations requises :',
    passportOptionUseProfile: 'Utiliser le Profil Enregistré',
    inputPlaceHolderProfile: 'Sélectionnez le Profil',
    passportOptionManual: 'Entrer Manuellement les Informations du Passeport',
    selectPassport:
      'Vous avez enregistré deux passeports, veuillez sélectionner celui que vous souhaitez utiliser :',
    inputLabelFirstName: 'Prénom',
    inputPlaceHolderFirstName: 'Entrez le Prénom',
    inputLabelLastName: 'Nom de Famille',
    inputPlaceHolderLastName: 'Entrez le Nom de Famille',
    dateOfBirth: 'Date de Naissance',
    dateOfBirthDescription:
      "Le format de la date est : 2 chiffres pour le jour de 01 à 31, 2 chiffres pour le mois de 01 à 12, et 4 chiffres pour l'année",
    residencyCardDateDescription:
      "Le format de la date est : 2 chiffres pour le jour de 01 à 31, 2 chiffres pour le mois de 01 à 12, et 4 chiffres pour l'année",
    genderOptionMale: 'Masculine',
    genderOptionFemale: 'Féminine',
    nationality: 'Pays de Nationalité',
    inputPlaceHolderNationality: 'Entrez la Nationalité',
    passportNumber: 'Numéro de Passeport',
    inputPlaceHolderPassportNumber: 'Entrez le Numéro de Passeport',
    expirationDate: "Date d'Expiration",
    expirationDateDescription:
      "Le format de la date est : 2 chiffres pour le jour de 01 à 31, 2 chiffres pour le mois de 01 à 12, et 4 chiffres pour l'année",
    expirationDateError: 'Veuillez entrer une date valide',
    countryOfIssue: 'Pays de Délivrance',
    inputPlaceHolderCountryOfIssue: 'Entrez le Pays de Délivrance',
    email: "Adresse e-mail pour l'envoi de la carte d'embarquement",
    emailError: 'Veuillez entrer une adresse e-mail valide',
    useEmailForEverybody:
      'Utiliser cette adresse e-mail pour tous les passagers',
    knownTravelerInformation: 'Numéro du Voyageur Connu',
    emergencyContactTitle: "Personne à Contacter en Cas d'Urgence",
    emergencyContactLabel: 'Nom et Prénom',
    phoneCodeLabel: 'Indicatif Pays',
    phoneLabel: 'Numéro de Téléphone',
    travelerNumber: 'Numéro du Voyageur Connu',
    inputPlaceHolderTravelerNumber: 'Entrez le Numéro',
    redressNumber: 'Numéro de Recours',
    redressNumberInputLabel: 'Numéro de Recours',
    inputPlaceHolderRedressNumber: 'Entrez le Numéro',
    frequentFlyerProgram: 'Programme de Voyageur Fréquent',
    inputPlaceHolderFrequentFlyerProgram: 'Sélectionnez le Programme',
    frequentFlyerNumber: 'Numéro du Voyageur Fréquent',
    inputPlaceHolderFrequentFlyerNumber: 'Entrez le Numéro',
    warningFrequentFlyerNumber:
      "Il s'agit d'un billet prime. Si ce n'est pas votre numéro de voyageur fréquent et que vous le modifiez, vous pourriez perdre les avantages du propriétaire du compte.",
    emergencyContact: "Personne à Contacter en Cas d'Urgence",
    fullName: 'Nom Complet',
    inputPlaceHolderFullName: 'Entrez le Nom Complet',
    PhoneNumber: 'Numéro de Téléphone',
    inputPlaceHolderPhoneNumber: 'Entrez le Numéro de Téléphone',
    countryOfResidence: 'Pays de Résidence',
    inputPlaceHolderCountryOfResidence: 'Entrez le Pays de Résidence',
    noResultMsgCountryOfIssue: 'Aucun résultat trouvé',
    noResultMsgNationality: 'Aucun résultat trouvé',
    noResultMsgCountryOfResidence: 'Aucun résultat trouvé',
    noResultMsgState: 'Aucun résultat trouvé',
    gender: 'Genre',
    whereAreYouStaying: 'Où allez-vous séjourner aux États-Unis ?',
    stayingStreetAddress: 'Adresse',
    inputPlaceHolderStreetAddress: "Entrez l'Adresse",
    stayingZipCode: 'Code Postal',
    inputPlaceHolderZipCode: 'Entrez le Code Postal',
    stayingCity: 'Ville',
    inputPlaceHolderCity: 'Entrez la Ville',
    stayingState: 'État',
    inputPlaceHolderState: "Entrez l'État",
    usResidencyCard: 'Carte de Résident des États-Unis',
    residencyCardNumber: 'Numéro de Carte',
    inputPlaceHolderCardNumber: 'Entrez le Numéro de Carte',
    residencyCardDate: "Date d'Expiration",
    sentenceInfo:
      "Assurez-vous de fournir des informations complètes et précises sur les détails de voyage et du passeport de chaque passager. Si cette exigence n'est pas respectée, les autorités pourraient vous refuser l'embarquement ou l'accès à la zone d'embarquement.",
    addButton: 'Ajouter',
    removeButton: 'Supprimer',
    estaText:
      "Tous les citoyens qui prévoient de se rendre aux États-Unis dans le cadre du Programme d'exemption de visa doivent obtenir une autorisation via le système ESTA (Système électronique d'autorisation de voyage) avant d'embarquer pour les États-Unis. Veuillez vous assurer d'avoir l'autorisation ESTA avant de terminer votre enregistrement.",
    enterFFProgram: 'Entrez le Programme de Voyageur Fréquent',
    optionalSectionLabel: '(Facultatif)',
    saveButton: 'Continuer',
    inputLabelEmail: "Adresse e-mail pour envoyer la carte d'embarquement",
    inputPlaceHolderemail: "Entrez l'Adresse e-mail",
    redemptionText:
      "Il s'agit d'un billet prime. Si ce n'est pas votre numéro de voyageur fréquent et que vous le modifiez, vous pourriez perdre les avantages du propriétaire du compte.",
    connectMilesSectionTitle:
      'Vous avez déjà des informations enregistrées dans votre profil. Sélectionnez le profil souhaité pour remplir automatiquement les informations du passeport requises ci-dessous.',
    prePopulateEmail: 'Utiliser cette adresse e-mail pour tous les passagers',
    prePopulatedWhereAreYouStaying:
      'Utiliser cette adresse pour tous les passagers',
    cmEnrollmentTitle:
      "Vous n'êtes pas encore membre de ConnectMiles ? Inscrivez-vous gratuitement dès maintenant et recevez 500 miles supplémentaires pour ce voyage !",
    cmEnrollmentParagraph:
      "Rejoignez ConnectMiles et commencez à gagner des milles et des avantages dès aujourd'hui. En vous inscrivant, nous vous enverrons un e-mail à {email} pour finaliser l'activation de votre compte. N'oubliez pas que ConnectMiles exige que chaque membre utilise une adresse e-mail unique pour créer son compte.",
    cmEnrollmentAccept:
      "Oui, je veux adhérer à ConnectMiles et j'accepte les {link}",
    cmEnrollmentLink:
      '{copa_url}/fr-gs/legal/termes-et-conditions/termes-et-conditions-connectmiles/',
    cmEnrollmentLinkText: "Conditions d'utilisation du programme.",
    linkAgreeWcag:
      "Appuyez sur Entrée pour consulter les Conditions d'utilisation du programme.",
    updaProfileCheckBox:
      'Enregistrer les données mises à jour dans mon profil ConnectMiles',
    inTransitCheckBox: 'En transit',
    inTransitCheckBoxWCAG:
      'Appuyez pour indiquer que vous êtes en transit aux États-Unis',
  },
  formValidations: {
    requiredFieldError: 'Ce champ est requis',
    max20CharactersError:
      'Vous avez entré trop de caractères. Le nombre maximum de caractères est de 20.',
    max32CharactersError: '32 caractères maximum autorisés',
    max50CharactersError: '50 caractères maximum autorisés',
    max64CharactersError: '64 caractères maximum autorisés',
    max100CharactersError:
      'Vous avez entré trop de caractères. Le nombre maximum de caractères est de 100.',
    max255CharactersError: '255 caractères maximum autorisés',
    onlyLettersError: 'Seules les lettres sont autorisées',
    onlyNumbersError: 'Seuls les chiffres sont autorisés',
    min3AndMax12CharactersError:
      '3 caractères minimum et 12 caractères maximum autorisés',
    alphaNumericError: 'Lettres et chiffres autorisés',
    dateFormatError: 'Format de date invalide',
    dateLowerThanCurrentDateError:
      "Une date antérieure à aujourd'hui est requise",
    dateGreaterThanCurrentDateError:
      'La date doit être ultérieure à la date actuelle',
    dateOfBirthError: 'Veuillez entrer une date de naissance valide',
    emailFormatError: "Format d'e-mail invalide",
    invalidFormat: 'Format invalide',
    lengthZipCode: '5 caractères minimum et 10 caractères maximum autorisés',
    lengthAdress: '1 caractère minimum et 31 caractères maximum autorisés',
    lengthCity: '1 caractère minimum et 15 caractères maximum autorisés',
    lengthEmergencyContact: 'Maximum 15 caractères autorisés',
    lengthPhoneCode: '8 caractères maximum autorisés',
    lengthPhoneNumber: '10 caractères maximum autorisés',
    ssrRepeatedValue: 'Ce service a déjà été ajouté pour ce passager.',
  },
  dashboard: {
    pendingPayment: 'Paiement en attente',
    greetingTitle: 'Bienvenue ! Effectuez le Check-In pour votre voyage',
    checkInCompleteTitle:
      "Check-Interminé ! Vous pouvez générer votre carte d'embarquement ou changer de siège",
    reviewUpcomingFlights: 'Détails du vol',
    layover: 'Escales',
    date: 'Date',
    flight: 'Vol',
    airline: 'Compagnie aérienne',
    terminalGate: 'Terminal / Porte',
    onTime: "À l'heure",
    advanced: 'Avancé',
    delayed: 'Retardé',
    canceled: 'Annulé',
    duration: 'Durée',
    operatedBy: 'Opéré par',
    travelerInformation: 'INFORMATIONS DU PASSAGER',
    seatTo: 'SIÈGE À {}',
    SeatSelectedButtonName: 'Changer',
    altImagen: 'Image sélectionnée',
    NoSelectedSeatButtonName: 'Sélectionner',
    readyCheckIn: 'Prêt pour effectuer le Check-In',
    checkedIn: 'Check-In effectué',
    failedCheckIn: 'Échec de Check-In',
    edit: 'Modifier',
    getBoardingPass: "Carte d'embarquement",
    selected: 'Sélectionné',
    noSelected: 'Choisissez votre siège',
    infoApis:
      'Vous devez fournir les informations du passager pour effectuer le Check-In',
    updateInformation: "Compléter l'information",
    titleSegment: '{departure} À {arrival}',
    changeSeat: 'Changer',
    businessSeat: 'Affaires',
    emergencySeat: "Siège d'urgence",
    seatSelected:
      'Passager {passengerName}, siège {seatCode} sélectionné pour le vol {origin} et {destination}. Appuyez sur Entrée pour changer de siège.',
    seatNotSelected:
      'Passager {passengerName}, siège non sélectionné pour le vol {origin} et {destination}. Appuyez sur Entrée si vous souhaitez changer de siège.',
    editInformation: "Modifier l'information",
    starAllianceMemberAltText: 'Copa est membre du réseau Star Alliance',
    copyRight: 'Copyright © 2020 Copa Airlines. Tous droits réservés.',
    LAXAirport:
      "En raison de travaux de construction à LAX, nous vous recommandons d'arriver au moins quatre heures avant le départ de votre vol pour avoir suffisamment de temps pour rejoindre votre porte.",
  },
  travelerInfoFormAlerts: {
    pageTitle: "Page d'informations sur le passager",
    pageDescription:
      "Pour compléter le processus de Check-In, vous devez fournir des informations. Certains champs sont obligatoires et d'autres sont facultatifs.",
    FFNChanged:
      "Vos informations de voyageur fréquent ont été mises à jour. Veuillez générer une nouvelle carte d'embarquement pour refléter ce changement.",
    UpdateErrorFFN:
      "Une erreur s'est produite lors de la mise à jour des informations du voyageur fréquent pour ce passager. Veuillez réessayer.",
    UpdateError:
      "Quelque chose s'est mal passé lors de la recherche de votre réservation. Veuillez réessayer plus tard.",
    UpdateDefaultError:
      "Une erreur s'est produite lors de la mise à jour des informations du voyageur fréquent pour ce passager. Veuillez réessayer.",
    UpdateErrorAPIS:
      "Une erreur s'est produite lors de la mise à jour des informations du voyageur fréquent pour ce passager. Veuillez réessayer.",
    saveSuccess:
      'Les informations du passager ont été enregistrées avec succès.',
    saveError:
      "Une erreur s'est produite lors de la mise à jour des informations du voyageur fréquent pour ce passager. Veuillez réessayer.",
    leaveScreenWarning:
      "Vous n'avez pas terminé de remplir tous les champs obligatoires ; si vous revenez en arrière, vous devrez recommencer. Êtes-vous sûr de vouloir quitter la page?",
    repeatedPassport:
      'Le numéro de passeport que vous avez saisi est déjà utilisé par un autre passager de votre groupe. Veuillez saisir un numéro de passeport unique.',
    FFNMatchError:
      'Désolé ! Le numéro de voyageur fréquent que vous avez saisi ne correspond pas à votre nom. Veuillez vérifier vos informations et réessayer.',
    FFNNotFoundError:
      'Désolé ! Le numéro de voyageur fréquent que vous avez saisi ne correspond pas à nos dossiers. Veuillez vérifier vos informations et réessayer.',
    expiredPassport:
      "Votre passeport a expiré. Veuillez entrer une date d'expiration valide.",
    expiredResidenceCard:
      "Votre carte de résidence a expiré. Veuillez entrer une date d'expiration valide.",
    emailError:
      "Une erreur s'est produite lors de la mise à jour de votre adresse e-mail. Veuillez réessayer.",
    leavePageTitle: 'Êtes-vous sûr de vouloir quitter cette page?',
    leavePageMessage:
      "Vous n'avez pas enregistré les informations, si vous revenez à la page de l'itinéraire, vous perdrez toutes les informations et devrez recommencer. Êtes-vous sûr de vouloir quitter?",
    leavePageCTA: 'Quitter cette page',
    stayCTA: 'Rester sur cette page',
    leaveTraveler: 'Êtes-vous sûr de vouloir quitter?',
    leavePageTravelerMessage:
      "Vous n'avez pas enregistré les informations, si vous passez au voyageur suivant, vous perdrez toutes les informations. Êtes-vous sûr de vouloir continuer?",
    leaveTravelerCTA: 'Continuer',
    stayOnTravelerCTA: 'Rester sur cette page',
    fillOutInfantInfo:
      'Vous voyagez avec un nourrisson, assurez-vous que ses informations sont complètes pour pouvoir continuer.',
    fillOutAdultInfo:
      "Vous voyagez avec un nourrisson, veuillez d'abord remplir les informations des adultes.",
    genericEnterError:
      "Une erreur s'est produite lors du chargement de la page d'informations sur le passager. Veuillez réessayer.",
    cmEnrollmentConfirmation: "Merci d'avoir choisi ConnectMiles !",
    cmEnrollmentContinue: 'Continuer',
    cmEnrollmentNumber: 'Votre numéro de ConnectMiles est',
    cmEnrollmentEmail: "L'adresse e-mail de votre compte est",
    cmEnrollmentInstrucction:
      'Nous vous avons envoyé un e-mail avec des instructions pour finaliser la configuration de votre nouveau profil.',
    cmEnrollmentError:
      "Une erreur s'est produite lors de la création de votre compte de voyageur fréquent. Veuillez réessayer.",
  },
  travelerInfoFormWCAG: {
    pageTitle: "Page d'informations du passager",
    pageDescription:
      "Pour compléter le processus de Check-In, vous devez fournir des informations. Certains champs sont obligatoires et d'autres sont facultatifs. Vous êtes dans la section des informations de {name}.",
    travelerName:
      "Vous êtes dans la section des informations de {name}, où vous devez fournir toutes les informations du passeport ; Si vous ne le faites pas, les autorités pourraient ne pas vous permettre de voyager ou d'entrer dans la zone d'embarquement.",
    backButton:
      "Appuyez sur Entrée pour revenir à la page d'accueil. Pour compléter les informations, appuyez sur Tab.",
    backButtonAlt: 'Icône de retour',
    firstName:
      'Entrez votre prénom, seules les lettres et les espaces sont autorisés.',
    lastName:
      'Entrez votre nom de famille, seules les lettres et les espaces sont autorisés.',
    gender:
      'Genre, utilisez la touche fléchée pour passer entre les options masculine et féminine, puis appuyez sur Entrée ou Espace.',
    passportNumber:
      "Entrez votre numéro de passeport. Il s'agit d'un champ alphanumérique, avec un minimum de 3 et un maximum de 12 caractères autorisés.",
    countryOfIssue:
      "Champ de complétion automatique, entrez les 3 premières lettres de votre pays d'émission, puis appuyez sur Entrée.",
    nationality:
      'Champ de complétion automatique, entrez les 3 premières lettres de votre pays de naissance, puis appuyez sur Entrée.',
    countryOfResidence:
      'Champ de complétion automatique, entrez les 3 premières lettres de votre pays de résidence, puis appuyez sur Entrée.',
    emergencyContact:
      "Personne de contact en cas d'urgence qui ne voyage pas avec vous.",
    address: "Entrez l'adresse où vous séjournerez aux États-Unis.",
    city: 'Entrez la ville de destination.',
    state:
      "Champ de complétion automatique, entrez les 3 premières lettres de l'État.",
    residentCardNumber:
      "Entrez le numéro de votre carte de résidence. Il s'agit d'un champ alphanumérique, avec un minimum de 3 et un maximum de 12 caractères autorisés.",
    residentCardExpirationDay:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner le jour d'expiration de votre carte de résidence.",
    residentCardExpirationMonth:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner le mois d'expiration de votre carte de résidence.",
    residentCardExpirationYear:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner l'année d'expiration de votre carte de résidence.",
    useProfileRadioB:
      'Section des boutons radio. Utilisez les touches fléchées vers le haut et vers le bas pour passer entre les options. Option 1 : utilisez votre profil pour remplir automatiquement les informations.',
    manualInfoRadioB:
      'Section des boutons radio. Utilisez les touches fléchées vers le haut et vers le bas pour passer entre les options. Option 2 : entrez manuellement les informations.',
    radioButtonPassportOptions:
      "Section des boutons radio. Ce profil comporte plus d'un passeport. Utilisez les touches fléchées vers le haut et vers le bas pour sélectionner le passeport que vous souhaitez utiliser.",
    selectProfile:
      "Menu déroulant. Utilisez les touches fléchées vers le haut et vers le bas pour sélectionner le profil à utiliser pour l'autocomplétion.",
    knownTravelerNumberAdd:
      'Le numéro de voyageur connu est un identifiant du programme PreCheck de la TSA des États-Unis, qui permet aux voyageurs de passer plus rapidement les contrôles de sécurité dans certains aéroports des États-Unis. Appuyez sur Entrée.',
    emergencyContactAdd: "Appuyez pour ajouter un contact d'urgence",
    emergencyContactRemove: "Appuyez pour masquer le contact d'urgence",
    enterEmergencyContact: "Entrez le nom et le prénom du contact d'urgence",
    enterCodeEmergencyContact: 'Entrez le code du pays',
    enterPhoneEmergencyContact: 'Entrez le numéro de téléphone',
    knownTravelerNumberRemove:
      'Pour supprimer ou fermer le numéro de voyageur connu, appuyez sur Entrée',
    knownTravelerNumber:
      "Entrez votre numéro de voyageur connu (TSA), il s'agit d'un champ alphanumérique avec un minimum de 3 caractères et un maximum de 12 caractères.",
    redressNumberAdd:
      "Le numéro de rectification est un numéro d'autorisation de sécurité délivré par le Département de la sécurité intérieure pour certains voyageurs. Appuyez sur Entrée.",
    redressNumberRemove:
      'Pour supprimer ou fermer le numéro de rectification, appuyez sur Entrée.',
    redressNumber:
      "Entrez votre numéro de rectification, il s'agit d'un champ alphanumérique avec un minimum de 3 et un maximum de 12 caractères autorisés.",
    frequentFlyerAdd:
      "Si vous avez un programme de voyageur fréquent, vous pouvez l'ajouter. Appuyez sur Entrée.",
    frequentFlyerRemove:
      'Pour supprimer ou fermer le numéro de voyageur fréquent, appuyez sur Entrée.',
    frequentFlyerNumber:
      "Entrez votre numéro de voyageur fréquent, il s'agit d'un champ alphanumérique.",
    frequentFlyerProgram:
      'Menu déroulant du programme de voyageur fréquent. Utilisez les touches fléchées vers le haut et vers le bas pour sélectionner le programme correspondant.',
    dayOfBirth:
      'Utilisez les flèches vers le haut et vers le bas pour sélectionner le jour de naissance du passager.',
    monthOfBirth:
      'Utilisez les flèches vers le haut et vers le bas pour sélectionner le mois de naissance du passager.',
    yearOfBirth:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner l'année de naissance du passager.",
    dayOfExpiration:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner le jour d'expiration du passeport du passager.",
    monthOfExpiration:
      "Utilisez les flèches vers le haut et vers le bas pour sélectionner le mois d'expiration du passeport du passager.",
    yearOfExpiration:
      "Utilisez les touches fléchées vers le haut et vers le bas pour sélectionner l'année d'expiration du passeport du passager.",
    email:
      "Entrez l'adresse e-mail où vous souhaitez recevoir votre carte d'embarquement.",
    saveTIFButton:
      'Appuyez sur Entrée pour enregistrer les informations et revenir au tableau de bord. Si plusieurs passagers sont concernés, vous serez redirigé vers le passager suivant.',
    continueModal:
      "Vous n'avez pas saisi toutes les informations du passeport ; si vous appuyez sur Entrée, vous perdrez ces informations. Si vous appuyez sur Tab, vous pourrez rester sur cette page et compléter les informations.",
    stayModal:
      "Appuyez sur Entrée pour rester sur la page d'informations du passager et compléter les champs requis.",
    zipCode:
      "Entrez le code postal de l'adresse où vous séjournerez aux États-Unis.",
  },
  dashboardAlerts: {
    scheduleChange:
      'Votre itinéraire a été mis à jour, veuillez vérifier les nouveaux détails de votre vol. Nous nous excusons pour les désagréments.',
    delay:
      'Il y a eu une mise à jour de votre itinéraire, veuillez vérifier les nouveaux détails de votre vol. Nous nous excusons pour les désagréments.',
    paxINF: 'Sélection de siège non disponible pour les nourrissons.',
    paxNRS:
      "Sélection de siège non disponible pour les passagers en attente. Votre siège vous sera assigné à la porte d'embarquement.",
    interline:
      'Vol non opéré par Copa Airlines. Votre siège sera assigné par la compagnie aérienne opératrice.',
  },
  connectMilesBlock: {
    message: 'Connectez-vous pour effectuer le Check-In plus rapidement !',
  },
  wcag: {
    pageTitle: 'Page principale de Check-In en ligne',
    pageDescription: 'Page principale de Check-In en ligne',
    openPanel: 'Ajouter une section {title}',
    closePanel: 'Supprimer la section {title}',
    seatSelectedDashboardMessage:
      "Passager {passengerName}, siège {seat} du vol {flightNumber} opéré par {airlineName} depuis l'aéroport {departureAirportName} de {departureCity} à l'aéroport {arrivalAirportName} de {arrivalCity}. Appuyez sur Entrée pour changer ce siège.",
    seatNoSelectedDashboardMessage:
      "Passager {passengerName}, siège non sélectionné pour le vol {flightNumber} opéré par {airlineName} depuis l'aéroport {departureAirportName} de {departureCity} à l'aéroport {arrivalAirportName} de {arrivalCity}. Appuyez sur Entrée pour choisir un siège.",
    editTravelerInfoButtonMessage:
      "Passager {passengerName}, pour modifier les informations relatives à ce passager et à d'autres passagers, veuillez appuyer sur Entrée.",
    boardingPassButtonMessage:
      "Passager {passengerName}, pour obtenir une carte d'embarquement pour ce passager, veuillez appuyer sur Entrée.",
    updateinfoButtonMessage:
      "Passager {passengerName}, pour mettre à jour les informations relatives à ce passager et à d'autres passagers, veuillez appuyer sur Entrée.",
    termsAndConditionsCheckboxDescription:
      "Articles restreints : Certains produits courants peuvent être dangereux lorsqu'ils sont transportés dans l'avion en bagage à main et/ou en soute. Assurez-vous qu'il n'y a pas de marchandises dangereuses interdites dans vos bagages, telles que des armes, des batteries au lithium, des gaz, des liquides ou des solides inflammables, des poisons et d'autres produits dangereux. Documents requis : Le passager est responsable de tous les documents de voyage nécessaires, y compris le passeport, les visas et tout autre document requis par la loi ou la réglementation dans les pays de destination ou de transit. Il est recommandé à tous les passagers de se tenir informés de leur vaccination et de leur état de santé, notamment du Certificat international de vaccination contre la fièvre jaune, s'ils voyagent dans un pays endémique.",
    nextSegmentsBTN: 'Segments suivants',
    previousSegmentsBTN: 'Segments précédents',
    subscribeACI:
      "Vous avez des vols futurs éligibles à l'enregistrement au Check-Inautomatique. Tous les passagers seront enregistrés automatiquement lorsque la période de Check-In sera ouverte, 24 heures avant le départ. Appuyez sur Entrée si vous souhaitez vous abonner.",
    restrictedItemsLink:
      "Certains produits courants peuvent être dangereux lorsqu'ils sont transportés dans l'avion en bagage à main et/ou en soute. Assurez-vous qu'il n'y a pas de marchandises dangereuses interdites dans vos bagages, telles que : Armes, Batteries, Gaz, Liquides inflammables ou solides, Substances en poudre, Autres substances dangereuses",
    segmentsmessage:
      'Vol {current} sur {total}, Numéro de vol {flightNumber}, date {flightDate} départ de {departureCity}, aéroport {departureAirport} à {departureTime}, terminal {departureTerminal}, porte {departureGate}, siège : {seatCode}, durée du vol {duration}, arrivée à {arrivalCity}, aéroport {arrivalCityAirport} à {arrivalTime}, terminal {arrivalTerminal}, porte : {arrivalGate}. ',
  },
  seatMap: {
    norecline: "Ne s'incline pas",
    seat: 'Siège {seatType}',
    mouseoverPrice: 'À partir de',
    legendTitle: 'Légende',
    section: 'Section {section}',
    descriptionTravellerList: 'Sélectionnez le passager',
    yourFlight: 'Votre vol',
    flightNOfN: 'Vol {current} sur {total}',
    flightTo: 'vers {destination}',
    seatN: 'Siège {seat}',
    noSeatSelected: 'Aucun siège sélectionné',
    tapOnSeat: "Choisissez qui s'assoit ici",
    tapOnSeatMobile: 'Cliquez sur un siège pour le sélectionner',
    chooseWhoSitsHere: "Choisissez qui s'assoit ici",
    travelTime: 'Durée du voyage : ',
    notApplicable: 'N/A',
    businessClass: 'Classe affaires',
    economyClass: 'Classe économique',
    dreamsBusinessClass: 'Classe affaires Dreams',
    backToTop: 'Retour en haut',
    restroom: 'Toilettes',
    galley: 'Service',
    seatDetails: 'Détails du siège',
    extraLeg: 'Espace supplémentaire pour les jambes',
    favorableLocation: 'Emplacement favorable',
    businessSeat: 'Classe affaires',
    unavailable: 'Non disponible',
    preferMembersAlert:
      'Les membres Préférence de ConnectMiles bénéficient de la présélection gratuite des sièges',
    premiumSeat: 'Premium',
    convenientSeat: 'Favorable',
    regularSeat: 'Standard',
    exitRow: 'Issue de secours',
    row: 'Rangée {row}',
    boardingTittle: 'Embarquement',
    boardingSeatGroup: 'Embarquement {group}',
    boardingGroup: "Groupe d'embarquement : {group}",
    pitch: 'Espacement des sièges : {pitch}',
    flatBed: 'Siège inclinable',
    seatRecline: 'Inclinaison : {recline}',
    readingLight: 'Liseuse',
    copaShowPass: 'Copa Showpass',
    outlets: 'Prise de courant et USB',
    usbPort: 'Port USB',
    rests: 'Repose-pieds et repose-jambes',
    sharedAcOutlet: 'Prise de courant partagée',
    electronicDeviceHolder: 'Support pour appareil électronique',
    inFlightEntertainment: 'Divertissement en vol',
    overheadScreen: 'Écran suspendu',
    personalDevice: 'Appareil personnel',
    onDemandScreen: 'Écran tactile personnel',
    onDemandScreen16: 'Écran tactile personnel de 16 po',
    seatbackOnDemand: 'Écran dans le dossier du siège',
    scroll: 'Faire défiler',
    exitSeat: 'Issue de secours',
    legendPrice: 'À partir de {price}',
    legendFreePrice: 'Gratuit',
    addFFNModalTitle: 'Ajoutez votre numéro ConnectMiles',
    addFFNModalLabel: 'Numéro ConnectMiles',
    addFFNModalPlaceHolder: 'Entrez le numéro ConnectMiles',
    addFFNModalParagraph:
      "Les PreferMembers de ConnectMiles bénéficient d'avantages exclusifs lorsqu'ils voyagent avec Copa Airlines, notamment la présélection gratuite des sièges. Ajoutez votre numéro ConnectMiles dès maintenant pour bénéficier de cet avantage.",
    previousFlight: 'Vol précédent',
    nextFlight: 'Vol suivant',
  },
  seatMapButtonBar: {
    addConnetMilesNumber: 'Inclure',
  },
  seatMapWCAG: {
    seat: 'Vous êtes assis au siège {seat} {location}, {seatType}, {topPerk}, {norecline}, À partir de : {price}. Appuyez sur Entrée si vous souhaitez attribuer ce siège à un passager.',
    pageDescription:
      "Page de sélection des sièges vers {arrival}. Vous pouvez sélectionner des sièges pour tous les passagers de la réservation (à l'exception des nourrissons) ; veuillez noter que la sélection de sièges est par vol.",
    backToDashboard:
      'Bouton, en appuyant sur Entrée, vous retournerez à la page de détails du voyage.',
    passengerNumberAndSeat: 'passager {number}, siège {seat}',
    passengerNumber: 'passager {number}',
    passengersList:
      'Sélectionnez un passager dans la liste : {passengers}. Pour naviguer entre les passagers, appuyez sur Tab et pour sélectionner, appuyez sur Entrée.',
    continueButton: 'Bouton Continuer.',
    backArrowButton:
      'Bouton flèche arrière, en appuyant sur Entrée, vous retournerez à la liste des passagers.',
    addConnetMiles: 'Appuyez sur Entrée pour ajouter le numéro de ConnectMiles',
    openLegend:
      'Appuyez sur Entrée pour voir la légende de la carte des sièges.',
    addFFNTitleModal:
      'Ajoutez votre numéro de ConnectMiles pour bénéficier de la sélection de sièges gratuite. Vous pouvez ajouter un numéro de voyageur pour chaque passager',
    addFFNCloseModal:
      'Bouton de fermeture, appuyez sur Entrée pour fermer la fenêtre contextuelle.',
    addFFNContinueModal:
      'Appuyez sur Entrée pour continuer avec la sélection des sièges.',
    addFFNFieldWCAG:
      "Entrez le numéro de voyageur fréquent de {passenger}. Rappelez-vous que c'est un champ alphanumérique",
  },
  pageNotFound: {
    error: 'ERREUR 404',
    description: 'Désolé, cette page est introuvable.',
    btn: 'Retour à Mes Voyages',
  },
  scrollAdvisor: {
    text: 'Faire défiler',
  },
  baggageAllowance: {
    priceLockMessage:
      'Veuillez noter que vos informations sur les bagages autorisés seront disponibles une fois que vous aurez terminé le paiement de votre réservation.',
    title: 'Bagages Autorisés',
    checkedBag: {
      plural: '{amount} Bagages enregistrés',
      singular: '{amount} Bagage enregistré',
    },
    carryOnBag: {
      plural: '{amount} Bagages à main',
      singular: '{amount} Bagage à main',
    },
    personalItems: {
      plural: '{amount} Articles personnels',
      singular: '{amount} Article personnel',
    },
    moreInfoBanner: {
      startText: "Pour plus d'informations, consultez la ",
      linkText: 'Politique des Bagages de Copa Airlines',
      endText: '.',
      link: '{copa_url}/fr-gs/information-au-voyage/informations-de-bagages/',
    },
    interline: {
      startText:
        "Un de vos vols n'est pas opéré par Copa Airlines. Veuillez vérifier notre référence aux ",
      linkText:
        'Politiques des Bagages intercompagnies des compagnies aériennes partenaires ',
      endText: " ou contactez l'un de nos agents de service.",
      link: '{copa_url}/fr-gs/decouvre-copa-airlines/alliances-aeriennes/accords-interligne/',
    },
    infant: {
      startText:
        "Les nourrissons ne peuvent pas enregistrer de bagages. Pour plus d'informations, consultez la ",
      linkText: 'Politique des Bagages pour les Nourrissons de Copa Airlines',
      endText: '.',
      link: '{copa_url}/fr-gs/information-au-voyage/informations-de-bagages/bagage-pour-enfants/',
    },
    baggageInfoNotAvailable: 'Informations non disponibles',
    link: 'Lien.',
    WCAG: {
      checkedBag:
        'Vous êtes autorisé à {amount} pièces de bagages enregistrés de {weight}{unit}',
      carryOnBag:
        'Vous êtes autorisé à {amount} un bagage à main de {weight}{unit}',
      personalItem: 'Vous êtes autorisé à {amount} articles personnels',
      openSection:
        "Voir plus d'informations sur vos bagages autorisés pour ce voyage {baggage}",
      closeSection:
        'Réduire les informations sur vos bagages autorisés pour ce voyage',
      title: 'Voici vos bagages autorisés pour votre vol vers',
    },
    error: {
      message:
        'Nous ne pouvons pas récupérer vos informations sur les bagages autorisés en ce moment.',
      startText: 'Assurez-vous de consulter la ',
      linkText: 'Politique des Bagages de Copa Airlines',
      link: '{copa_url}/fr-gs/information-au-voyage/informations-de-bagages/',
      endText: " pour plus d'informations.",
    },
  },
  summaryDetails: {
    title: 'Résumé des Sélections',
    subtitle: 'VOS EXTRAS DE VOYAGE',
    seats: 'SIÈGES',
    flightDestination: '{departure} vers {arrival}',
    flightLength: 'Vol {first} de {last}',
    subtotal: 'Sous-total',
    taxes: 'Taxes',
    total: 'Total',
    freeSeat: 'Gratuit',
    freeTotal: 'Sélection gratuite',
    exitRow: 'Siège de sortie',
    convenientSeat: 'Favorable',
    premiumSeat: 'Premium',
    noSelection: 'Pas de sélection',
    interlineSegment:
      "Ce vol n'est pas opéré par Copa Airlines, la sélection des sièges n'est pas disponible.",
  },
  summaryDetailsWCAG: {
    title: 'Détails de la sélection des sièges par segment, par passager',
    closeModal:
      'Appuyez sur le bouton pour fermer la fenêtre contextuelle du résumé de la sélection des sièges',
    summaryButtonDashBoard:
      'Appuyez sur le bouton pour voir les détails des extras de votre voyage',
  },
  sellSeatsSuccess: {
    title: 'Achat réussi',
    titleError: "Confirmation d'achat",
    subtitle:
      "Nous avons débité {amount}. Vous recevrez une confirmation par e-mail à l'adresse {email}.",
    purchaseSummary: "Résumé de l'achat",
    authCode: "Code d'autorisation :",
    subtotal: 'Sous-total',
    taxes: 'Taxes',
    total: 'Total',
    back: 'Retour à la réservation',
    alertErrorSellSeats: {
      start:
        "Nous avons traité votre paiement avec succès, mais nous n'avons pas pu attribuer vos sièges. Veuillez contacter notre ",
      linkText: 'Centre de Réservations',
      link: '{copa}/fr-gs/soutien-au-client/centre-dappels/',
      end: " pour obtenir de l'aide. Nous nous excusons pour la gêne occasionnée.",
    },
    WCAG: {
      title: 'Confirmation de paiement. Fenêtre contextuelle.',
      closeModal:
        'Fermer. Ferme la fenêtre contextuelle de confirmation de paiement',
      back: 'Retour à la réservation.',
    },
  },
  sellSeatsError: {
    title: "Nous n'avons pas pu traiter votre paiement",
    notice:
      'Vous avez épuisé toutes les tentatives de traitement du paiement. Veuillez contacter notre ',
    back: 'Retour à la réservation',
  },
  seats: {
    types: {
      [SeatTypes.PREMIUM]: 'Premium',
      [SeatTypes.CONVENIENT]: 'Favorable',
      [SeatTypes.EXIT_ROW]: 'Siège de sortie',
      [SeatTypes.REGULAR]: 'Régulier',
      [SeatTypes.ECONOMY_EXTRA]: 'Economy Extra',
      [SeatTypes.BUSINESS]: 'Affaires',
      [SeatTypes.DREAM_ROW]: 'Lit',
    },
    descriptionWithType: 'Siège {type}',
  },
  paytmentsError: {
    title: "Nous n'avons pas pu traiter votre paiement",
    redemptionTitle: "Nous n'avons pas pu traiter votre paiement",
    notice: "Une erreur s'est produite lors du traitement de votre paiement",
    redemptionNotice:
      "Désolé, quelque chose s'est mal passé en essayant de racheter vos milles.",
    message: 'Veuillez réessayer à un autre moment',
    redemptionMessage: 'Veuillez réessayer ultérieurement.',
    goToMyTrips: 'Retour à Mes Voyages',
    redemptionGoToMyTrips: 'Retour à Mes Voyages',
    WCAG: {
      title: "Nous n'avons pas pu traiter votre paiement",
      back: 'Bouton. Retour à Mes Voyages',
    },
  },
  invalidAccount: {
    title: 'Connectez-vous avec le compte correct pour continuer',
    message1:
      'Nous avons détecté que vous vous êtes connecté avec un compte différent de celui que vous avez utilisé pour effectuer cette réservation avec des milles. Veuillez vous connecter avec le compte correct ',
    message2: " pour finaliser l'achat.",
    button: 'Se connecter avec un autre compte',
  },
  differentAccountRedemption: {
    title: 'Connectez-vous avec le compte correct pour continuer',
    message1:
      'Nous avons détecté que vous vous êtes connecté avec un compte différent de celui que vous avez utilisé pour effectuer cette réservation avec des milles. Veuillez vous connecter avec le compte correct ',
    message2: ' pour continuer.',
    defaultMessage:
      "Nous avons détecté que vous vous êtes connecté avec un compte différent de celui que vous avez utilisé pour effectuer cette réservation avec des milles. Veuillez vous connecter avec le bon numéro de compte pour finaliser l'achat.",
    CTA: 'Se connecter avec un autre compte',
  },
  modalBackOrigin: {
    modalTitle: 'Souhaitez-vous quitter cette page ?',
    modalBody:
      "Vous n'avez pas encore effectué le paiement. Si vous revenez en arrière maintenant, vous n'achèverez pas l'achat, et votre sélection ne sera pas confirmée.",
    stayPageCTA: 'Rester sur cette page',
    backOriginCTA: 'Quitter cette page',
    backOriginTitleWCAG:
      "Fenêtre contextuelle. Êtes-vous sûr de vouloir quitter cette page ? Si vous revenez en arrière maintenant, vous n'achèverez pas l'achat, et votre sélection ne sera pas confirmée.",
    stayPageButonWCAG:
      'Bouton, appuyez sur Entrée pour rester sur cette page et continuer le paiement.',
    backOriginButonWCAG: 'Bouton, appuyez sur Entrée pour quitter la page',
  },
  rebooking: {
    newSrtOnboardingModal: {
      title:
        "Nous avons apporté des modifications à l'itinéraire de ce voyage. Votre action est requise.",
      subtitle:
        "Veuillez vérifier le nouvel itinéraire ; vous aurez la possibilité d'accepter les modifications ou de modifier vos dates de voyage.",
      step1: {
        title: 'Étape 1',
        subtitle: "Vérifier l'itinéraire de voyage.",
      },
      step2: {
        title: 'Étape 2',
        subtitle: "Accepter ou modifier l'itinéraire.",
      },
      step3: {
        title: 'Étape 3',
        subtitle: 'Continuer vers Mes Voyages pour confirmer.',
      },
      cta: 'Continuer',
    },
    screenTitle:
      'Action requise : accepter ou modifier le nouvel itinéraire de voyage',
    pageDescription:
      'L\'itinéraire de votre prochain voyage à {destination} a été modifié. Nous vous avons réacheminé vers la meilleure alternative de voyage disponible. Si ce nouvel itinéraire convient à vos projets, veuillez choisir l\'option "Cet itinéraire me convient". Sinon, vous pouvez modifier vos dates de voyage en utilisant l\'option "Modifier l\'itinéraire"',
    newItinerary: 'Nouvel itinéraire',
    noChanges: 'Pas de modifications',
    buttons: {
      itineraryOK: 'Cet itinéraire me convient',
      modifyItinerary: "Modifier l'itinéraire",
      acceptItinerary: 'Accepter le nouvel itinéraire',
      seeFlightOptions: 'Voir les options de vol',
    },
    stackableAlert: {
      title:
        'Avez-vous demandé un service spécial ? Lisez attentivement cette information.',
      description: `###### Si vous aviez déjà demandé un service spécial (bagages spéciaux, animaux de compagnie à bord, assistance spéciale ou similaires), vous devrez contacter notre {link} pour transférer ces services à vos nouveaux vols.`,
      linkDescription: 'Centre de Réservation',
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    },
    search: {
      title: 'Vers {destination}',
      info: 'Changer les dates de voyage',
      datePickLabel:
        'Choisissez une nouvelle date pour votre vol vers {destination}',
      dateLabel: 'Date de départ',
      flightOptions: 'Voir les options de vol',
      notModifyInfo:
        'Si vous ne souhaitez pas modifier ce vol, vous pouvez passer au suivant.',
      notModifyFlight: 'Ne pas modifier ce vol',
      availableFlights: 'Vols disponibles',
      previousDay: 'Jour précédent',
      nextDay: 'Jour suivant',
      flightAssigned: 'Vol actuel',
      error: {
        info: "Oups ! Une erreur s'est produite lors de la recherche des vols.",
        suggestion: 'Veuillez réessayer.',
        tryAgainBtn: 'Réessayer',
      },
      notFound: {
        info: "Oups ! Aucun vol n'a été trouvé pour cette date",
        suggestion: 'Essayez de sélectionner une autre date.',
      },
      pickFlightBtn: 'Sélectionner ce vol',
      checkinFlightChanges: {
        header: {
          title: 'Votre voyage vers {destination}',
          subtitle: "Modifier l'itinéraire",
        },
        title: 'Vols disponibles',
        subtitle:
          'Sélectionnez le jour pour voir les vols disponibles pour votre modification.',
        error: {
          200: {
            title: 'Pas de vols disponibles à cette date',
            suggestion: 'Veuillez réessayer en sélectionnant une autre date',
          },
          201: {
            title: 'Pas de vols disponibles à cette date',
            suggestion: 'Veuillez réessayer en sélectionnant une autre date',
          },
          202: {
            title: 'Pas de vols disponibles à cette date',
            suggestion: 'Veuillez réessayer en sélectionnant une autre date',
          },
          203: {
            title: 'Pas de vols disponibles à cette date',
            suggestion: 'Veuillez réessayer en sélectionnant une autre date',
          },
          204: {
            title:
              'Désolé, quelque chose a échoué lors du chargement des vols disponibles',
            suggestion: 'Veuillez réessayer plus tard',
          },
        },
        seatmapPreviewBtn: 'Voir les sièges',
        outboundFlight: 'Vol aller',
        selectFlightBtn: 'Sélectionner ce vol',
        odNonStop: 'Sans escale',
      },
    },
    confirmationModal: {
      title: 'Votre réservation a été confirmée.',
      description:
        'Nous avons modifié votre réservation avec succès. Vous pouvez consulter les détails de la réservation sur la page suivante',
      continueBtn: 'Continuer vers ma réservation',
      segmentAlert:
        "Nous avons confirmé avec succès certains des vols de votre réservation ; cependant, d'autres vols nécessitent une attention spéciale de l'un de nos agents de service. Veuillez contacter notre centre d'appels pour obtenir de l'assistance.",
    },
    // TODO: Remove acceptItineraryModal translations on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
    acceptItineraryModal: {
      title: 'Souhaitez-vous confirmer cet itinéraire ?',
      subtitle:
        'En appuyant sur "Confirmer le nouvel itinéraire", nous procéderons à modifier votre réservation.',
      descriptionBitModal:
        "N'oubliez pas de sélectionner à nouveau vos sièges et tout autre service spécial que vous avez acheté.",
      continueBtnOld: "Confirmer l'itinéraire",
      continueBtn: 'Continuer et sélectionner des sièges.',
      descriptionOld:
        'En appuyant sur "Confirmer l\'itinéraire", vous acceptez le nouvel itinéraire de voyage que Copa Airlines vous a attribué.',
      description:
        'En appuyant sur "Continuer", nous procéderons à modifier votre réservation. Après la modification, vous serez dirigé vers le plan de sièges pour sélectionner vos sièges.',
      cancelBtn: 'Annuler',
      cancelBtnBitModal: 'Revenir',
      WCAG: {
        continueBtn: 'Appuyez ici pour confirmer votre itinéraire.',
        cancelBtn: "Appuyez ici pour annuler et revenir à l'écran précédent.",
        title: 'Titre en attente',
      },
    },
    cancelBanner: {
      title:
        'Aucune des options ne convient à vos projets de voyage ? Annulez maintenant et voyagez plus tard',
      description:
        "Si l'itinéraire proposé ou les options de vol ne correspondent pas à vos projets de voyage, vous pouvez annuler votre itinéraire et conserver la valeur de votre billet pour voyager plus tard. Lorsque vous serez prêt, il vous suffira de nous contacter pour choisir un nouvel itinéraire.",
      cta: {
        text: "Voir d'autres options",
        link: '{copa_url}/fr-gs/soutien-au-client/echange-et-remboursement/',
      },
    },
    nonRevenue: {
      title: 'Ceci est votre itinéraire actuel',
      subtitle:
        'À travers cette page, vous pouvez modifier les vols de votre réservation NR.',
      pills: {
        standby: "En liste d'attente",
        confirmed: 'Confirmé',
        inTransit: 'En transit',
      },
    },
    ovs: {
      screenTitle:
        'Vous avez des projets flexibles ? Vous pouvez changer de vol sans frais supplémentaires.',
      pageDescription:
        'Si vous êtes intéressé, vous pourrez modifier votre itinéraire via ce site sans frais supplémentaires.',
      btnSearch: 'Rechercher des vols',
      disclaimerText:
        'Quelques éléments à prendre en compte avant de modifier votre itinéraire',
      disclaimerItem1:
        "Vous pouvez modifier les dates de voyage jusqu'à 14 jours avant ou après la date de voyage d'origine.",
      disclaimerItem2:
        'Lors de la modification des vols, vous devrez sélectionner à nouveau des sièges. Si vous avez déjà acheté des sièges, vous pourrez sélectionner des sièges similaires sur vos nouveaux vols en fonction de leur disponibilité.',
      disclaimerItem3:
        "Vous ne pouvez effectuer qu'une seule modification de votre itinéraire.",
      disclaimerItem4:
        "Lors de la modification, assurez-vous d'adapter vos projets de voyage au nouvel itinéraire que vous choisirez.",
    },
    minor02: {
      screenTitle: 'Votre itinéraire a été ajusté',
      pageDescription:
        'Des ajustements mineurs ont été nécessaires pour votre itinéraire à destination de {destination}. Veuillez vérifier les horaires de vos vols.',
    },
    srtRedemption: {
      screenTitle: 'Ceci est votre itinéraire actuel',
      pageDescription:
        'À travers cette page, vous pouvez modifier les vols de votre réservation.',
      disclaimerText:
        'Quelques éléments à prendre en compte avant de modifier votre itinéraire',
      disclaimerAllPassengers:
        "Les modifications s'appliqueront à tous les passagers de cette réservation.",
      disclaimerSelectedSeats:
        'Si vous avez déjà sélectionné des sièges, vous devrez les sélectionner à nouveau après la modification. Vous pourrez choisir des sièges similaires sur votre nouveau vol en fonction de leur disponibilité.',
      disclaimerChanges:
        "Les changements peuvent être effectués jusqu'à un an à compter de la date d'achat.",
      disclaimerBenefits:
        "La modification n'altérera pas les avantages et attributs originaux de la tarification (par ex. la franchise de bagages).",
      disclaimerDateRestrictions:
        'Les modifications de voyage sont soumises à des restrictions, de sorte que certaines dates peuvent ne pas être disponibles.',
      disclaimerConfirmation:
        "Vos vols actuels ne changeront pas tant que vous n'aurez pas confirmé votre nouvel itinéraire ultérieurement sur cette page.",
      newItineraryTitle: 'Ceci est votre nouvel itinéraire',
      newItinerarySubTitle:
        'Veuillez vérifier les informations avant de continuer.',
      newItineraryHeader: "Modifier l'itinéraire",
      newItineraryCTAConfirm: "Confirmer l'itinéraire",
    },
    checkinFlightChanges: {
      screenTitle: 'Ceci est votre itinéraire actuel',
      pageDescription:
        'À travers cette page, vous pouvez modifier les vols de votre réservation qui sont disponibles pour effectuer le Check-In. ',
      disclaimerText:
        "Avant de modifier l'itinéraire, il est important de savoir :",
      disclaimerAllPassengers:
        "Une seule modification peut être effectuée et s'appliquera à tous les passagers de la réservation.",
      disclaimerSelectedSeats:
        "Après la modification, vous devrez sélectionner à nouveau les sièges. Si vous aviez déjà sélectionné des sièges, il est possible qu'ils ne soient plus disponibles après la modification.",
      disclaimerChanges:
        "Certains services spéciaux précédemment sélectionnés peuvent ne pas être disponibles sur le nouveau vol, car ils doivent être réservés à l'avance.",
      disclaimerSSR:
        'Si vous avez acheté des services auprès de tiers (assurance voyage ou location de voiture, par exemple), vous devrez contacter directement les fournisseurs pour vérifier la disponibilité des services sur le nouveau vol.',
      disclaimerAll: 'Voir toutes les conditions et modalités',
      disclaimerAllLink:
        '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in/',
      checkoutPage: {
        title: 'Vérifiez et confirmez votre nouvel itinéraire de voyage',
        description:
          'Vous avez choisi un nouvel itinéraire pour votre prochain voyage à destination de {destination}. Veuillez vérifier attentivement votre itinéraire avant de continuer.',
        errorToast:
          "Désolé, nous n'avons pas pu mettre à jour votre itinéraire. Veuillez réessayer.",
        pills: {
          newItinerary: 'Nouvel itinéraire',
          noChanges: 'Pas de changement',
        },
        orginalItinerary: "Voir l'itinéraire d'origine",
        modifyButton: 'Modifier la sélection',
        confirmButton: 'Confirmer le nouvel itinéraire',
        modal: {
          title: 'Souhaitez-vous confirmer cet itinéraire ?',
          description1:
            'En appuyant sur "Confirmer le nouvel itinéraire", nous procéderons à modifier votre réservation.',
          description2:
            "N'oubliez pas de sélectionner à nouveau vos sièges et tout autre service spécial que vous avez acheté.",
          staroverButton: 'Revenir',
          confirmButton: 'Confirmer le nouvel itinéraire',
        },
      },
      // TODO: Remove confirmationPage translation on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
      confirmationPage: {
        title: 'Votre nouvel itinéraire a été confirmé.',
        modalTitle: 'Votre modification a été confirmée !',
        description:
          'Vous pouvez consulter les détails de votre réservation sur la page suivante.',
        modalSubTitle:
          'Vous pouvez consulter les détails de votre voyage dans la section Mes voyages de Copa.com et dans notre application mobile.',
        modalDescriptionOld:
          'Vous pouvez consulter les détails de votre voyage dans la section Mes voyages sur Copa.com et dans notre application mobile.',
        modalDescription:
          'Après la modification, vous serez dirigé vers le plan de sièges pour sélectionner vos sièges.',
        continueButton: 'Continuer',
        modalContinueButtonOld: 'Continuer avec ma réservation',
        modalContinueButton: 'Sélectionner des sièges',
      },
      wcag: {
        mainPageDescription:
          'Ceci est votre itinéraire actuel. À travers cette page, vous pouvez modifier les vols de votre réservation qui sont disponibles pour effectuer le Check-In. ',
        searchPageMainDescription:
          'Voici les vols disponibles. Vous devrez sélectionner le jour où vous souhaitez voyager pour obtenir les vols disponibles et effectuer le changement.',
        flightAppliesToChange: 'Ce vol peut être modifié.',
        flightDoesNotAppliesToChange: 'Ce vol ne peut pas être modifié.',
        termsAndConditionLink:
          "Rediriger pour voir toutes les conditions générales qui s'appliquent dans un nouvel onglet.",
        btnAvaliableFlights: 'Appuyez pour voir les vols disponibles',
        searchPageDescription:
          'Voici les vols disponibles. Vous devrez sélectionner le jour où vous souhaitez voyager pour obtenir les vols disponibles et effectuer le changement.',
        btnSelectDays: 'Appuyez pour sélectionner cette date',
        btnViewSeats:
          "Une nouvelle page s'ouvrira pour vérifier la disponibilité des sièges sur ce vol",
        btnSelectFlight: 'Appuyez pour sélectionner ce vol',
        flightNewItineraryDescription: 'Ceci est le nouvel itinéraire',
        flightNoChangesDescription:
          "Ceci est l'itinéraire qui n'a pas été modifié",
        originalItineraryCollapsed:
          "Appuyez pour développer la section de votre itinéraire d'origine",
        originalItineraryExpanded:
          "Appuyez pour réduire la section de votre itinéraire d'origine",
        btnModifySelection:
          'Appuyez si vous souhaitez modifier votre sélection',
        btnConfirmItinerary: 'Appuyez pour confirmer votre nouvel itinéraire',
        btnReturnToFlow:
          'Appuyez pour continuer. Vous serez redirigé vers la page principale de {redirectionPage}',
        redirectionPage: 'Mes Voyages',
        errorReservationCenterLink:
          "Rediriger vers la page du centre de réservations. La page s'ouvrira dans un nouvel onglet",
      },
    },
    srtOVS: {
      disclaimers: {
        allPassengers:
          "La modification s'appliquera à tous les passagers de la réservation.",
        selectedSeats:
          'Après avoir effectué la modification de la réservation, il sera nécessaire de sélectionner à nouveau les sièges. Si vous aviez déjà choisi ' +
          "des sièges, il est possible qu'ils ne soient plus disponibles après la modification.",
        SSR:
          'Certains services spéciaux précédemment sélectionnés peuvent ne pas être disponibles sur le nouveau vol,' +
          "car ils doivent être demandés à l'avance.",
        thirdPartiesServices:
          'Si vous avez acheté des services auprès de tiers (assurance voyage ou location de voiture, par exemple),' +
          'vous devrez contacter directement les fournisseurs pour vérifier la disponibilité des services sur le nouveau vol.',
      },
      termsAndConditions: {
        text: 'Voir toutes les conditions et modalités.',
        link: '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in-ovs/',
      },
    },
    odInfo: {
      flyover: `{stopsNumber} {stopsNumber, plural,
        one { escale}
        other { escales}
      }`,
    },
  },
  rebook: {
    header: {
      infoText: 'Votre voyage vers {destination}',
      title: "Accepter l'itinéraire ou Modifier la réservation",
      ovsAndNrTitle: "Modifier l'itinéraire",
      minor02Title: "Changement d'itinéraire",
      checkinFlightChangesTitle: "Modifier l'itinéraire",
    },
    subHeader: 'Informations importantes sur votre voyage',
    dateTrip: 'Dates de voyage',
    reservationCode: 'Code de réservation',
    subTitle:
      "Veuillez consulter l'itinéraire précédent avant de choisir l'une des options suivantes.",
    information:
      "En raison des restrictions de vol et des exigences de voyage en vigueur dans de nombreuses de nos destinations, nous avons dû apporter des modifications à plusieurs de nos itinéraires de voyage. En conséquence, l'itinéraire de votre prochain vol a été modifié.",
    itinerary: 'Itinéraire de voyage mis à jour',
    itinerarySubTitle:
      "Ceci est votre itinéraire mis à jour. S'il convient à vos projets de voyage, veuillez cliquer sur Accepter cet itinéraire en bas de la page.",
    finalNote:
      "En cliquant sur Accepter cet itinéraire, je confirme mon accord pour la modification de l'itinéraire proposée par Copa Airlines. Si l'itinéraire proposé ne convient pas à vos projets de voyage, veuillez cliquer sur Modifier la réservation pour rechercher des alternatives de voyage.",
    finalNoteNewItinerary:
      'En cliquant sur Confirmer la nouvelle réservation, je confirme que la nouvelle réservation convient à mes projets de voyage.',
    modifyItinerary: 'Modifier la réservation',
    acceptItinerary: 'Accepter cet itinéraire',
    errorMessage:
      "Désolé, nous n'avons pas pu mettre à jour votre itinéraire. Veuillez réessayer.",
    titleNotAllowed:
      'Désolé, au moins une condition de votre réservation rend impossible la modification de votre vol via cette application.',
    messageNotAllowed: {
      start:
        'Veuillez visiter Mes voyages pour vérifier les options de modification ou contactez notre ',
      linkText: 'Centre de réservation',
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
      end: ' pour traiter la modification.',
    },
    options: "Voir d'autres options",
    newItineraryHeader: 'Modifier la réservation',
    newIntineraryTitle: 'Vérifier votre sélection',
    newIntinerarySubTitle:
      'Veuillez vérifier les informations avant de continuer.',
    oldIntinerary: "Voir l'itinéraire précédent",
    startOver: 'Recommencer',
    confirmItinerary: 'Confirmer la nouvelle réservation',
    messageConfirmItinerary:
      'Nous avons modifié avec succès votre itinéraire de voyage.',
    messageErrorItinerary:
      "Désolé, nous n'avons pas pu mettre à jour votre itinéraire. Veuillez réessayer.",
    messageItineraryNoModify:
      'Vous n\'avez apporté aucune modification à votre itinéraire. Sélectionnez "Recommencer" pour effectuer des modifications ou sélectionnez "Confirmer la nouvelle réservation" pour continuer avec cet itinéraire.',
    redemption: {
      notElegibleTitle:
        'Désolé, nous ne pouvons pas traiter votre modification.',
      notElegibleMessage:
        "Il s'agit d'une version limitée qui ne traite pas les modifications à moins de 7 jours du vol. Pour effectuer votre modification, veuillez nous contacter sur notre",
      notElegibleLinkDesc: 'Centre de services ConnectMiles.',
      notElegibleLink: '{copa_url}/fr-gs/connectmiles',
      notElegibleButton: 'Retour à Mes voyages',
    },
  },
  confirmContactInfo: {
    title: 'Indiquez-nous comment vous préférez être contacté.',
    description:
      'En nous fournissant vos coordonnées, nous pourrons vous tenir informé des changements ou autres mises à jour importantes de votre itinéraire. Vous pouvez modifier ces informations à tout moment.',
    privacyDisclaimer:
      'Nous utiliserons ces données uniquement pour vous contacter concernant ce voyage.',
    modal: {
      confirmBtn: 'Confirmer les coordonnées de contact',
    },
    form: {
      currentEmail: 'Adresse e-mail',
      email: {
        label: 'Adresse e-mail (obligatoire)',
        placeholder: 'Entrez une adresse e-mail',
        recommendation:
          "Nous vous recommandons d'utiliser une adresse e-mail personnelle.",
      },
      phone: {
        addLabel: 'Ajouter un numéro de téléphone',
        label: 'Numéro de téléphone',
        placeholder: 'Entrez un numéro',
      },
      countryCode: {
        placeholder: 'Code pays',
      },
      cancelBtn: 'Annuler',
      editEmailBtn: "Modifier l'e-mail",
    },
  },
  GDPRBanner: {
    text: 'Sur notre site web, copa.com, nous utilisons des cookies pour assurer le bon fonctionnement de cette page et améliorer votre expérience. Si vous décidez de continuer à naviguer, nous considérons que vous acceptez la politique de cookies qui fait partie de notre ',
    link: '{copa_url}/fr-gs/legal/termes-et-conditions/politique-de-confidentialite/',
    linkText: 'Politique de Confidentialité.',
    ctaButton: 'Accepter',
    ctaWCAGButton:
      'Message important. Sur notre site web, copa.com, nous utilisons des cookies pour assurer le bon fonctionnement de cette page et améliorer votre expérience. Si vous décidez de continuer à naviguer, nous considérons que vous acceptez la politique de cookies qui fait partie de notre Politique de Confidentialité. Appuyez sur Entrée pour accepter.',
  },
  directChannelAlert: {
    directDescription:
      "Merci d'avoir acheté directement avec Copa Airlines, vous pourrez maintenant profiter de meilleurs prix à la fois pour les sièges et les bagages.",
    indirectDescription:
      "Cette réservation a été effectuée via un système de réservation tiers. Pour obtenir de meilleurs tarifs pour les billets, les sièges et/ou les bagages, nous vous invitons à effectuer votre prochain achat directement auprès de Copa Airlines ou par l'intermédiaire d'une agence de voyage ayant un accès direct.",
  },
  directChannelPill: {
    directDescription: 'Achat Direct',
    indirectDescription: 'Achat Externe',
  },
  CiamLoginBox: {
    title: 'Connectez-vous pour continuer.',
    description:
      'Vous devez vous connecter avec le compte que vous avez initialement utilisé pour effectuer cette réservation.',
    loginBtn: 'Se connecter',
    wcagBtn: 'Appuyez pour vous connecter',
  },
  srt: {
    flight: {
      layover: 'Escale:',
      freeChargeLabel: 'Changements gratuits',
    },
    notEligibleError: {
      title: 'À l’heure actuelle, nous ne pouvons pas traiter votre changement',
      subtitle:
        'Veuillez réessayer plus tard ou contacter notre Centre de Réservation pour le changement.',
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
      cta: 'Retourner',
    },
    calendarServiceError: {
      title:
        'Désolé, un problème est survenu en essayant de modifier votre itinéraire.',
      subtitle:
        "Veuillez réessayer plus tard ou contacter notre centre d'appels pour le changement.",
      cta: "Allez au centre d'appel",
      link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
    },
    navBackBtnWCAG: 'Appuyez pour revenir à Mes Voyages',
    wrongAccountModal: {
      title: 'Pour continuer, connectez-vous avec le compte correct',
      subtitle:
        'Ce compte est différent de celui que vous avez utilisé pour effectuer cette réservation avec des milles. Veuillez vous connecter avec le compte correct',
      cta: 'Se connecter',
    },
    availableFlightsErrors: {
      201: {
        title: 'Pas de vols disponibles à cette date',
        suggestion: 'Veuillez réessayer en sélectionnant une autre date',
      },
      202: {
        title: 'Pas de vols disponibles à cette date',
        suggestion: 'Veuillez réessayer en sélectionnant une autre date',
      },
      203: {
        title: 'Pas de vols disponibles à cette date',
        suggestion: 'Veuillez réessayer en sélectionnant une autre date',
      },
      204: {
        title:
          'Désolé, quelque chose a échoué lors du chargement des vols disponibles',
        suggestion: 'Veuillez réessayer plus tard',
      },
    },
    sc: {
      default: {
        onboardingPage: {
          navTitle: "Modifier l'itinéraire",
          acceptItinerary: {
            title: 'Ceci est votre itinéraire actuel',
            subtitle: `Vous pouvez modifier les vols de votre réservation via cette page.`,
            modifyBtn: "Modifier l'itinéraire",
            acceptBtn: 'Cet itinéraire me convient',
          },
          confirmDefaultItineraryModal: {
            title: 'Souhaitez-vous confirmer cet itinéraire ?',
            subtitle:
              'En appuyant sur "Continuer", nous procéderons à modifier votre réservation. ',
            seatmapDescription:
              'Après la modification, vous serez dirigé vers le plan de sièges pour sélectionner vos sièges.',
            oldDescription:
              "N'oubliez pas de sélectionner à nouveau vos sièges et tout autre service spécial que vous avez acheté.",
            oldContinueBtn: "Confirmer l'itinéraire",
            seatMapContinueBtn: 'Continuer et sélectionner des sièges.',
            cancelBtn: 'Retourner',
            WCAG: {
              continueBtn: 'Appuyez ici pour confirmer votre itinéraire.',
              cancelBtn:
                "Appuyez ici pour annuler et revenir à l'écran précédent.",
              title: 'Titre en attente',
            },
          },
        },
        resultSearchPage: {
          availableFlightSection: {
            nextCTA: 'Lendemain',
            previousCTA: 'Veille',
            subtitle: 'Sélectionnez le vol de votre choix :',
            title: 'Vols disponibles',
          },
          dateSection: {
            ctaText: 'Voir les options de vol',
            title: 'Choisissez une nouvelle date',
          },
          flightSection: {
            buttonText: 'Ne pas modifier ce vol',
            subtitle: '{date} · Vers {destination}',
            title: 'Rechercher des solutions de rechange pour ce voyage',
          },
          header: {
            title: "Modifier l'itinéraire",
          },
        },
        checkout: {
          confirmationModal: {
            title: 'Votre changement a été confirmé!',
            subtitle:
              'Vous pouvez consulter les détails de votre voyage dans la section Mes voyages de Copa.com et dans notre application mobile.',
            oldDescription:
              'Vous pouvez consulter les détails de votre voyage dans la section Mes voyages sur Copa.com et dans notre application mobile.',
            seatmapDescription:
              'Ensuite, vous serez dirigé vers le plan des sièges pour sélectionner vos nouveaux sièges.',
            oldContinueButton: 'Continuer avec ma réservation',
            seatContinueButton: 'Sélectionner des sièges',
            redirectToMyTrips: 'Aller à Mes Voyages',
          },
        },
      },
      maj01: {
        onboardingPage: {
          navTitle: "Accepter ou modifier l'itinéraire",
          acceptItinerary: {
            title:
              'Action requise : accepter ou modifier le nouvel itinéraire de voyage',
            subtitle:
              'Nous vous avons réacheminé vers la meilleure alternative de voyage disponible.',
            modifyBtn: "Modifier l'itinéraire",
            acceptBtn: 'Cet itinéraire me convient',
          },
          collapsable: {
            title: "Itinéraire d'origine",
            subtitle:
              "Une fois le nouvel itinéraire confirmé, l'ancien ne sera plus valide.",
            btnOpen: 'Voir les détails',
            btnClose: 'Masquer les détails',
          },
        },
      },
      rdp: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte :',
            messages: `- ###### Vous pouvez modifier les dates de voyage jusqu'à 24 heures avant ou après l'heure de départ initiale.\n\n- ###### Lors de la modification des vols, vous devrez sélectionner à nouveau des sièges. Vous pouvez choisir les mêmes sièges ou des sièges similaires sur vos nouveaux vols, sous réserve de disponibilité et sans frais.\n\n- ###### Lors de l'enregistrement en ligne (24 heures avant le départ de votre vol), vous pouvez apporter des modifications à votre itinéraire une fois à l'aller et une fois au retour, si nécessaire.\n\n- ###### Si vous avez acheté des services supplémentaires (par exemple, une assurance voyage ou une location de voiture), veuillez contacter le fournisseur respectif pour apporter des modifications.\n\n- ###### Si vous avez acheté des services spéciaux (par exemple, une assistance en fauteuil roulant ou des repas végétariens), vous devrez les demander à nouveau auprès de nos services.`,
          },
          notEligibleError: {
            title:
              'À l’heure actuelle, nous ne pouvons pas traiter votre changement',
            subtitle:
              'Notre application accepte des changements pour les voyages avec une date de départ au moins 7 jours à l’avance. Pour modifier votre itinéraire, veuillez contacter notre Centre de services ConnectMiles.',
            link: '{copa_url}/fr-gs/connectmiles/',
            cta: 'Retour à Mes Voyages',
          },
        },
      },
      adhoc: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte :',
            messages: `- ###### Nous pouvons effectuer un seul changement et ceci s’appliquera à tous les passagers lors de la réservation.\n\n- ###### À la fin du changement de la réservation, vous devez sélectionner à nouveau les sièges. Si des sièges sont présélectionnés, ils peuvent être indisponibles pour la sélection après le changement.\n\n- ###### Certains services spéciaux présélectionnés peuvent être indisponibles sur le nouveau vol, car ils nécessitent d’être demandés à l’avance.\n\n- ###### Si des services ont été achetés auprès de tiers (p. ex., une assurance voyage ou une location de voiture), le passager doit coordonner directement après des fournisseurs la disponibilité du service sur le nouveau vol.`,
          },
          notEligibleError: {
            title:
              'En ce moment, nous ne pouvons pas traiter votre modification',
            subtitle:
              'En raison des conditions de votre réservation, nous ne pouvons pas traiter la modification via notre application. Pour modifier votre itinéraire, veuillez contacter notre Centre de Réservations.',
            link: '{copa_url}/fr-gs/connectmiles/',
            cta: 'Retour à Mes Voyages',
          },
        },
      },
      nr: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte :',
            messages: `- ###### Vous pouvez changer votre vol jusqu'à 45 minutes avant l'heure de départ initiale.\n\n- ###### Vous pouvez changer pour des vols dont l'heure de départ est d'au moins 45 minutes après l'heure de la modification.\n\n- ###### Si vous avez déjà enregistré des bagages en soute, il est possible qu'ils ne suivent pas le changement. Dans ce cas, nous vous recommandons de contacter un agent à l'aéroport pour obtenir de l'aide.\n\n- ###### Il incombe à chaque collaborateur de veiller à ce que le changement à effectuer soit correct et d'être présent à la porte d'embarquement suffisamment tôt.`,
          },
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte :',
            messages: `- ###### Une seule modification peut être effectuée et s'appliquera à tous les passagers de la réservation.\n\n- ###### Vous pouvez changer votre vol jusqu'à 14 jours avant ou après votre date de voyage initiale.\n\n- ###### Lors de la modification des vols, vous devrez sélectionner à nouveau des sièges. Vous pouvez choisir les mêmes sièges ou des sièges similaires sur vos nouveaux vols, sous réserve de disponibilité.\n\n- ###### Certains services spéciaux précédemment sélectionnés peuvent ne pas être disponibles sur le nouveau vol, car ils doivent être demandés à l'avance.\n\n- ###### Si vous avez acheté des services auprès de tiers (par exemple, une assurance voyage ou une location de voiture), le passager devra coordonner directement avec les fournisseurs la disponibilité du service sur le nouveau vol.`,
            notEligibleError: {
              title:
                'En ce moment, nous ne pouvons pas traiter votre modification',
              subtitle:
                'En raison des conditions de votre réservation, nous ne pouvons pas traiter la modification via notre application. Pour modifier votre itinéraire, veuillez contacter notre Centre de Réservations',
              link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
              cta: 'Retour à Mes Voyages',
            },
          },
        },
      },
    },
    wci: {
      default: {
        onboardingPage: {
          navTitle: "Modifier l'itinéraire",
          acceptItinerary: {
            title: 'Voici votre itinéraire actuel',
            subtitle: `Grâce à cette page, vous pouvez modifier les vols de votre réservation.`,
            ctaBtn: 'Voir vols disponibles',
          },
          itineraryCard: {
            availablePill: 'Disponible',
            notAvailablePill: 'Non Disponible',
          },
          disclaimers: {
            title: 'Quelques considérations à prendre en compte: ',
            messages: `- ###### Nous pouvons effectuer un seul changement et ceci s’appliquera à tous les passagers lors de la réservation.\n\n- ###### À la fin du changement de la réservation, vous devez sélectionner à nouveau les sièges. Si des sièges sont présélectionnés, ils peuvent être indisponibles pour la sélection après le changement.\n\n- ###### Certains services spéciaux présélectionnés peuvent être indisponibles sur le nouveau vol, car ils nécessitent d’être demandés à l’avance.\n\n- ###### Si des services ont été achetés auprès de tiers (p. ex., une assurance voyage ou une location de voiture), le passager doit coordonner directement après des fournisseurs la disponibilité du service sur le nouveau vol.`,
            allPassengers:
              'Nous pouvons effectuer un seul changement et ceci s’appliquera à tous les passagers lors de la réservation.',
            selectedSeats:
              'À la fin du changement de la réservation, vous devez sélectionner à nouveau les sièges. Si des sièges sont présélectionnés, ils peuvent être indisponibles pour la sélection après le changement.',
            SSR: 'Certains services spéciaux présélectionnés peuvent être indisponibles sur le nouveau vol, car ils nécessitent d’être demandés à l’avance.',
            thirdPartiesServices:
              'Si des services ont été achetés auprès de tiers (p. ex., une assurance voyage ou une location de voiture), le passager doit coordonner directement après des fournisseurs la disponibilité du service sur le nouveau vol.',
          },
          termsAndConditions: {
            text: 'Voir tous les termes et conditions.',
            ovsLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in-ovs/',
            cfcLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in/',
          },
          notEligibleError: {
            title:
              'À l’heure actuelle, nous ne pouvons pas traiter votre changement',
            subtitle:
              'En raison des conditions de votre réservation, nous ne pouvons pas traiter le changement par notre application. Pour modifier votre itinéraire, veuillez contacter notre Centre de Réservation',
            link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
            cta: 'Aller au Centre de Réservation',
          },
        },
        resultPage: {
          title: 'Sélectionnez un vol aller',
          description: '{date} · Vers {destination}',
          searchResult: {
            title: 'Vols disponibles',
            description: 'Sélectionnez le vol de votre choix :',
          },
          selectFlightBtn: 'Sélectionner un vol',
          viewSeatsBtn: 'Voir les sièges',
        },
        checkoutPage: {
          headerTitle: "Réviser l'itinéraire",
          title: 'Révisez votre choix',
          subtitle:
            'Assurez-vous que votre choix est correct avant de continuer',
          modifyCTA: 'Modifier mon choix',
          confirmCTA: 'Confirmer un nouvel itinéraire',
        },
        confirmationPage: {
          headerTitle: 'Page de confirmation',
          title: 'Voici votre nouvel itinéraire',
          subtitle: 'Résumé de votre nouvel itinéraire de voyage',
          confirmCTA: 'Voir ma réservation',
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte: ',
            messages: `- ###### Nous pouvons effectuer un seul changement et ceci s’appliquera à tous les passagers lors de la réservation.\n\n- ###### À la fin du changement de la réservation, vous devez sélectionner à nouveau les sièges. Si des sièges sont présélectionnés, ils peuvent être indisponibles pour la sélection après le changement.\n\n- ###### Certains services spéciaux présélectionnés peuvent être indisponibles sur le nouveau vol, car ils nécessitent d’être demandés à l’avance.\n\n- ###### Si des services ont été achetés auprès de tiers (p. ex., une assurance voyage ou une location de voiture), le passager doit coordonner directement après des fournisseurs la disponibilité du service sur le nouveau vol.`,
          },
          termsAndConditions: {
            text: 'See all the Terms and Conditions.',
            ovsLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in-ovs/',
            cfcLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in/',
          },
        },
      },
      cfc: {
        onboardingPage: {
          disclaimers: {
            title: 'Quelques considérations à prendre en compte:',
            messages: `- ###### Nous pouvons effectuer un seul changement et ceci s’appliquera à tous les passagers lors de la réservation\n\n- ###### À la fin du changement de la réservation, vous devez sélectionner à nouveau des sièges. Si des sièges sont présélectionnés, ils peuvent être indisponibles pour la sélection après le changement.\n\n- ###### Certains services spéciaux présélectionnés peuvent être indisponibles sur le nouveau vol, car ils nécessitent d’être demandés à l’avance.\n\n- ###### Si des services ont été achetés auprès de tiers (p. ex., une assurance voyage ou une location de voiture), le passager doit coordonner directement après des fournisseurs la disponibilité du service sur le nouveau vol.`,
          },
          termsAndConditions: {
            text: 'Voir tous les termes et conditions.',
            ovsLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in-ovs/',
            cfcLink:
              '{copa_url}/fr-gs/legal/termes-et-conditions/changements-check-in/',
          },
        },
      },
    },
  },
  upgrade: {
    errorPage: {
      wcag: "Vous êtes sur une page d'erreur. Cela peut être dû à une défaillance de nos services, nous vous recommandons de réessayer plus tard.",
    },
    saua: {
      errorPage: {
        title:
          'Votre itinéraire sur des vols opérés par une autre compagnie aérienne',
        subtitle:
          "Les demandes de promotion pour les vols opérés par d'autres compagnies aériennes ne sont pas disponibles pour le moment. Si vous avez des vols opérés par Copa Airlines et d'autres compagnies aériennes, vous pouvez demander un promotion uniquement pour les segments Copa Airlines via notre [Centre de Réservations]({copa_url}/fr-gs/soutien-au-client/centre-dappels/).",
        cta: 'Retourner',
        ctaWcag:
          'Appuyez pour revenir en arrière. Vous serez redirigé vers une autre page',
      },
      passengerForm: {
        title: 'Passagers',
        paxTitle: 'Passagers',
        nameField: 'Nom',
        namePlaceholder: 'Saisissez le prénom',
        lastNameField: 'Nom de famille',
        lastNamePlaceholder: 'Saisissez le nom de famille',
        eTicketField: 'Numéro de billet',
        eTicketPlaceholder: 'Saisissez le numéro du billet',
        connectMilesField: 'Numéro ConnectMiles',
        connectMilesPlaceholder: 'Entrez le numéro ConnectMiles',
        addPaxBtn: 'Ajouter un passager',
        addPaxBtnWCAG:
          'Appuyez pour ajouter un passager supplémentaire. Vous devrez compléter les informations de voyage pour chaque passager que vous ajoutez',
        removePaxBtn: 'Retiirer',
        removePaxBtnWCAG: 'Appuyez pour supprimer ce passager',
        invalidFormatMessage: 'Format invalide',
        requireMessage: 'Ce champ est obligatoire',
        minimumCharsAllowedMessage:
          'Le nombre minimum de caractères est de {number}',
      },
      onboarding: {
        pnrLabel: 'Code de réservation',
        title: 'Promotions sur les compagnies aériennes de Star Alliance',
        subtiTitle:
          "Copa Airlines vous permet d'échanger vos miles et vos bons disponibles contre un surclassement en cabine exécutive sur la compagnie aérienne de votre choix au sein du réseau Star Alliance. Pour demander cette promotion, vous devez remplir les informations suivantes:",
        incontentAlertText:
          "###### La demande de promotion s'applique à tous les passagers de la réservation et pour un maximum de deux vols.",
      },
      flightsForm: {
        title: 'Information de vol',
        flightTitle: 'Vol {number}',
        originToDestination: '{origin} vers {destination}',
        airlineLabel: 'Compagnie aérienne ',
        airlinePlaceholder: 'Sélectionnez la compagnie aérienne de votre vol',
        flightNumber: 'Numéro de vol',
        flightNumberPlaceholder: 'Entrez le numéro de vol',
        pnr: 'Numéro de réservation {airline}',
        pnrPlaceholder: 'Saisissez le numéro de réservation',
        pnrHelperText:
          "Si vous n'avez pas le numéro de réservation, contactez notre [Centre d’appels]({copa_url}fr-gs/soutien-au-client/centre-dappels/).",
        originLabel: 'Origine',
        originPlaceholder: "Sélectionnez votre ville d'origine",
        destinationLabel: 'Destination',
        destinationPlaceholder: 'Sélectionnez votre ville de destination',
        flightDate: 'Date du vol',
        flightDatePlaceholder: { day: 'JJ', month: 'MM', year: 'AAAA' },
        cabin: 'Cabine',
        cabinPlaceholder: 'Sélectionnez la cabine de votre surclassement',
        clearForm: 'Forme épurée',
        addFlight: 'Ajouter un autre vo',
        removeFlight: 'Retirer',
        airportsInlineError: 'Les champs ne peuvent pas être identiques',
        dateInlineError: 'Vous ne pouvez sélectionner que des dates futures',
        serviceError: 'Quelque chose a échoué. Essayez plus tard.',
        notFoundError: 'Auncun résultat trouvé',
        wcag: {
          clearForm:
            'Appuyez sur pour effacer le formulaire. Cela supprimera les informations de vol saisies',
          addFlight:
            "Appuyez pour ajouter un vol supplémentaire pour lequel vous souhaitez demander un surclassement. Lors de l'ajout d'un vol supplémentaire, vous devrez remplir les informations requises pour ce vol",
          removeFlight:
            'Appuyez pour supprimer ce vol de la demande de surclassement',
          autocomplete:
            'Vous devez saisir au moins 3 lettres pour rechercher un aéroport ou une ville.',
        },
      },
      generalErrorPage: {
        title: 'Nous ne pouvons pas traiter votre demande',
        subtitle:
          "Nous rencontrons actuellement des problèmes de service qui nous empêchent de traiter votre demande de surclassement. Veuillez réessayer plus tard ou contacter notre [Centre d'Appels]({copa_url}fr-gs/soutien-au-client/centre-dappels/) pour demander votre surclassement en utilisant vos miles ou certificats.",
        cta: 'Retourner',
        ctaWcag:
          'Appuyez pour revenir en arrière. Vous serez redirigé vers une autre page',
      },
      noMilesErrorPage: {
        title: 'Nous ne pouvons pas traiter votre demande',
        subtitle:
          'Pour demander une promotion, vous devez avoir des miles ou des certificats disponibles.',
        cta: 'Retour au début',
        ctaWcag:
          'Appuyez sur pour revenir au début. Vous serez redirigé vers une autre page',
        pageText:
          "Vous êtes sur une page d'erreur. Afin de demander un surclassement, le compte doit disposer de miles ou de certificats disponibles.",
      },
    },
  },
  upgradeHub: {
    header: {
      title: 'Demander une promotion',
      wcagText:
        'Appuyez pour revenir en arrière. Vous serez redirigé vers une autre page',
    },
    onboarding: {
      destinationCard: {
        flightTo: 'Vers {destination}',
      },
      loginBanner: {
        description:
          "Accédez à votre compte ConnectMiles si vous souhaitez demander un surclassement à l'aide de miles ou de certificats.",
        ctaText: 'Se Connecter',
        wcagText:
          'Appuyez pour vous connecter. Vous serez redirigé vers une autre page',
      },
      title: 'De quelle manière allez-vous demander une promotion ?',
      subtitle:
        "Copa Airlines vous offre l'occasion de demander une promotion en Classe Affaire et de profiter de ses avantages. Vous pouvez acheter votre place en payant avec de l'argent ou en utilisant vos miles ou certificats disponibles.",
      plusUpgradeCard: {
        title: "Payez avec de l'argent",
        subtitle:
          "Faites une offre avec la somme d'argent que vous êtes prêt à payer pour une promotion en Classe Affaire.*",
        cta: 'Sélectionner',
        wcagText:
          "Appuyez sur pour demander la promotion en payant avec de l'argent. Vous serez redirigé vers une autre page",
        logoTinyAlt:
          'FlyUp est le programme de Copa Airlines où vous pouvez opter pour un surclassement en classe affaires et profiter de ses avantages',
        wcagTextDisableCard:
          "L'option de demander un surclassement en classe affaires en payant avec de l'argent n'est pas disponible.",
        ariaIcon: 'Icône de Fly Up',
      },
      upgradeHub: {
        title: 'Utilisez des miles ou des certificats ConnectMiles',
        subtitle:
          'Achetez votre place en utilisant vos miles ou certificats disponibles.**',
        cta: 'Sélectionner',
        wcagText:
          'Appuyez sur pour demander le surclassement à l’aide de miles ou de certificats.',
        logoTinyAlt:
          'Vous pouvez demander un surclassement en classe affaires en utilisant vos miles ou certificats disponibles.',
        wcagTextDisableCard:
          "L'option de surclassement en classe affaires en utilisant vos miles ou certificats n'est pas disponible.",
      },
      disclaimer:
        "*Ce programme n'affecte pas les promotions gratuites accordées aux PrefersMembers de ConnectMiles. Les places restantes seront vendues, après l'octroi des promotions gratuites conformément aux règles du programme.",
      disclaimer2:
        '**Les certificats sont exclusifs aux membres Platinum et Présidentiels.',
    },
    generalError: {
      plusUpgrade:
        "Nous sommes désolés, votre réservation n'est pas éligible pour participer à ce programme.",
      title: 'Pour le moment, nous ne pouvons pas traiter votre demande',
      subTitle:
        'En raison de problèmes avec notre service, nous vous suggérons de réessayer plus tard',
      button: 'Retourner',
    },
    selectFlight: {
      title:
        'Sélectionnez les vols pour lesquels vous souhaitez demander un promotion',
      destination: {
        subtitle: 'Voici vos options de vol.',
      },
      memberCard: {
        miles: '{miles} Miles',
        expirationDate: "Date d'expiration: {date}",
        certificates: '{certificates} Certificats',
        noCertificates: 'Certificados no disponibles',
        globlalPlusRegional: '({global} Global + {regional} Regional)',
      },
      alert: {
        text:
          "- ###### La demande de promotion s'applique à tous les passagers de la réservation\n" +
          '- ###### Les sièges, valises et autres prestations supplémentaires précédemment achetées ne seront pas remboursables',
        link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
      },
      itineraryCard: {
        available: 'Sélectionner le vol',
        notAvailable: 'Pas de disponibilité',
        ariaLabelCheckboxActivated: 'Appuyez pour sélectionner ce vol',
        ariaLabelCheckboxDisabled:
          "Ce vol n'est plus disponible pour un surclassement",
      },
      pricesSection: {
        initialTitle:
          'Sélectionnez et appliquez votre mode de paiement pour voir le coût dans cette section',
        initialSubtitle:
          'Vous verrez le coût estimé de la mise à niveau avec le mode de paiement appliqué',
        title: "Résumé de l'achat",
        subtitle: 'Extras de voyage',
        departureCodeAndArrivalCode:
          'Promotion {departureCode} - {arrivalCode} ',
        adultPassengerCount:
          '{count, plural, one {# adulte} other {# adultes}}',
        childPassengerCount:
          '{count, plural, one {# enfant} other {# enfants}}',
        certificatesCount:
          '{count, plural, one {# certificat} other {# certificats}}',
        milesCount: '{count} award miles',
        regionalCertificatesCount:
          '{count, plural, one {# certificat regional} other {# certificats regional}}',
        globalCertificatesCount: '{count} global',
        subtotal: 'Total partiel',
        taxes: 'Taxes et frais',
        total: 'Total',
        ctaText: 'Demander promotion',
        ctaWcag:
          'Appuyez pour demander la promotion. Vous serez redirigé vers une autre page',
      },
      paxCardText: {
        memberText: 'ConnectMiles Member',
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Pas de numéro de voyageur fréquent',
      },
      passengerText: {
        title: 'Passagers dans la réservation',
        subtitle: 'Passagers qui seront surclassés en Classe Affaires.',
      },
      errorToast:
        "Une erreur s'est produite lors de la modification de votre sélection. Veuillez réessayer",
    },
    confirmation: {
      header: 'Confirmation',
      confirmationText: {
        title: {
          successfulUpgrade:
            'Félicitations, vous avez été promotion en Classe Affaires!',
          partialUpgrade:
            'Confirmation du promotion en Classe Affaires pour les vols suivants:',
          failedUpgrade:
            "Nous n'avons pas pu vous surclasser sur les vols sélectionnés",
        },
        subtitle: {
          successfulUpgrade:
            'Votre passage en Classe Affaires peut prendre quelques minutes pour se refléter.',
          partialUpgrade:
            'Votre passage en Classe Affaires peut prendre quelques minutes pour se refléter.',
          failedUpgrade:
            "Nous vous recommandons de réessayer plus tard ou d'appeler notre [Centre de Réservations.]({copa_url}/fr-gs/soutien-au-client/centre-dappels/)",
        },
        successfulUpgradeAlert:
          "###### N'oubliez pas de sélectionner vos sièges lorsque vous complétez la demande de surclassement.",
        successfulUpgradeAlertWCAG: "Appuyez pour supprimer l'alerte",
      },
      pillStatus: {
        successful: 'Promotion reçue',
        failed: 'Sans upgrade',
      },
      partialUpgrade: {
        title: "Certains vols n'ont pas été promotion",
        subtitle:
          "Votre demande de promotion en classe affaires n'a pas pu être traitée pour les vols suivants. Vous pouvez réessayer plus tard ou contacter notre ",
        link: '{copa_url}/fr-gs/soutien-au-client/centre-dappels/',
        linkText: 'Centre de Réservations.',
      },
      passengerText: {
        title: 'Passagers dans la réservation',
        subtitle: 'Passagers qui seront surclassés en Classe Affaires.',
      },
      paxCardText: {
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Pas de numéro de voyageur fréquent',
      },
      ctaText: {
        successfulUpgrade: 'Aller à Mes Voyages',
        partialUpgrade: 'Aller à Mes Voyages',
        failedUpgrade: 'Retour au début',
      },
      wcag: {
        pageText:
          "Vous êtes sur une page de confirmation partielle. Cela signifie que certains de vos segments de voyage ont pu être surclassés et que d’autres ne l’ont malheureusement pas été. Si vous souhaitez postuler à nouveau pour des segments qui n'ont pas pu être promus, nous vous recommandons de réessayer plus tard.",
        goBackText:
          'Appuyez pour revenir en arrière. Vous serez redirigé vers une autre page',
        errorPageDescription:
          "Vous êtes sur une page d'erreur. Cela peut être dû à une défaillance de nos services, nous vous recommandons de réessayer plus tard.",
        ctaText: {
          successfulUpgrade:
            'Appuyez pour accéder à Mes Voyages et gérer votre réservation. Vous serez redirigé vers une autre page.',
          partialUpgrade:
            'Appuyez pour accéder à Mes Voyages et gérer votre réservation. Vous serez redirigé vers une autre page.',
          failedUpgrade:
            'Appuyez pour revenir au début. Vous serez redirigé vers une autre page',
        },
      },
    },
    upgradeBox: {
      title: 'Calculez la valeur de votre promotion',
      subTitle:
        'Avec les itinéraires choisis, sélectionnez le mode de paiement avec lequel vous souhaitez obtenir votre promotion:',
      fopTitle: 'Sélectionnez le mode de paiement:',
      fopPlaceHolder: 'Procédé de paiement',
      totalCostTitle: 'Coût total:',
      totalCostPlaceHolder: 'Valeur en miles ou certificats',
      totalCostInlineHelper: 'Calcul selon votre sélection de vol',
      regional: 'regional,',
      global: 'global',
      miles: 'award miles',
      errors: {
        serviceError:
          "Nous n'avons pas pu calculer le coût total. Tente à nouveau.",
        voucherRequired:
          "Vous n'avez pas encore choisi les attestations de promotion",
        milesRequired: "Tu ñ'as pas assez de miles",
        notEnoughVouchers: "Vous n'avez pas assez de certificats.",
      },
      fopDropDown: {
        regionalVoucher: 'Certificat Regional',
        available: 'Disponible',
        globalVoucher: 'Certificat Global',
        save: 'Garder',
        error: 'Choisissez un certificat de promotion pour avancer',
        miles: 'Award miles',
        certificates: 'Certificats',
        helperText:
          'La sélection proposée peut être personnalisée selon vos préférences',
      },
      cta: {
        apply: 'Utiliser',
        reset: 'Claire',
        vouchersToRedim: 'Certificats à échanger',
      },
      banner: {
        milesSuccess:
          'Vous avez sélectionné des miles comme mode de paiement pour votre promotion en Classe Affaires',
        voucherSuccess:
          'Vous avez sélectionné des certificats comme mode de paiement pour votre promotion en Classe Affaires',
        serviceError:
          "Votre mode de paiement n'a pas pu être appliqué. Veuillez vérifier les informations et réessayer.",
      },
      upgradeBoxContainerWCAG:
        'Nesta seção você poderá escolher sua forma de pagamento, calcular o custo do seu upgrade usando esta forma e aplicá-lo. Ao aplicar sua forma de pagamento, as milhas não serão debitadas ou o certificado ainda será utilizado. Você precisará continuar no fluxo para obter a confirmação da atualização.',
      certificatesNotAvailable: 'non disponibles',
    },
    saua: {
      priceSummary: {
        ascendToArrival: 'Ascension vers {arrivalCode}',
      },
      confirmationPage: {
        header: 'Confirmation',
        title:
          'Nous avons reçu votre demande de surclassement pour les vols suivants',
        pageDescription:
          "Vous êtes sur une page de confirmation. Pour connaître le résultat de votre demande, veuillez consulter les détails de la confirmation. Selon la disponibilité, votre demande peut avoir été approuvée, partiellement approuvée ou refusée.En cas d'approbation, la mise à niveau peut prendre quelques minutes pour se refléter.",
        cases: {
          success: {
            title:
              'Nous avons reçu votre demande de surclassement pour les vols suivants',
            alertText:
              'Votre demande a été reçue avec succès. Vous recevrez bientôt une confirmation de surclassement par e-mail.',
            pillText: 'Surclassement reçu',
          },
          partial: {
            title:
              "Nous avons reçu la demande de surclassement pour l'un de vos vols.",
            alertText:
              "Nous n'avons pas pu traiter votre demande de surclassement en Classe Affaires pour l'un de vos vols. Veuillez réessayer plus tard.",
          },
          error: {
            title:
              "Nous n'avons pas pu traiter votre demande de surclassement pour les vols suivants:",
            alertText:
              "La demande de surclassement pour vos vols n'a pas pu être traitée. Veuillez réessayer plus tard.",
            pillText: 'Pas de surclassement',
          },
        },
        errorTitle:
          'Raisons pour lesquelles votre demande de surclassement a échoué :',
        errorReasons: {
          error1:
            'Le numéro de réservation ne correspond pas à la compagnie aérienne opérant le vol. Si vous ne disposez pas du numéro de réservation, veuillez contacter le Centre d´appels.',
          error2:
            'Les informations de vol sont incorrectes (ex. : date, numéro de vol, numéro de réservation ou cabine).',
          error3:
            "La classe demandée n'est pas éligible pour un surclassement ou il n'y a plus de disponibilité.",
          error4:
            'Une demande de surclassement a déjà été faite pour cette réservation.',
          error5:
            'Il n’y a pas assez de miles pour compléter la demande de surclassement.',
          error6:
            'La réservation inclut des services spéciaux qui ne peuvent pas être honorés avec un surclassement de classe (ex. : animal en cabine).',
          error7:
            'La date de départ du vol fournie est en dehors des dates disponibles pour un surclassement.',
        },
        flight: {
          title: 'Vol {number}',
          origin: "Aéroport d'origine",
          destination: 'Aéroport de destination',
          airline: 'Compagnie aérienne',
          number: 'Numéro de vol',
          date: 'Date du vol',
          cabin: 'Cabine',
        },
        passenger: {
          title: 'Passagers pour lesquels vous avez demandé une promotion:',
          subtitle:
            "Ce sont les passagers qui pourront profiter d'un siège en classe affaires.",
        },
        paxCard: {
          title: 'Billet: {eticket}',
        },
        cta: 'Retourner',
        failed:
          "Nous n'avons pas pu traiter votre demande de surclassement pour les vols suivants:",
      },
    },
  },
}
