/* eslint-disable react/state-in-constructor, react/prop-types, class-methods-use-this */
/* eslint-disable react/jsx-filename-extension, jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import 'babel-polyfill'
import 'unorm'
import { Route, Switch, withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { branch, compose, renderComponent, setPropTypes } from 'react-recompose'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { Maybe, Nothing } from 'monet'
import { head } from 'rambda'
import 'react-toastify/dist/ReactToastify.css'
import MemberMenu from './components/commons/memberMenu/memberMenu'

// @pages
import { CiamCallback } from './components/pages/ciamCallback/ciamCallback'
import FindReservationPage from './components/pages/findReservationPage/findReservationPage'
import TripHubPage from './components/pages/tripHubPage/tripHubPage'
import ErrorPageNotFound from './components/pages/errorNotFoundPage/errorNotFoundPage'
import DetailPage from './components/pages/detailPage/detailPage'
import TravelerInfo from './components/pages/travelerInfo/travelerInfo'
import RequestRefundPage from './components/pages/requestRefundPage/requestRefundPage'
import RetractPage from './components/pages/retractPage/retractPage'
import ConfirmationPageWCI from './flows/srt/flows/WCI/pages/confirmation/ConfirmationPageWCI'
import PayReservation from './components/pages/tripHubPage/payReservation/payReservation'
import ViewSeats from './components/pages/viewSeats/viewSeats.jsx'
import SrtErrorPage from './flows/srt/errorPage'
import UpgradeHubConfirmation from './flows/upgrade/upgrade-hub/confirmation/UpgradeHubConfirmation'
import SauaGeneralError from './flows/upgrade/saua/components/SauaGeneralError/SauaGeneralError'

// @commons
import ToastContainerCO from './components/commons/toastCO/toastContainerCO'
import Spinner from './components/commons/spinner/spinner'
import ModalCO from './components/commons/modal/modalCO'
import LoginForm from './components/forms/loginForm/loginForm'
import HeaderNavigation from './components/navigation/headerNavigation'
import AlertCO from './components/commons/alert/alertCO'
import AlertInfo from './components/commons/alertInfo/alertInfo'
import FooterItinerary from './components/commons/footerItinerary/footerItinerary'
import { CM_MASTERSESSION, CM_MEMBERSHIP } from './constants/cookies'

// @utils
import {
  deleteCookiesMatchingArrays,
  getCookie,
  getDomain,
  setCookie,
} from './utils/cookies'
import { linkToMyAccountCO, linkToPortalCO } from './utils/utils'
import localeData from './translations'
import { countdown } from './utils/dates'

// @actions
import { removeGlobalAlert } from './actions/globalAlert'
import {
  logInRequest,
  logOutRequest,
  profileRequest,
  sessionExpiredUpdate,
  showLoginModalResquest,
} from './actions/connectMiles'
import { loadAuthInfoFromCookies } from './actions/auth'

// @styles
import './styles/app.css'
import GdprBanner from './components/commons/gdprBanner/gdprBanner'
import { EXCLUDED_PATHS_FOR_HEADER_AND_FOOTER } from './constants/variables'
import withAuthHook from './hooks/withAuthHook'
import { getFeatureFlag } from './constants/featureFlags'
import { SrtPage } from './flows/srt/srtPage'
import ResultSearch from './flows/srt/resultSearch'
import Checkout from './flows/srt/checkout'
import ErrorPage from './components/pages/errorSystemPage/errorSystemPage'
import UpgradePage from './flows/upgrade/upgradePage'
import { EnhancedRoute } from './components/routes/EnhancedRoute'
import UpgradeHubGeneralError from './flows/upgrade/upgrade-hub/upgradeHubGeneralError'
import { UpgradeErrorPage } from './flows/upgrade/types'

const hasNotTriggeredNetworkErros = (prevValue, actValue) =>
  prevValue === undefined && prevValue !== actValue

class App extends React.Component {
  logoRef = React.createRef()

  headHolder = React.createRef()

  state = {
    showModalMemberMenu: false,
    showAlertScheduleChg: false,
    showAlertInformation: true,
    showAlertExpireTime: false,
    expireMin: 0,
    expireSec: 0,
    segmentWithScheduleChange: Nothing(),
    showRetractErrorAlert: false,
    showBanner: getGdprCookie(),
  }

  UNSAFE_componentWillMount() {
    const { expireTime } = this.props
    const timer = countdown(expireTime)
    this.setState({
      expireMin: timer.minutes,
      expireSec: timer.seconds,
    })
  }

  componentDidMount() {
    const { setAuthInfo, connectMilesMembership, connectMilesToken } =
      this.props

    setAuthInfo({
      [CM_MEMBERSHIP]: connectMilesMembership,
      [CM_MASTERSESSION]: connectMilesToken,
    })

    if (this.logoRef && this.logoRef.current) {
      setTimeout(() => {
        if (this.logoRef && this.logoRef.current) {
          this.logoRef.current.focus()
        }
      }, 0)
    }
    document.addEventListener('keydown', this.handleCloseModalFromEsc, false)
  }

  componentDidUpdate(prevProps) {
    const {
      setAuthInfo,
      connectMilesMembership,
      connectMilesToken,
      location,
      lang,
      softLangChange,
      auth,
      ciamRequestError,
    } = this.props

    if (prevProps.connectMilesMembership !== connectMilesMembership) {
      setAuthInfo({
        [CM_MEMBERSHIP]: connectMilesMembership,
        [CM_MASTERSESSION]: connectMilesToken,
      })
    }

    if (this.headHolder && this.headHolder.current) {
      setTimeout(() => {
        const element = document.getElementById('find-reservation')
        if (element) {
          deleteCookiesMatchingArrays(['pnr'])
        }
      }, 300)
    }
    const urlPathName = location.pathname.split('/')[1]
    const isTripDetailPage = urlPathName === 'trip-detail'
    const elementFooter = document.getElementById('footer-container')
    if (isTripDetailPage) {
      elementFooter.style.display = 'flex'
    } else if (elementFooter) {
      elementFooter.style.display = 'none'
    }

    this.handleTripHubResponse(prevProps)
    if (prevProps.lang !== lang && !softLangChange) {
      window.location.reload(false)
    }

    if (
      hasNotTriggeredNetworkErros(
        prevProps.ciamRequestError?.response?.status,
        ciamRequestError?.response?.status
      )
    ) {
      auth.logout({ didSessionExpired: true })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleCloseModalFromEsc, false)
  }

  handleCloseModalFromEsc = (event, _divInput) => {
    const { showModalMemberMenu } = this.state
    const { showModalLogin } = this.props
    const isEscape = event.keyCode === 27
    if (isEscape && showModalLogin) {
      this.handleToggleModalLogin(event, _divInput)
    }
    if (isEscape && showModalMemberMenu) {
      this.handleToggleModalMemberMenu(event, _divInput)
    }
    return false
  }

  handleTripHubResponse = prevProps => {
    const {
      showModalLogin,
      loginIsFetching,
      setShowModalLogin,
      connectMilesError,
    } = this.props
    let sizeError = 0
    if (connectMilesError) {
      sizeError = Object.keys(connectMilesError).length
    }
    if (!loginIsFetching && prevProps.loginIsFetching && sizeError === 0) {
      setShowModalLogin(!showModalLogin)
      document.body.classList.remove('open-modal')
    }
  }

  handleToggleModalLogin = (event, element) => {
    let hideModal = true
    if (element && element.contains(event.target)) {
      hideModal = false
    }
    const {
      showModalLogin,
      loginIsFetching,
      setShowModalLogin,
      profileIsFetching,
    } = this.props

    if (!loginIsFetching && !profileIsFetching && hideModal) {
      setShowModalLogin(!showModalLogin)
      setTimeout(() => {
        if (showModalLogin) {
          this.handleCloseModal()
        } else {
          this.handleOpenModal()
        }
      }, 0)
    }
  }

  handleToggleModalMemberMenu = (event, element) => {
    const hideModal = !(element && element.contains(event.target))
    const { showModalMemberMenu } = this.state

    if (hideModal) {
      this.setState({
        showModalMemberMenu: !showModalMemberMenu,
      })

      setTimeout(() => {
        if (showModalMemberMenu) {
          this.handleCloseModal()
        } else {
          this.handleOpenModal()
        }
      }, 0)
    }
  }

  handleOpenModal = () => {
    const { connectMilesToken } = this.props
    const loginBoxForm = document.getElementById('login')
    const memberMenu = document.getElementById('member-menu')
    const modal = isMobile
      ? document.getElementById('modal-overlay')
      : memberMenu || loginBoxForm
    document.body.classList.add('open-modal')
    this.focusedElementBeforeModal = document.activeElement
    const focusableElementsString =
      'a[href], input:not([disabled]), button:not([disabled]), [tabindex="0"]'
    let focusableElements = modal.querySelectorAll(focusableElementsString)
    focusableElements = Array.prototype.slice.call(focusableElements)
    let firstModalElement
    if (connectMilesToken) {
      firstModalElement = isMobile
        ? focusableElements[0]
        : memberMenu.getElementsByTagName('button')[0]
    } else {
      firstModalElement = isMobile
        ? focusableElements[0]
        : loginBoxForm.getElementsByTagName('input')[0]
    }
    const lastModalElement = focusableElements[focusableElements.length - 1]
    firstModalElement.focus()

    modal.addEventListener('keydown', e => {
      if (e.keyCode === 9) {
        if (e.shiftKey) {
          if (document.activeElement === firstModalElement) {
            e.preventDefault()
            lastModalElement.focus()
          }
        } else if (document.activeElement === lastModalElement) {
          e.preventDefault()
          firstModalElement.focus()
          e.preventDefault()
        }
      }
    })
    firstModalElement.focus()
  }

  handleCloseModal = () => {
    const { location } = this.props
    if (location.pathname.split('/')[1] !== '') {
      document.body.classList.remove('open-modal')
    }
    if (this.focusedElementBeforeModal) {
      this.focusedElementBeforeModal.focus()
    }
  }

  handleModalLogOut = ({
    connectMilesToken,
    connectMilesMembership,
    logout,
    logOutAction,
  }) => {
    // TODO: Clean after 100% rollout of FF IS_CIAM_ON
    if (getFeatureFlag('IS_CIAM_ON')) {
      logout()
    } else {
      logOutAction({
        sessionToken: connectMilesToken,
        username: connectMilesMembership,
      })
    }
  }

  handleShowAlertScheduleChg = affectedSegments => {
    const isMultiple = affectedSegments.length > 1
    this.setState({
      showAlertScheduleChg: affectedSegments.length > 0,
      segmentWithScheduleChange: isMultiple
        ? Nothing()
        : Maybe.fromNull(head(affectedSegments)),
    })
  }

  handleHiddenAlertInformation = () => {
    this.setState({
      showAlertInformation: false,
    })
  }

  handleHiddenAlertExpireTime = () => {
    this.setState({
      showAlertExpireTime: false,
    })
  }

  handleHiddenAlertInvalidToken = () => {
    const { sessionExpired, setSessionExpiredAlert } = this.props
    setSessionExpiredAlert(!sessionExpired)
  }

  handleExpirationAlertChange = ({ expireSec, expireMin }) => {
    this.setState({
      expireSec,
      expireMin,
    })
  }

  handleExpirationAlertVisibility = isVisible => {
    this.setState({ showAlertExpireTime: isVisible })
  }

  handleRetractErrorAlert = error => {
    this.setState({
      showRetractErrorAlert: error,
    })
  }

  acceptCookies = () => {
    this.setState({
      showBanner: false,
    })
    setCookie('gdprcm', true, 1825, getDomain())
  }

  validateRoutePath = urlPath => {
    const {
      flight,
      flight: { revenue },
    } = this.props
    if (urlPath === 'rebooking') {
      if (flight.pnr !== '' && !revenue.isNonRevenue) {
        return true
      }
      return false
    }
    if (
      urlPath !== 'retract' &&
      urlPath !== 'traveler-info' &&
      urlPath !== 'request-refund'
    ) {
      return true
    }
    return false
  }

  render() {
    const {
      lang,
      location,
      globalAlert,
      hideGlobalAlert,
      spinner,
      connectMilesProfile,
      loginAction,
      loginIsFetching,
      profileIsFetching,
      connectMilesError,
      deviceOrigin,
      showModalLogin,
      sessionExpired,
      connectMilesToken,
      connectMilesMembership,
      auth: { logout },
      logOutAction,
    } = this.props
    const {
      showModalMemberMenu,
      showAlertScheduleChg,
      showAlertInformation,
      showAlertExpireTime,
      expireSec,
      expireMin,
      segmentWithScheduleChange,
      showRetractErrorAlert,
      showBanner,
    } = this.state
    const urlPathName = location.pathname.split('/')[1]
    const isTripDetailPage = urlPathName === 'trip-detail'
    const showBetaAlert = false
    const showComponent =
      !EXCLUDED_PATHS_FOR_HEADER_AND_FOOTER.includes(urlPathName)
    const isEmptyConnectMilesProfile = isEmpty(connectMilesProfile)
    const messages = localeData[lang] || localeData.en

    if (globalAlert.error) {
      setTimeout(() => hideGlobalAlert(), 10000)
    }
    if (urlPathName === '') {
      document.body.classList.add('open-modal')
    } else {
      document.body.classList.remove('open-modal')
    }
    const isDisplayedScheduleAlert = showAlertScheduleChg && isTripDetailPage
    return (
      <div>
        <IntlProvider locale={lang} messages={messages}>
          <WithIntl>
            {formatMessage => (
              <div>
                <div ref={this.headHolder} className="head-holder">
                  {showAlertInformation && showBetaAlert && (
                    <AlertInfo
                      id="alert-info"
                      onClickClose={this.handleHiddenAlertInformation}
                    >
                      <p>
                        <FormattedMessage id="findReservation.informationAlert" />
                        <a href="mailto:CopaMytrips@copaair.com">
                          <FormattedMessage id="findReservation.linkInformationAlert" />
                        </a>
                      </p>
                    </AlertInfo>
                  )}
                  {showAlertExpireTime && (
                    <AlertInfo
                      id="alert-info"
                      className="secundary-alert"
                      onClickClose={this.handleHiddenAlertExpireTime}
                    >
                      <p>
                        <FormattedMessage
                          id="shoppingCart.barraTitle"
                          values={{
                            time: `${expireMin}:${expireSec}`,
                          }}
                        />
                      </p>
                    </AlertInfo>
                  )}
                  {urlPathName === 'retract' && (
                    <AlertCO
                      type="danger"
                      id="retract-alert"
                      isDisplayed={showRetractErrorAlert}
                      position="anchored"
                      onClickClose={() =>
                        this.setState({ showRetractErrorAlert: false })
                      }
                    >
                      <p style={{ 'text-align': 'left' }}>
                        <FormattedMessage id="retract.error.alert.start" />
                        <a
                          href={formatMessage(
                            {
                              id: 'retract.error.alert.link',
                            },
                            { copa_url: process.env.REACT_APP_COPA_URL }
                          )}
                        >
                          <FormattedMessage id="retract.error.alert.linkText" />
                        </a>
                        <FormattedMessage id="retract.error.alert.end" />
                      </p>
                    </AlertCO>
                  )}
                  {globalAlert.message && (
                    <AlertCO
                      type={globalAlert.alertType}
                      id="global-alert"
                      position={globalAlert.position}
                      isDisplayed={globalAlert.error}
                      onClickClose={hideGlobalAlert}
                    >
                      {globalAlert.message}
                    </AlertCO>
                  )}

                  <AlertCO
                    type="info"
                    id="schedule-alert"
                    isDisplayed={isDisplayedScheduleAlert}
                    position="anchored"
                    buttonBar={false}
                    clockBar={!isMobile}
                  >
                    {segmentWithScheduleChange
                      .map(seg => (
                        <>
                          <FormattedMessage
                            id="alertScheduleChanges.descriptionSing.start"
                            values={{
                              from: seg.departureCode,
                              to: seg.arrivalCode,
                            }}
                          />
                          <a
                            href={formatMessage(
                              {
                                id: 'alertScheduleChanges.link',
                              },
                              { copa_url: process.env.REACT_APP_COPA_URL }
                            )}
                          >
                            <FormattedMessage id="alertScheduleChanges.linkText" />
                          </a>
                          <FormattedMessage id="alertScheduleChanges.descriptionSing.end" />
                        </>
                      ))
                      .getOrElse(
                        <>
                          <FormattedMessage id="alertScheduleChanges.descriptionPlu.start" />
                          <a
                            href={formatMessage(
                              {
                                id: 'alertScheduleChanges.link',
                              },
                              { copa_url: process.env.REACT_APP_COPA_URL }
                            )}
                          >
                            <FormattedMessage id="alertScheduleChanges.linkText" />
                          </a>
                          <FormattedMessage id="alertScheduleChanges.descriptionPlu.end" />
                        </>
                      )}
                  </AlertCO>

                  {showComponent && (
                    <>
                      {!deviceOrigin && (
                        <>
                          {sessionExpired && (
                            <AlertInfo
                              id="alert-info"
                              className="third-alert"
                              onClickClose={this.handleHiddenAlertInvalidToken}
                            >
                              <FormattedMessage id="findReservation.errorInvalidToken" />
                            </AlertInfo>
                          )}
                          <HeaderNavigation
                            idName="header-content"
                            idLogo="header-logo"
                            idLinkLogo="header-logo-link"
                            idBtnLogin="header-btn-login"
                            idBtnSignup="header-btn-singup"
                            idBtnMemberMenu="header-btn-member-menu"
                            logoRef={this.logoRef}
                            handleOpenModalLogin={this.handleToggleModalLogin}
                            handleOpenModalMemberMenu={
                              this.handleToggleModalMemberMenu
                            }
                            linkToPortal={linkToPortalCO()}
                            logOutAction={() =>
                              this.handleModalLogOut({
                                connectMilesToken,
                                connectMilesMembership,
                                logout,
                                logOutAction,
                              })
                            }
                          />
                        </>
                      )}
                      <Helmet htmlAttributes={{ lang }} />
                    </>
                  )}
                </div>
                <Switch>
                  <Route
                    location={location}
                    path="/"
                    exact
                    handleToggleModal={this.handleToggleModalLogin}
                    component={FindReservationPage}
                  />
                  <Route
                    location={location}
                    path="/trip-hub"
                    exact
                    component={TripHubPage}
                  />
                  <Route
                    location={location}
                    path="/trip-detail/:number?/:surname?/:language?"
                    exact
                    render={() => (
                      <DetailPage
                        openAlertScheduleChg={this.handleShowAlertScheduleChg}
                        notifyExpirationAlertTimeChange={
                          this.handleExpirationAlertChange
                        }
                        notifyExpirationAlertVisibilityChange={
                          this.handleExpirationAlertVisibility
                        }
                        expirationAlertVisible={this.state.showAlertExpireTime}
                        showBanner={showBanner}
                      />
                    )}
                  />
                  <Route
                    location={location}
                    path="/traveler-info/:pnr/:surname/:id"
                    exact
                    component={TravelerInfo}
                  />
                  <Route
                    location={location}
                    path="/request-refund"
                    exact
                    component={RequestRefundPage}
                  />
                  <Route
                    location={location}
                    path="/retract/:pnr/:surname/:language?"
                    exact
                    render={() => (
                      <RetractPage
                        onErrorChange={this.handleRetractErrorAlert}
                      />
                    )}
                  />
                  <Route
                    location={location}
                    path="/pay-reservation/:number?/:surname?/:language?"
                    exact
                    component={PayReservation}
                  />
                  <Route
                    location={location}
                    path="/view-seats"
                    exact
                    component={ViewSeats}
                  />
                  <Route
                    location={location}
                    path="/callback"
                    exact
                    component={CiamCallback}
                  />
                  <Route
                    location={location}
                    path="/rebooking/:pnr/:surname/:language(en|es|pt|fr)"
                    exact
                    component={SrtPage}
                  />
                  <Route
                    location={location}
                    path="/rebooking/:pnr/:surname/:language/result-search"
                    exact
                    component={ResultSearch}
                  />
                  <Route
                    location={location}
                    path="/rebooking/:pnr/:surname/:language(en|es|pt|fr)/checkout"
                    exact
                    component={Checkout}
                  />
                  <Route
                    location={location}
                    path="/rebooking/:pnr/:surname/:language(en|es|pt|fr)/confirmation"
                    exact
                    component={ConfirmationPageWCI}
                  />
                  <Route
                    location={location}
                    path="/rebooking/:pnr/:surname/:language/error"
                    exact
                    component={SrtErrorPage}
                  />
                  <Route
                    location={location}
                    path="/upgrade/:pnr/:surname/:language(en|es|pt|fr)"
                    exact
                    component={UpgradePage}
                  />
                  <Route
                    location={location}
                    path="/upgrade/:pnr/:surname/:language(en|es|pt|fr)/error"
                    exact
                    render={() => (
                      <EnhancedRoute
                        ariaText="upgrade.errorPage.wcag"
                        component={<UpgradeHubGeneralError />}
                      />
                    )}
                  />
                  <Route
                    location={location}
                    path="/upgrade/:pnr/:surname/:language(en|es|pt|fr)/saua-error"
                    exact
                    render={() => (
                      <EnhancedRoute
                        ariaText="upgrade.errorPage.wcag"
                        component={
                          <SauaGeneralError type={UpgradeErrorPage.DEFAULT} />
                        }
                      />
                    )}
                  />
                  <Route
                    location={location}
                    path="/upgrade/:pnr/:surname/:language(en|es|pt|fr)/saua-error/no-miles"
                    exact
                    render={() => (
                      <EnhancedRoute
                        ariaText="upgrade.saua.noMilesErrorPage.pageText"
                        component={
                          <SauaGeneralError type={UpgradeErrorPage.NO_MILES} />
                        }
                      />
                    )}
                  />
                  <Route
                    location={location}
                    path="/upgrade-confirmation/:language(en|es|pt|fr)"
                    exact
                    render={() => (
                      <EnhancedRoute
                        ariaText="upgradeHub.confirmation.wcag.pageText"
                        component={<UpgradeHubConfirmation />}
                      />
                    )}
                  />
                  <Route component={ErrorPageNotFound} />
                </Switch>
                {this.validateRoutePath(urlPathName) && showBanner && (
                  <GdprBanner acceptCookies={this.acceptCookies} />
                )}
                <ToastContainerCO className="toast-container" />
                {spinner && <Spinner />}
                {!this.props.connectMilesToken ? (
                  <ModalCO
                    id="modal-overlay"
                    showHeader={showModalLogin}
                    showModal={showModalLogin && isEmptyConnectMilesProfile}
                    handleClose={this.handleToggleModalLogin}
                    wcagObject={{
                      id: 'loginFormWCAG.pageDescription',
                    }}
                  >
                    <LoginForm
                      id="login"
                      logInAction={loginAction}
                      isLoading={loginIsFetching || profileIsFetching}
                      loginError={connectMilesError}
                    />
                  </ModalCO>
                ) : (
                  <ModalCO
                    id="modal-overlay"
                    showHeader={
                      showModalMemberMenu && this.props.connectMilesToken
                    }
                    showModal={showModalMemberMenu}
                    handleClose={this.handleToggleModalMemberMenu}
                  >
                    <MemberMenu
                      id="member-menu"
                      linkToMyAccount={linkToMyAccountCO(lang)}
                      handleLogout={() =>
                        this.handleModalLogOut({
                          connectMilesToken,
                          connectMilesMembership,
                          logout,
                          logOutAction,
                        })
                      }
                    />
                  </ModalCO>
                )}
                {showComponent && (
                  <FooterItinerary
                    idName="footer-container"
                    idLogo="footer-logo"
                    idText="footer-text"
                  />
                )}
              </div>
            )}
          </WithIntl>
        </IntlProvider>
      </div>
    )
  }
}

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  connectMilesProfile: PropTypes.object,
  loginAction: PropTypes.func,
  loginIsFetching: PropTypes.bool,
  profileIsFetching: PropTypes.bool,
  setAuthInfo: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
    softLangChange: state.locale.soft,
    tripHub: state.tripHub,
    flight: state.flight,
    globalAlert: state.globalAlert,
    flightFetching: state.flight.fetching,
    spinner: state.generalSpinner.fetching,
    connectMilesProfile: state.connectMiles.connectMilesProfile,
    loginIsFetching: state.connectMiles.fetching,
    profileIsFetching: state.connectMiles.fetchingProfile,
    connectMilesError: state.connectMiles.error,
    deviceOrigin: getCookie('deviceOrigin') === 'true',
    connectMilesMembership: getCookie('lp_ffn'),
    connectMilesToken: getCookie('masterSessionId'),
    showModalLogin: state.connectMiles.showLoginModal,
    sessionExpired: state.connectMiles.sessionExpired,
    ciamRequestError: state.connectMiles.requestError,
  }
}

function getGdprCookie() {
  const gdprCookie = getCookie('gdprcm')
  const cookieValue = !gdprCookie || gdprCookie === 'false'
  return cookieValue
}

const WithIntl = injectIntl(({ children, intl: { formatMessage } }) =>
  children(formatMessage)
)

const setPropTypesEnhance = setPropTypes(propTypes)

const errorPage = branch(props => props.error, renderComponent(ErrorPage))

const reduxstate = connect(mapStateToProps, {
  hideGlobalAlert: removeGlobalAlert,
  loginAction: logInRequest,
  logOutAction: logOutRequest,
  getConnectMilesProfile: profileRequest,
  setShowModalLogin: showLoginModalResquest,
  setSessionExpiredAlert: sessionExpiredUpdate,
  setAuthInfo: loadAuthInfoFromCookies,
})

export default compose(
  withRouter,
  reduxstate,
  setPropTypesEnhance,
  errorPage,
  withAuthHook
)(App)
