import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import theme from '@copa/design-system-factory.theme'
import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { pageViewEventUpgraHub } from 'src/actions/analytics'
import { useHistory, useParams } from 'react-router'
import { QueryParams } from 'src/types/QueryParams'
import iconFlightError from '../../../assets/flightError.svg'
import { spacing } from '../../../utils/spacing'
import { useCustomBackNavigation } from '../../../hooks/useCustomBackNavigation'
import { useGoBackWithOrigin } from '../hooks/useGoBackToOrigin'
import { isFetchingGeneral } from '../../../actions/generalSpinner'

const upgradeHubGeneralError = () => {
  const { formatMessage } = useIntl()
  const goBack = useGoBackWithOrigin()
  const history = useHistory()
  const dispatch = useDispatch()
  const { origin } = useParams<QueryParams>()

  useCustomBackNavigation({ customAction: goBack })

  useEffect(() => {
    dispatch(isFetchingGeneral(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      pageViewEventUpgraHub({
        path: history.location.pathname,
        pageName: 'Error page',
        scenario: 'General-Error',
        referSource: origin,
      })
    )
  }, [origin, history])

  return (
    <Box>
      <Box style={{ marginBottom: spacing.roomy }}>
        <HeaderBarNavigation
          title={formatMessage({ id: 'upgradeHub.header.title' })}
          onClick={() => {
            goBack()
          }}
          wcagText={formatMessage({ id: 'upgradeHub.header.wcagText' })}
        />
      </Box>
      <TitleCombination
        avatarProps={{
          id: 'flight-avatar',
          stroke: false,
          variant: 'huge',
          // @ts-ignore
          font: iconFlightError,
        }}
        titleProps={{
          variant: 'h2',
          color: theme.palette.grey['700'],
          children: formatMessage({
            id: `upgradeHub.generalError.title`,
          }),
        }}
        descriptionProps={{
          variant: 'body1',
          color: theme.palette.grey['600'],
          children: formatMessage({
            id: `upgradeHub.generalError.subTitle`,
          }),
        }}
        buttonProps={{
          variant: 'outlinePrimaryMain',
          children: formatMessage({
            id: `upgradeHub.generalError.button`,
          }),
          'aria-label': formatMessage({ id: 'upgradeHub.header.wcagText' }),
          onClick: () => {
            goBack()
          },
        }}
      />
    </Box>
  )
}

export default upgradeHubGeneralError
