const flightFormState = {
  origin: {
    key: '',
    text: '',
  },
  destination: {
    key: '',
    text: '',
  },
  airline: '',
  number: '',
  date: '',
  cabinClass: '',
}

export default flightFormState
