import { theme } from '@copa/design-system-factory.theme'

type AvatarStyleFunction = (
  font: string,
  stroke: boolean,
  color: string,
  fontColor: string,
  inverted: boolean
) => {
  container: {}
  img: {}
  stroke: {}
  typography: {}
}

const commonStyles = (
  size: number,
  font: string,
  stroke: boolean,
  color: string,
  fontColor: string,
  inverted: boolean,
  padding: number
) => {
  const colorStroke = () => {
    if (inverted && font.length === 2) {
      return theme.palette.background.lightBlue
    }
    return font.length === 2 ? color : 'transparent'
  }

  return {
    container: {
      width: `${size}px`,
      height: `${size}px`,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: stroke
        ? `1px solid ${inverted ? theme.palette.background.lightBlue : color}`
        : '',
      borderRadius: '50%',
      padding: stroke ? `${padding}px` : '',
    },
    stroke: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorStroke,
      borderRadius: '50%',
      width: '100%',
      height: '100%',
    },
    typography: {
      color: inverted ? theme.palette.primary.main : fontColor,
    },
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  }
}

const tinyStyles = (font, stroke, color, fontColor, inverted) =>
  commonStyles(32, font, stroke, color, fontColor, inverted, 2)
const petitStyles = (font, stroke, color, fontColor, inverted) =>
  commonStyles(48, font, stroke, color, fontColor, inverted, 2)
const normalStyles = (font, stroke, color, fontColor, inverted) =>
  commonStyles(56, font, stroke, color, fontColor, inverted, 3)
const bigStyles = (font, stroke, color, fontColor, inverted) =>
  commonStyles(72, font, stroke, color, fontColor, inverted, 3)
const hugeStyles = (font, stroke, color, fontColor, inverted) =>
  commonStyles(96, font, stroke, color, fontColor, inverted, 3)

const avatarStylesMap: Record<string, AvatarStyleFunction> = {
  tiny: tinyStyles,
  petit: petitStyles,
  normal: normalStyles,
  big: bigStyles,
  huge: hugeStyles,
}

const AvatarStylesByVariant = (
  variant: string,
  font: string,
  stroke: boolean,
  color: string,
  fontColor: string,
  inverted: boolean
) => {
  const selectedStyle = avatarStylesMap[variant] || tinyStyles
  return selectedStyle(font, stroke, color, fontColor, inverted)
}

export { AvatarStylesByVariant }
