import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useSrtTemplate } from './hook/template/useSrtTemplate'
import { flows, templates } from './flows/SC/templates/templates.enum'
import OnboardingPageSC from './flows/SC/pages/onboarding/OnboardingPageSC'
import { getLanguage } from './utils/utils'
import { setLocaleLanguage } from '../../actions/locale'
import OnboardingPageWCI from './flows/WCI/pages/onboarding/OnboardingPageWCI'
import { ScheduleChangeTemplate } from './flows/SC/templates/interfaces/schedule-change.template'
import { WCITemplate } from './flows/WCI/templates/interfaces/WCITemplate'
import { analyticsViewSrtPageEvent } from '../../actions/analytics'

export function SrtPage() {
  const { template, isEligibleToRebook, flow, showInterstitial } =
    useSrtTemplate()
  // @ts-ignore
  const { language } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const lang = getLanguage(language)
    dispatch(setLocaleLanguage(lang))
  }, [language, dispatch])

  useEffect(() => {
    if (template.name !== templates.DEFAULT) {
      dispatch(analyticsViewSrtPageEvent(template.name))
    }
  }, [template, dispatch])

  return flow === flows.SC ? (
    // @ts-ignore
    <OnboardingPageSC
      template={template as ScheduleChangeTemplate}
      isEligibleToRebook={isEligibleToRebook}
      flow={flow}
      showInterstitial={showInterstitial}
    />
  ) : (
    // @ts-ignore
    <OnboardingPageWCI
      template={template as WCITemplate}
      isEligibleToRebook={isEligibleToRebook}
      flow={flow}
      showInterstitial={showInterstitial}
    />
  )
}

export default SrtPage
