import { spacing } from '../../../../../../utils/spacing'

export const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: {
      md: spacing.petit,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: spacing.petit,
      md: spacing.minimal,
    },
    marginBottom: {
      xs: spacing.normal,
      lg: spacing.roomy,
    },
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    justifyContent: 'center',
    alignItems: 'center',
    gap: {
      xs: spacing.petit,
      sm: spacing.normal,
      lg: spacing.roomy,
    },
    alignSelf: 'stretch',
  },
  icon: {
    width: {
      xs: '48px',
      sm: '72px',
      md: '96px',
    },
    height: {
      xs: '48px',
      sm: '72px',
      md: '96px',
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.tiny,
    alignSelf: 'stretch',
    '&:not(:last-child)': {
      paddingRight: {
        lg: spacing.roomy,
      },
    },
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  stepTitle: {
    fontWeight: 600,
  },
})
