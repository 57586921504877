import { WrapperUpgradeBox } from '@copa/design-system-factory.functional-components.wrapper-upgrade-box'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CounterDropdownProps } from '@copa/design-system-factory.dropdowns.counter-dropdown'
import {
  DataUpgradeBoxModel,
  OptionProps,
  StateUpgradeBoxModel,
} from '@copa/design-system-factory.boxes.upgrade-box/types'
import { UpgradeBoxProps } from '@copa/design-system-factory.boxes.upgrade-box'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import { MilesOptionRequestData } from '@copa/design-system-factory.hooks.endpoints'
import UpBoxLogo from '../../../../assets/images/upgradeBox.svg'
import { VouchersTotal } from '../../types'
import { getUpgradeBoxEnv, isValidMemberForVouchers } from '../../utils'
import { FormatMessage } from '../../../srt/utils/types'
import { PassengersInputs } from '../../upgrade-hub/types'
import { SauaFlight } from '../../saua/components/FlightForm/FlightForm'

interface UpgradeBoxSAUAProps {
  upgradeBoxRequest?: Partial<MilesOptionRequestData>
  selectedFlights: SauaFlight[]
  passengers: PassengersInputs
  loyalty: {
    loyalLevel: string
    balance: number
  }
  vouchers: VouchersTotal | null | undefined
  isSauaFormCompleted: boolean
  onPriceResponse: (__response: DataUpgradeBoxModel) => void
  onClickReset: () => void
  formsFlights: SauaFlight[]
  formPassengers: PassengersInputs
}

export interface GetOptionsCompositionProps {
  selectedFlights: SauaFlight[]
  passengers: PassengersInputs

  loyalty: {
    loyalLevel: string
    balance: number
  }
  vouchers: VouchersTotal | null | undefined
  formatMessage: FormatMessage
  isSauaFormCompleted: boolean
}
interface FlightsAndPassengerEmptyParams {
  passengers: PassengersInputs
  selectedFlights: SauaFlight[]
}
const isFlightsAndPassengersFilled = ({
  passengers,
  selectedFlights,
}: FlightsAndPassengerEmptyParams) =>
  !(!isEmpty(passengers) && !isEmpty(selectedFlights))

export const getOptionsComposition = ({
  formatMessage,
  loyalty,
  selectedFlights,
  passengers,
  vouchers,
  isSauaFormCompleted,
}: GetOptionsCompositionProps): [OptionProps, OptionProps] => [
  {
    label: formatMessage({
      id: 'upgradeHub.upgradeBox.fopDropDown.miles',
    }),
    amount: loyalty?.balance,
    value: 'miles',
    disabled:
      !isSauaFormCompleted ||
      isFlightsAndPassengersFilled({ passengers, selectedFlights }),
    labelNotAvailable: '',
  },
  // @ts-ignore
  ...(isValidMemberForVouchers(loyalty)
    ? [
      {
        label: formatMessage({
          id: 'upgradeHub.upgradeBox.fopDropDown.certificates',
        }),
        amount:
            !vouchers?.total || vouchers?.total === 0 ? null : vouchers?.total,
        value: 'vouchers',
        disabled:
            !isSauaFormCompleted ||
            isFlightsAndPassengersFilled({ passengers, selectedFlights }) ||
            !vouchers?.total ||
            vouchers?.total === 0,
        // @ts-ignore
        labelNotAvailable: formatMessage({
          id: 'upgradeHub.upgradeBox.certificatesNotAvailable',
        }),
      },
    ]
    : []),
]

export default function UpgradeBoxSAUA({
  upgradeBoxRequest,
  selectedFlights,
  loyalty,
  vouchers,
  passengers,
  isSauaFormCompleted,
  onPriceResponse,
  onClickReset,
  formsFlights,
  formPassengers,
}: UpgradeBoxSAUAProps) {
  const { formatMessage } = useIntl()
  const [reset, setReset] = useState<boolean>(false)
  const [clickReset, setClickReset] = useState<boolean>(false)
  const [stateUpgradeBox, setStateUpgradeBox] = useState<StateUpgradeBoxModel>()
  const [shouldHideFOP, setShouldHideFOP] = useState(false)
  const [priceResponse, setPriceResponse] = useState<DataUpgradeBoxModel>()
  const [messageBanner, setMessageBanner] = useState<string>()

  // We need this effect to get correct values from variables in the component
  useEffect(() => {
    if (clickReset) {
      setReset(true)
      setStateUpgradeBox(undefined)
      setClickReset(false)
      setShouldHideFOP(false)
      setPriceResponse(undefined)
      onClickReset()
    }
  }, [clickReset, onClickReset])

  useEffect(() => {
    setReset(true)
    setStateUpgradeBox(undefined)
    setClickReset(false)
    setShouldHideFOP(false)
    setPriceResponse(undefined)
  }, [formsFlights, formPassengers])

  useEffect(() => {
    setReset(true)
  }, [selectedFlights, passengers])

  useEffect(() => {
    if (priceResponse) {
      onPriceResponse(priceResponse)
    }
  }, [priceResponse, onPriceResponse])

  const getMilesOptionResponse = useCallback(
    (response: DataUpgradeBoxModel) => {
      setPriceResponse(response)
      setShouldHideFOP(true)
    },
    [setPriceResponse, setShouldHideFOP]
  )

  useEffect(() => {
    if (priceResponse?.selectValue) {
      if (priceResponse?.selectValue === 'vouchers') {
        setMessageBanner(
          formatMessage({
            id: 'upgradeHub.upgradeBox.banner.voucherSuccess',
          })
        )
      } else {
        setMessageBanner(
          formatMessage({
            id: 'upgradeHub.upgradeBox.banner.milesSuccess',
          })
        )
      }
      setStateUpgradeBox('success')
    }
    setShouldHideFOP(false)
  }, [formatMessage, setStateUpgradeBox, setMessageBanner, priceResponse])

  const propsCounterDropdown = useMemo<CounterDropdownProps>(
    () => ({
      id: 'upgradeBoxFOP',
      counterFields: [
        {
          id: 'counter1',
          label: formatMessage({
            id: 'upgradeHub.upgradeBox.fopDropDown.regionalVoucher',
          }),
          subLabel: {
            text: formatMessage({
              id: 'upgradeHub.upgradeBox.fopDropDown.available',
            }),
            number: vouchers?.regional as number,
          },
          maxValue: vouchers?.regional as number,
          minValue: 0,
          initialValue: 0,
          handleError: (_, __) => {},
          disabled: false,
          wcagObject: {
            minusBtn: '-',
            number: '0',
            plusBtn: '+',
          },
          onChangeCounter: (_, __) => {},
        },
        {
          id: 'counter2',
          label: formatMessage({
            id: 'upgradeHub.upgradeBox.fopDropDown.globalVoucher',
          }),
          subLabel: {
            text: formatMessage({
              id: 'upgradeHub.upgradeBox.fopDropDown.available',
            }),
            number: vouchers?.global as number,
          },
          maxValue: vouchers?.global as number,
          minValue: 0,
          initialValue: 0,
          handleError: (_, __) => {},
          disabled: false,
          wcagObject: {
            minusBtn: '-',
            number: '0',
            plusBtn: '+',
          },
          onChangeCounter: (_, __) => {},
        },
      ],
      buttonSaveValues: {
        children: formatMessage({
          id: 'upgradeHub.upgradeBox.fopDropDown.save',
        }),
      },
      hasErrors: false,
      helperCounterDropdown: {
        text: formatMessage({
          id: 'upgradeHub.upgradeBox.fopDropDown.helperText',
        }),
        textError: formatMessage({
          id: 'upgradeHub.upgradeBox.fopDropDown.error',
        }),
      },
      open: false,
      // @ts-ignore
      actionRef: undefined,
      onHandleSave: _ => {},
      onChangeCounterDropdown: _ => {},
      onClose: () => {},
    }),
    [vouchers, formatMessage]
  )

  const optionsComposition = useMemo<[OptionProps, OptionProps]>(
    () =>
      getOptionsComposition({
        loyalty,
        vouchers,
        selectedFlights,
        formatMessage,
        passengers,
        isSauaFormCompleted,
      }),
    [
      loyalty,
      vouchers,
      selectedFlights,
      formatMessage,
      passengers,
      isSauaFormCompleted,
    ]
  )

  const dataUpgradeBox = useMemo<UpgradeBoxProps>(
    () => ({
      title: formatMessage({
        id: 'upgradeHub.upgradeBox.title',
      }),
      description: formatMessage({
        id: 'upgradeHub.upgradeBox.subTitle',
      }),
      avatarProps: {
        variant: 'big',
        id: 'avatar-upgrade-box',
        // @ts-ignore
        font: UpBoxLogo,
      },
      inputProps: {
        label: formatMessage({
          id: 'upgradeHub.upgradeBox.totalCostTitle',
        }),
        helperText: formatMessage({
          id: 'upgradeHub.upgradeBox.totalCostInlineHelper',
        }),
        id: 'upgradeBoxTotalCost',
        placeholder: formatMessage({
          id: 'upgradeHub.upgradeBox.totalCostPlaceHolder',
        }),
        handleChange: _ => {},
        value: '',
        textErrorService: formatMessage({
          id: 'upgradeHub.upgradeBox.errors.serviceError',
        }),
        textErrorVouchersRequired: formatMessage({
          id: 'upgradeHub.upgradeBox.errors.voucherRequired',
        }),
        textErrorMilesRequired: formatMessage({
          id: 'upgradeHub.upgradeBox.errors.milesRequired',
        }),
        textErrorNotEnoughMiles: formatMessage({
          id: 'upgradeHub.upgradeBox.errors.milesRequired',
        }),
        textErrorNotEnoughVouchers: formatMessage({
          id: 'upgradeHub.upgradeBox.errors.notEnoughVouchers',
        }),
      },
      inputPlaceholderProps: {
        miles: {
          label: formatMessage({
            id: 'upgradeHub.upgradeBox.miles',
          }),
        },
        vouchers: {
          label: formatMessage({
            id: 'upgradeHub.upgradeBox.fopDropDown.certificates',
          }),
          regionalLabel: formatMessage({
            id: 'upgradeHub.upgradeBox.regional',
          }),
          globalLabel: formatMessage({
            id: 'upgradeHub.upgradeBox.global',
          }),
        },
      },
      selectProps: {
        label: formatMessage({
          id: 'upgradeHub.upgradeBox.fopTitle',
        }),
        helperText: '',
        id: 'name',
        placeholder: formatMessage({
          id: 'upgradeHub.upgradeBox.fopPlaceHolder',
        }),
        defaultValue: '',
        onChange: () => {},
        disabled: shouldHideFOP,
      },
      options: optionsComposition,
      primaryButtonProps: {
        primaryText: formatMessage({
          id: 'upgradeHub.upgradeBox.cta.vouchersToRedim',
        }),
        onClick: () => {},
      },
      secondaryButtonProps: {
        primaryText: formatMessage({
          id: 'upgradeHub.upgradeBox.cta.apply',
        }),
        secondaryText: formatMessage({
          id: 'upgradeHub.upgradeBox.cta.reset',
        }),
        disabledOption: true,
        onClick: () => {},
        onClickClear: () => setClickReset(true),
      },
      counterDropdownProps: propsCounterDropdown,
      stateUpgradeBox,
      alertMessage: {
        text: messageBanner || '',
      },
      resetComponent: reset,
      handleSaveValues: getMilesOptionResponse,
      onChangeValuesUpgradeBox: _ => {},
    }),
    [
      messageBanner,
      formatMessage,
      optionsComposition,
      propsCounterDropdown,
      reset,
      shouldHideFOP,
      stateUpgradeBox,
      getMilesOptionResponse,
    ]
  )

  const shouldResetUpgradeBox = (resetUpgrade: boolean) => {
    setReset(resetUpgrade)
  }

  return (
    <WrapperUpgradeBox
      apiBaseUrl={process.env.REACT_APP_API_URL || ''}
      // @ts-ignore
      dataRequest={upgradeBoxRequest}
      envRequest={getUpgradeBoxEnv()}
      upgradeBoxProps={dataUpgradeBox}
      resetComponent={reset ? shouldResetUpgradeBox : undefined}
    />
  )
}
