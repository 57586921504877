import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { ButtonCo } from '@copa/design-system-factory.button'
import { useIntl } from 'react-intl'
import moment, { Moment } from 'moment'
import { styles } from './styles'
import { ArrowLeft } from '../../../../assets/images/srt/availableFlightCarousel/ArrowLeft'
import { ArrowRight } from '../../../../assets/images/srt/availableFlightCarousel/ArrowRight'
import { getDateIncrement } from '../../../../utils/dates'
import AvailableFlightError from './AvailableFlightError/AvailableFlightError'
import { RebookingCalendarDates } from '../../utils/types'
import { Leg } from '../../interfaces/leg.interface'
import { createAvailableItineraryCard } from '../../utils/flights'
import { AVAILABILITY_DATE_FORMAT } from '../../../../constants/variables'
import { flows } from '../../flows/SC/templates/templates.enum'

interface AvailableFlightCarouseTemplate {
  nextCTA: string
  previousCTA: string
  subtitle: string
  title: string
}

interface AvailableFlightCarouselProps {
  ods: []
  dateLimits: RebookingCalendarDates
  selectedDate: Moment | string
  // @ts-ignore
  onPageChange: (_date) => void
  statusCode: number | string
  currentLeg: Leg
  // @ts-ignore
  selectAvailableFlight: (_leg) => void
  template: AvailableFlightCarouseTemplate
}

export default function AvailableFlightCarouse({
  ods = [],
  selectedDate,
  dateLimits: { startCalendarDate, endCalendarDate },
  onPageChange,
  statusCode,
  currentLeg,
  selectAvailableFlight,
  template: { nextCTA, previousCTA, title, subtitle },
}: AvailableFlightCarouselProps) {
  const classes = styles()
  const { formatMessage } = useIntl()

  const shouldDisablePreviousDate = () =>
    selectedDate === moment(startCalendarDate).format(AVAILABILITY_DATE_FORMAT)
  const shouldDisableNextDate = () =>
    selectedDate === moment(endCalendarDate).format(AVAILABILITY_DATE_FORMAT)
  // @ts-ignore
  const navigateToDate = navDirection => {
    onPageChange(
      moment(getDateIncrement(selectedDate, navDirection)).format(
        AVAILABILITY_DATE_FORMAT
      )
    )
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.header}>
        <Box sx={classes.title}>
          <Typography color="grey.800" variant="h2">
            {formatMessage({ id: title })}
          </Typography>
          <Typography color="grey.600" variant="body1">
            {formatMessage({ id: subtitle })}
          </Typography>
        </Box>

        <Box sx={classes.carouselActions}>
          <Box sx={classes.actionBtn}>
            <ButtonCo
              justIcon
              aria-label="button-previous-date"
              variant="solidPrimaryMain"
              rounded
              disabled={shouldDisablePreviousDate()}
              onClick={() => navigateToDate(-1)}
            >
              {/* @ts-ignore */}
              <ArrowLeft />
            </ButtonCo>

            <Typography color="grey.600" variant="body1">
              {formatMessage({ id: previousCTA })}
            </Typography>
          </Box>

          <Box sx={classes.actionBtn}>
            <Typography color="grey.600" variant="body1">
              {formatMessage({ id: nextCTA })}
            </Typography>

            <ButtonCo
              justIcon
              aria-label="button-next-date"
              variant="solidPrimaryMain"
              rounded
              disabled={shouldDisableNextDate()}
              onClick={() => navigateToDate(1)}
            >
              {/* @ts-ignore */}
              <ArrowRight />
            </ButtonCo>
          </Box>
        </Box>
      </Box>

      {statusCode !== 200 ? (
        <AvailableFlightError errorCode={statusCode} />
      ) : (
        <Box sx={classes.flights}>
          {createAvailableItineraryCard(ods, currentLeg, {
            formatMessage,
            shouldShowDelayInfo: false,
            selectAvailableFlight,
            flow: flows.SC,
          })}
        </Box>
      )}
    </Box>
  )
}
