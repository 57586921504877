/* eslint-disable react-hooks/exhaustive-deps,import/prefer-default-export */
import React, { useEffect, useMemo, useState } from 'react'
import { Box, ClickAwayListener } from '@mui/material'
import { Layout } from '@copa/design-system-factory.layout'
import { Typography } from '@copa/design-system-factory.typography'
import { InputBig } from '@copa/design-system-factory.input-big'
import { ButtonCo } from '@copa/design-system-factory.button'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { isEmpty } from 'rambda'
import { HeaderNavSrt } from '../../../../components/HeaderNavSrt'
import { createItineraryCards } from '../../../../utils/flights'
import { MyTripsState } from '../../../../../../types/state'
import { Calendar } from '../../../../../../assets/images/srt/calendar'
import useBitBreakPoints from '../../../../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'
import Title from '../../../WCI/pages/resultSearch/components/Title/Title'
import AvailableFlightCarousel from '../../../../components/availableFlightCarousel/availableFlightCarousel'
import { SrtPages } from '../../../../hook/navigate/types'
import { Leg, Segment } from '../../../../interfaces/leg.interface'
import {
  completeOD,
  equivalentODs,
} from '../../../../../../utils/rebooking/availability'
import { notFlownLegs } from '../../../../../../selectors/destination'
import searchAvailability from '../../../../utils/searchAvailability'
import { AVAILABILITY_DATE_FORMAT } from '../../../../../../constants/variables'
import useSrtNavigate from '../../../../hook/navigate/useSrtNavigate'
import getAvailableFlightsCalendar from './getAvailableFlightsCalendar'
import { SetSessionStateType } from '../../../../utils/types'
import { scTemplatesByTypeModify } from '../../templates/templates.map'
import { flows } from '../../templates/templates.enum'

interface ResultSearchSCProps {
  setSessionState?: SetSessionStateType
}

export default function ResultSearchSC({
  setSessionState,
}: ResultSearchSCProps) {
  const { mergeState, redirectTo, getState } = useSrtNavigate({
    setSessionState,
  })

  const sessionState = getState()

  const {
    flight,
    odIndex,
    flow,
    selectedOds,
    templateName,
    rebookingCalendarODs: odsCalendar,
  } = sessionState
  // @ts-ignore
  const { name, resultSearch } = scTemplatesByTypeModify[templateName]

  const rebookingCalendarODs = useMemo(
    () =>
      getAvailableFlightsCalendar({
        selectedOds,
        rebookingCalendarODs: odsCalendar,
      }),
    [odsCalendar, selectedOds]
  )
  // @ts-ignore
  const getRebookingDateLimits = (legIndex, dateName) =>
    // @ts-ignore
    moment(rebookingCalendarODs[legIndex].rebookingCalendar[dateName]).format(
      AVAILABILITY_DATE_FORMAT
    )

  const [legIndex, setLegIndex] = useState(odIndex)

  const currentFlightDate = useMemo(
    () =>
      // @ts-ignore
      moment(flight.flights[legIndex].flightDate).format(
        AVAILABILITY_DATE_FORMAT
      ),
    [flight, legIndex]
  )

  const selectedFlightDate = useMemo(
    () =>
      moment(
        // @ts-ignore
        selectedOds[legIndex - 1]?.segments[0]?.flightEstimatedDate
      ).format(AVAILABILITY_DATE_FORMAT),
    [selectedOds, legIndex]
  )

  const initialCalendarDate = useMemo(
    // @ts-ignore
    () => (selectedOds[legIndex - 1] ? selectedFlightDate : currentFlightDate),
    [selectedOds, currentFlightDate, selectedFlightDate, legIndex]
  )

  const startCalendarDate = getRebookingDateLimits(
    legIndex,
    'startCalendarDate'
  )
  const endCalendarDate = getRebookingDateLimits(legIndex, 'endCalendarDate')

  const { data: searchAvailabilityResponse, error: searchAvailabilityError } =
    useSelector((state: MyTripsState) => state.rebooking.search)
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const [calendarDate, setCalendarDate] = useState(initialCalendarDate)
  const [selectedDate, setSelectedDate] = useState(initialCalendarDate)
  const [shouldChangeCalendarDate, setShouldChangeCalendarDate] = useState<boolean>(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const manageCalendarValue = (newValue = calendarDate) => {
    setCalendarDate(moment(newValue).format(AVAILABILITY_DATE_FORMAT))
  }
  const manageCalendar = () => setShowCalendar(true)
  const handleClickAway = () => setShowCalendar(false)

  const legs: Leg[] = notFlownLegs({ flight })
  // @ts-ignore
  const currentLeg: Leg = legs[legIndex]

  const filteredAvailableODs = useMemo(() => {
    const availableODs = searchAvailabilityResponse
      ? searchAvailabilityResponse.availableODs
      : []

    return availableODs
      ? availableODs
      // @ts-ignore
        .map(od => (currentLeg ? completeOD(currentLeg, od) : od))
      // @ts-ignore
        .filter(od => (currentLeg ? equivalentODs(od, currentLeg) : false))
      : []
  }, [searchAvailabilityResponse, currentLeg])

  const availabilityStatusCode = useMemo(
    () =>
      searchAvailabilityResponse ? searchAvailabilityResponse.statusCode : 200,
    [searchAvailabilityResponse]
  )

  const dateLimits = useMemo(
    // @ts-ignore
    () => rebookingCalendarODs[legIndex].rebookingCalendar,
    [legIndex, rebookingCalendarODs]
  )

  useEffect(() => {
    if (searchAvailabilityError) {
      redirectTo(SrtPages.ERROR)
    }
  }, [searchAvailabilityError, redirectTo])
  // @ts-ignore
  const changeSelectedDate = date => {
    setSelectedDate(date)
    setCalendarDate(date)
    searchAvailability({
      // @ts-ignore
      flight,
      typeModify: name,
      // @ts-ignore
      flow,
      dispatch,
      departureDate: date,
      legIndex,
    })
  }

  useEffect(() => {
    if (shouldChangeCalendarDate) {
      changeSelectedDate(calendarDate)
    }
    setShouldChangeCalendarDate(false)
  },[
    calendarDate,
    shouldChangeCalendarDate,
    changeSelectedDate
  ])

  // @ts-ignore
  const shouldDisableDate = (day, minDate, maxDate) => {
    const current = moment(day)
    return current.isBefore(moment(minDate)) || current.isAfter(moment(maxDate))
  }

  const goToNextLeg = (selectedLeg: Segment) => {
    // @ts-ignore
    const nextLegIndex = legIndex + 1

    setLegIndex(nextLegIndex)
    setCalendarDate(
      selectedLeg?.flightEstimatedDate ??
        // @ts-ignore
        flight.flights[nextLegIndex].flightDate
    )
    setSelectedDate(
      selectedLeg?.flightEstimatedDate ??
        // @ts-ignore
        flight.flights[nextLegIndex].flightDate
    )

    mergeState({ odIndex: nextLegIndex })
  }

  const continueSelection = (selectedLeg: Segment = {} as Segment) => {
    if (legs.length - 1 !== legIndex) {
      goToNextLeg(selectedLeg)
    } else {
      redirectTo(SrtPages.CHECKOUT)
    }
  }
  // @ts-ignore
  const addLegToState = ({ leg, isModified }) => {
    const { selectedOds: selectedLegsTmp } = getState()
    const currentSelectedLegs = selectedLegsTmp ?? []
    currentSelectedLegs.push({
      ...leg,
      modified: isModified,
    })
    mergeState({ selectedOds: currentSelectedLegs })
    // @ts-ignore
    continueSelection(selectedLegsTmp[legIndex].segments[0])
  }

  const doNotModify = () => {
    // @ts-ignore
    addLegToState({ leg: legs[legIndex], isModified: false })
    continueSelection()
  }
  // @ts-ignore
  const selectFlight = leg => {
    addLegToState({ leg, isModified: true })
  }

  const currentFlights = createItineraryCards(
    // @ts-ignore
    flight,
    {
      shouldShowExtraInfo: true,
      formatMessage,
      doNotModifyFlightBtn: {
        text: formatMessage({
          id: resultSearch.flightSection.buttonText,
        }),
        onClick: doNotModify,
        ariaLabel: formatMessage({
          id: resultSearch.flightSection.buttonText,
        }),
      },
      shouldShowStatus: false,
      flow: flows.SC,
    },
    0
  )

  return (
    <>
      <HeaderNavSrt template={resultSearch.header} />
      <Box sx={classes.container}>
        <Layout>
          {/* @ts-ignore */}
          <Title currentLeg={currentLeg} title={resultSearch.flightSection} />
          <Box sx={classes.currentFlightContainer}>
            {/* @ts-ignore */}
            {currentFlights[legIndex]}
          </Box>
          <Box>
            <Box>
              <Typography variant="h3" color="primary.main">
                {formatMessage({
                  id: resultSearch.dateSection.title,
                })}
              </Typography>
            </Box>
            <Box sx={classes.actionsContainer}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={classes.calendar}>
                  <Box onClick={manageCalendar} onKeyDown={manageCalendar}>
                    <InputBig
                      id="datePicker"
                      placeholder="Input"
                      value={moment(calendarDate).format('MM/DD/YYYY')}
                      inputLogo={Calendar}
                    />
                  </Box>

                  {showCalendar && (
                    <Box sx={classes.dateWrapper}>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale="en"
                      >
                        <DateCalendar
                          defaultValue={moment(calendarDate)}
                          shouldDisableDate={day =>
                            shouldDisableDate(
                              day,
                              startCalendarDate,
                              endCalendarDate
                            )
                          }
                          onChange={newValue => {
                            manageCalendarValue(newValue.toString())
                            setShowCalendar(false)
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>

              <Box sx={classes.buttonContainer}>
                <ButtonCo
                  sx={classes.button}
                  variant="solidPrimaryMain"
                  rounded
                  size={breakpoints.isXS ? 'large' : 'default'}
                  onClick={() => setShouldChangeCalendarDate(true)}
                >
                  {formatMessage({
                    id: resultSearch.dateSection.ctaText,
                  })}
                </ButtonCo>
              </Box>
            </Box>
          </Box>
        </Layout>
        {(!isEmpty(filteredAvailableODs) || availabilityStatusCode !== 200) && (
          <Box sx={classes.availableFlightsContainer}>
            <Layout>
              <AvailableFlightCarousel
                currentLeg={currentLeg}
                ods={filteredAvailableODs}
                selectedDate={selectedDate}
                dateLimits={dateLimits}
                onPageChange={changeSelectedDate}
                statusCode={availabilityStatusCode}
                selectAvailableFlight={selectFlight}
                template={resultSearch.availableFlightSection}
              />
            </Layout>
          </Box>
        )}
      </Box>
    </>
  )
}
