import moment from 'moment/moment'
import { flows, templates } from '../flows/SC/templates/templates.enum'
import { Flight } from '../interfaces/flight.interface'
import {
  AVAILABILITY_DATE_FORMAT,
  AVAILABILITY_END_DATE_FORMAT,
  DAYS_MOMENT_OPTION,
} from '../../../constants/variables'
import { PASSENGER_TYPE_INF } from '../../../constants/passengers'
import { Leg } from '../interfaces/leg.interface'
import { notFlownLegs } from '../../../selectors/destination.js'
import { rebookingAvailabilityRequest } from '../../../actions/rebooking'

interface SearchAvailabilityParams {
  flight: Flight | undefined
  typeModify: templates | string | undefined
  flow: flows | undefined
  // @ts-ignore
  dispatch: (_action) => void
  departureDate?: string
  legIndex?: number
}

export default function searchAvailability({
  flight,
  typeModify,
  flow,
  dispatch,
  departureDate,
  legIndex = 0,
}: SearchAvailabilityParams): void {
  const legs: Leg[] = notFlownLegs({ flight })
  const currentLeg: Leg = legs[legIndex]
  const selectedFlightKey = currentLeg.destination.segment.flightKey

  const selectedOd = flight?.ods.find(od =>
    od.flightsKeys.find(flightKey => flightKey === selectedFlightKey)
  )
  const requestTypeModify = typeModify === templates.FC ? 'FC' : typeModify
  // TODO: Unified this function with the one on src/utils/rebooking/acceptItinerary.js (findTypeModify)
  function determineTypeModify() {
    let updatedTypeModify = requestTypeModify
    if (flow === flows.WCI) {
      // @ts-ignore
      updatedTypeModify = `${requestTypeModify}-${selectedOd.odType}`
    }
    if (requestTypeModify === templates.NR) {
      updatedTypeModify = flight?.revenue.nonRevenueType
    }
    return updatedTypeModify
  }
  const payload = {
    date:
      moment(departureDate).format(AVAILABILITY_DATE_FORMAT) ??
      moment().format(AVAILABILITY_DATE_FORMAT),
    ...(flow === flows.WCI && {
      endDate: moment(currentLeg.segments[0].flightEstimatedDate)
        .add(1, DAYS_MOMENT_OPTION)
        .format(AVAILABILITY_END_DATE_FORMAT),
    }),
    segments: currentLeg.segments.map(segment => ({
      flightKey: segment.flightKey,
      departureCode: segment.departureCode,
      arrivalCode: segment.arrivalCode,
      classOfService: segment.classOfService,
    })),
    sizePassengers: currentLeg.segments[0].passengers.filter(
      ps => ps.typeCode !== PASSENGER_TYPE_INF
    ).length,
    typeModify: determineTypeModify(),
    pnr: flight?.pnr,
  }

  dispatch(rebookingAvailabilityRequest(payload))
}
