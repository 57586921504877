import React from 'react'
import { useIntl } from 'react-intl'
import { SeoWrapper } from './SeoWrapper'

interface EnhancedRouteProps {
  component: React.ReactElement
  ariaText: string
  ariaTextProps?: object
  idContainer?: string
  pageTitle?: string
  keywords?: string[]
}

export const EnhancedRoute: React.FC<EnhancedRouteProps> = ({
  component,
  ariaText,
  ariaTextProps = {},
  idContainer,
  pageTitle,
  keywords,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  return (
    <SeoWrapper
      ariaText={formatMessage({ id: ariaText })}
      id={idContainer ?? ''}
      title={pageTitle ?? ''}
      keywords={keywords ?? []}
      {...rest}
    >
      {React.cloneElement(component, ariaTextProps)}
    </SeoWrapper>
  )
}
