import React from 'react'
import { Box } from '@mui/material'
import { ButtonCo } from '@copa/design-system-factory.button'
import { useIntl } from 'react-intl'
import useStyles from './styles'
import useBitBreakPoints from '../../../../../../../../hooks/material/useBitBreakPoints'

export function ModifyItineraryBtn({
  // @ts-ignore
  template: { modifyBtn },
  // @ts-ignore
  onClick,
  sticky = false,
}) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)

  return (
    modifyBtn.display && (
      <Box sx={classes[sticky ? 'stickyContainer' : 'buttonsContainer']}>
        <ButtonCo
          sx={classes.button}
          variant="solidPrimaryMain"
          rounded
          size="large"
          onClick={() => onClick()}
        >
          {formatMessage({ id: modifyBtn.text })}
        </ButtonCo>
      </Box>
    )
  )
}
