import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import useBitBreakPoints from '../../../../../../../../hooks/material/useBitBreakPoints'
import { useStyles } from './styles'
import {
  getCurrentFlightDestination,
  getCurrentFlightEstimatedDepartureDate,
} from '../../../../../../utils/flights'
import { ResultPageTitleWCI } from '../../../../templates/interfaces/pages/resultSearchWCI.interface'
import { Leg } from '../../../../../../interfaces/leg.interface'

interface TitleProps {
  currentLeg: Leg
  title: ResultPageTitleWCI | undefined
}

export default function Title({ title, currentLeg }: TitleProps) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const date = useMemo(
    () => getCurrentFlightEstimatedDepartureDate(currentLeg),
    [currentLeg]
  )
  const destination = useMemo(
    () => getCurrentFlightDestination(currentLeg),
    [currentLeg]
  )
  return title?.display ? (
    <Box sx={classes.container}>
      <Typography color="primary.main" variant="h2">
        {formatMessage({ id: title.title })}
      </Typography>
      <Typography color="grey.600" variant="body2">
        {formatMessage(
          { id: title.description },
          {
            date,
            destination,
          }
        )}
      </Typography>
    </Box>
  ) : null
}
