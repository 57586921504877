import React, { useEffect, useState } from 'react'
import { Layout } from '@copa/design-system-factory.layout'
import { Interstitial } from '@copa/design-system-factory.loaders.interstitial-loading'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { HeaderNavSrt } from '../../../../components/HeaderNavSrt'
import TitleWCI from './components/titleWCI/TitleWCI'
import useStyles from './components/titleWCI/styles'
import ItineraryWCI from './components/itineraryWCI/ItineraryWCI'
import { shouldShowContent } from '../../../SC/pages/onboarding/utils/utils'
import { ModifyItineraryBtn } from '../../../SC/pages/onboarding/components/modifyItineraryBtn/ModifyItineraryBtn'
import { spacing } from '../../../../../../utils/spacing'
import useBitBreakPoints from '../../../../../../hooks/material/useBitBreakPoints'
import DestinationCard from '../../../../components/destinationCard/DestinationCard'
import { NotEligibleError } from '../../../SC/pages/onboarding/components/notEligibleError/NotEligibleError'
import { MyTripsState } from '../../../../../../types/state'
import useSrtNavigate from '../../../../hook/navigate/useSrtNavigate'
import { Flight } from '../../../../interfaces/flight.interface'
import { SrtPages } from '../../../../hook/navigate/types'
import searchAvailability from '../../../../utils/searchAvailability'
import { showErrorToast } from '../../../../../../utils/toastAlert'
import { rebookingReset } from '../../../../../../actions/rebooking'
import { WCITemplate } from '../../templates/interfaces/WCITemplate'
import { analyticsModifyItinerarySrtEvent } from '../../../../../../actions/analytics'
import { Disclaimers } from '../../../../../../components/commons/Disclaimers/Disclaimers'
import { OnboardingPageProps } from '../../../../utils/types'

function OnboardingPageWCI({
  template,
  isEligibleToRebook,
  flow,
  showInterstitial = false,
}: OnboardingPageProps<WCITemplate>) {
  const flight: Flight = useSelector((state: MyTripsState) => state.flight)
  const { data: searchAvailabilityResponse, error: searchAvailabilityError } =
    useSelector((state: MyTripsState) => state.rebooking.search)
  const {
    flightChanges: { isElegible },
  } = useSelector((state: MyTripsState) => state.rebooking)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { redirectTo } = useSrtNavigate({})
  const { isMediumOrLarge, isXsOrSmall, breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { onboarding } = template

  useEffect(() => {
    dispatch(rebookingReset())
  }, [dispatch])

  useEffect(() => {
    if (searchAvailabilityResponse && shouldRedirect) {
      redirectTo(
        SrtPages.RESULT_SEARCH,
        {
          flight,
          flow,
          templateName: template.name,
        },
        {
          replace: false,
        }
      )
    }

    if (searchAvailabilityError) {
      showErrorToast(formatMessage({ id: 'rebook.errorMessage' }))
    }
  }, [
    searchAvailabilityResponse,
    searchAvailabilityError,
    shouldRedirect,
    redirectTo,
    flight,
    flow,
    template.name,
    formatMessage,
  ])

  const modifyItinerary = () => {
    setShouldRedirect(true)
    dispatch(analyticsModifyItinerarySrtEvent(template.name))
    searchAvailability({
      flight,
      typeModify: template.name,
      flow,
      dispatch,
    })
  }

  if (showInterstitial) {
    return (
      <Interstitial
        aria-busy={showInterstitial}
        role="status"
        data-testid="srt-initial-interstitial"
      />
    )
  }
  return (
    shouldShowContent({ template, isElegible }) && (
      <>
        <HeaderNavSrt template={onboarding.header} />
        {isEligibleToRebook ? (
          <>
            <Layout>
              <Box sx={classes.destinationCardContainer}>
                {/* @ts-ignore */}
                <DestinationCard
                  template={onboarding.destinationCard}
                  flight={flight}
                />
              </Box>
              <Box>
                <TitleWCI
                  title={onboarding.pageTitle.title}
                  description={onboarding.pageTitle.description}
                />
              </Box>
              <ItineraryWCI suggestedItinerary={flight} />
              <Box>
                <Disclaimers template={{ ...onboarding.disclaimers }} />
                {isMediumOrLarge && (
                  <Box sx={{ marginBottom: spacing.spacious }}>
                    <ModifyItineraryBtn
                      onClick={modifyItinerary}
                      template={onboarding}
                    />
                  </Box>
                )}
              </Box>
            </Layout>
            {isXsOrSmall && (
              <ModifyItineraryBtn
                onClick={modifyItinerary}
                sticky
                template={onboarding}
              />
            )}
          </>
        ) : (
          <NotEligibleError template={onboarding} />
        )}
      </>
    )
  )
}

export default OnboardingPageWCI
