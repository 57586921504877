import { HTMLAttributes, useState, useMemo } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import RemarkGfm from 'remark-gfm'
import RemarkUnwrapImages from 'remark-unwrap-images'
import RehypeRaw from 'rehype-raw'

import { Link } from '@copa/design-system-factory.link'
import { Divider } from '@copa/design-system-factory.divider'
import { Table, TableProps } from '@copa/design-system-factory.table'
import { theme } from '@copa/design-system-factory.theme'
import { Typography } from '@copa/design-system-factory.typography'
import { stylesMarkdown } from './utils/styles'

import { IUnorderedListProps, LinkProps, OtherProps } from './types'
import {
  matchesSomeCustomAlignment,
  replaceString,
  validateAlignment,
} from './utils/utils'

/**
 * Importing this styles helps styling the HTML tags directly. We need this
 * because components like <ul> needs to be stylized with arrow bullets
 */
import cssStyles from './markdownStyles.module.css'

/**
 * Documentation about rule eslint-disable-next-line @typescript-eslint/no-unused-vars
 * This is because the "node" is not been used, but we cannot pass it as the rest of props
 * because is not a valid HTML property
 */

const CustomTd = (props: React.TdHTMLAttributes<HTMLElement>) => {
  return <td {...props} />
}

const CustomTh = (props: React.TdHTMLAttributes<HTMLElement>) => {
  return <th {...props} />
}

export type MarkDownProps = {
  /** Children to be rendered, it must be a string in markdown format */
  children: string
  /** Theme variant to style the table */
  isDarkTheme?: boolean
  /** Remove the text margins set by default */
  noTextMargin?: boolean
} & OtherProps

export const Markdown = ({
  children,
  isDarkTheme = false,
  noTextMargin = false,
  ...propsMarkdown
}: MarkDownProps) => {
  const [tableMarkdownState] = useState<boolean>()
  const newChildren = useMemo(() => replaceString(children), [children])
  const colors = theme.palette
  const styles = stylesMarkdown()
  const componentsMap: Components = {
    h1: ({ node }) => {
      const { children: ChildrenText } = node

      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="h1"
          variant="h1"
          sx={{
            margin: '16px 0 32px 0',
            color: isDarkTheme ? 'common.white' : 'primary.main',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    h2: ({ node }) => {
      const { children: ChildrenText } = node
      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="h2"
          variant="h2"
          sx={{
            margin: '16px 0 24px 0',
            color: isDarkTheme ? 'common.white' : 'primary.main',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    h3: ({ node }) => {
      const { children: ChildrenText } = node
      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="h3"
          variant="h3"
          sx={{
            margin: '16px 0 16px 0',
            color: isDarkTheme ? 'common.white' : 'grey.700',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    h4: ({ node }) => {
      const { children: ChildrenText } = node
      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="h4"
          variant="h4"
          sx={{
            margin: '16px 0 16px 0',
            color: isDarkTheme ? 'common.white' : 'grey.700',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    h5: ({ node }) => {
      const { children: ChildrenText } = node
      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="p"
          variant="captionLarge"
          sx={{
            margin: noTextMargin ? '0' : '0 0 24px 0',
            color: isDarkTheme ? 'common.white' : 'grey.700',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    h6: ({ node }) => {
      const { children: ChildrenText } = node
      // @ts-ignore: this is because we are not validating all the properties that comes from 'node'.
      const textValue = ChildrenText[0]?.value || ''
      let newText = textValue
      if (matchesSomeCustomAlignment(textValue)) {
        newText = textValue.substring(1)
      }
      return (
        <Typography
          component="p"
          variant="body2"
          sx={{
            margin: noTextMargin ? '0' : '0 0 24px 0',
            color: isDarkTheme ? 'common.white' : 'grey.600',
            textAlign: validateAlignment(textValue),
          }}
        >
          {newText}
        </Typography>
      )
    },
    a: ({ href, children: childrenLink, title }) => {
      let titleProp = title
      const targetProps: LinkProps = {}
      if (title?.charAt(0) === '_') {
        titleProp = title.substring(1)
        targetProps.target = '_blank'
        targetProps.rel = 'noreferrer'
      }
      return (
        <Link
          href={href || '#'}
          target={targetProps.target || '_self'}
          linkVariant={isDarkTheme ? 'primaryInvertLink' : 'primaryLink'}
          title={titleProp || ''}
          rel={targetProps.rel}
        >
          {childrenLink || ''}
        </Link>
      )
    },
    blockquote: () => {
      return (
        <Typography
          component="p"
          variant="body3"
          sx={{
            margin: '0 0 16px 0',
            color: isDarkTheme ? 'common.white' : 'grey.600',
          }}
        />
      )
    },
    hr: () => {
      return <Divider base="lightSolid" variant="fullWidth" />
    },
    img: ({ node, ...props }) => (
      <img alt="" style={styles.images} {...props} />
    ),
    p: ({ children: ChildrenP }) => {
      return (
        <Typography
          component="p"
          variant="body1"
          sx={{
            margin: noTextMargin ? '0' : '0 0 24px 0',
            color: isDarkTheme ? colors.common.white : colors.grey['600'],
          }}
        >
          {ChildrenP || ''}
        </Typography>
      )
    },
    strong: () => {
      return (
        <Typography
          component="strong"
          sx={{
            margin: '0 0 24px 0',
            color: isDarkTheme ? 'common.white' : 'grey.700',
          }}
        />
      )
    },
    li: ({ node, ...props }) => (
      <li
        style={{
          color: isDarkTheme ? colors.common.white : colors.grey['600'],
        }}
        {...props}
      />
    ),
    ol: ({ node, ...props }) => (
      <ol
        style={{
          color: !isDarkTheme ? colors.common.white : colors.grey['600'],
        }}
        {...props}
      />
    ),
    ul: ({ node, ...props }) => {
      const { variant } = props as IUnorderedListProps
      const shouldUseArrowBullet =
        variant?.toLowerCase() === 'arrowBullets'.toLowerCase()
      return (
        <ul
          className={
            shouldUseArrowBullet ? cssStyles.list_container : undefined
          }
          style={{
            color: isDarkTheme ? colors.common.white : colors.grey['600'],
            margin: noTextMargin ? '0' : '16px 0',
            padding: noTextMargin ? '0 0 0 24px' : '0 0 0 40px',
          }}
          {...props}
        />
      )
    },
    //   /**
    //    * Below is the code related to table
    //    * This part will be defined later and proper components and styled will be added
    //    */
    table: ({ node, ...props }) => {
      const { variant, style } = props as TableProps
      if (variant) {
        return <Table {...props} />
      }
      return (
        <Table
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0 1rem',
            marginBottom: '32px',
            width: '100%',
            ...style,
          }}
          {...props}
        />
      )
    },
    tbody: ({ node, ...props }) => (
      <tbody
        style={{
          color: theme.palette.grey[600],
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          textAlign: 'center',
        }}
        {...props}
      />
    ),
    td: ({ node, ...props }) => {
      const { children: tdChildren, ...tdAtributesProperties } = props
      const tdAtributes =
        tdAtributesProperties as HTMLAttributes<HTMLTableCellElement>
      if (!tableMarkdownState) {
        return (
          <td {...tdAtributes}>
            <div>{tdChildren}</div>
          </td>
        )
      }
      return <CustomTd style={{ padding: '22px 16px' }} {...props} />
    },
    th: ({ node, ...props }) => {
      const { children: thChildren, ...tdAtributesProperties } = props
      const tdAtributes =
        tdAtributesProperties as HTMLAttributes<HTMLTableCellElement>
      if (!tableMarkdownState) {
        return (
          <th {...tdAtributes}>
            <div>{thChildren}</div>
          </th>
        )
      }
      return <CustomTh style={styles.table_header_item} {...props} />
    },
    thead: ({ node, ...props }) => (
      <thead style={styles.table_header} {...props} />
    ),
    tr: ({ node, ...props }) => <tr style={styles.table_row} {...props} />,
  }
  return (
    <ReactMarkdown
      remarkPlugins={[
        [RemarkGfm, { singleTilde: false }],
        [RemarkUnwrapImages],
      ]}
      rehypePlugins={[[RehypeRaw, { allowDangerousHtml: true }] as any]}
      components={componentsMap}
      {...propsMarkdown}
    >
      {newChildren}
    </ReactMarkdown>
  )
}
